(ns cljc.java-time.clock (:refer-clojure :exclude [abs get range format min max next name resolve short]) (:require [cljc.java-time.extn.calendar-awareness] [goog.object] [java.time :refer [Clock]]))
(clojure.core/defn tick {:arglists (quote (["java.time.Clock" "java.time.Duration"]))} (^js/JSJoda.Clock [^js/JSJoda.Clock java-time-Clock15387 ^js/JSJoda.Duration java-time-Duration15388] (js-invoke java.time.Clock "tick" java-time-Clock15387 java-time-Duration15388)))
(clojure.core/defn offset {:arglists (quote (["java.time.Clock" "java.time.Duration"]))} (^js/JSJoda.Clock [^js/JSJoda.Clock java-time-Clock15389 ^js/JSJoda.Duration java-time-Duration15390] (js-invoke java.time.Clock "offset" java-time-Clock15389 java-time-Duration15390)))
(clojure.core/defn system-utc {:arglists (quote ([]))} (^js/JSJoda.Clock [] (js-invoke java.time.Clock "systemUTC")))
(clojure.core/defn system-default-zone {:arglists (quote ([]))} (^js/JSJoda.Clock [] (js-invoke java.time.Clock "systemDefaultZone")))
(clojure.core/defn fixed {:arglists (quote (["java.time.Instant" "java.time.ZoneId"]))} (^js/JSJoda.Clock [^js/JSJoda.Instant java-time-Instant15391 ^js/JSJoda.ZoneId java-time-ZoneId15392] (js-invoke java.time.Clock "fixed" java-time-Instant15391 java-time-ZoneId15392)))
(clojure.core/defn tick-minutes {:arglists (quote (["java.time.ZoneId"]))} (^js/JSJoda.Clock [^js/JSJoda.ZoneId java-time-ZoneId15393] (js-invoke java.time.Clock "tickMinutes" java-time-ZoneId15393)))
(clojure.core/defn tick-seconds {:arglists (quote (["java.time.ZoneId"]))} (^js/JSJoda.Clock [^js/JSJoda.ZoneId java-time-ZoneId15394] (js-invoke java.time.Clock "tickSeconds" java-time-ZoneId15394)))
(clojure.core/defn millis {:arglists (quote (["java.time.Clock"]))} (^long [^js/JSJoda.Clock this15395] (.millis this15395)))
(clojure.core/defn with-zone {:arglists (quote (["java.time.Clock" "java.time.ZoneId"]))} (^js/JSJoda.Clock [^js/JSJoda.Clock this15396 ^js/JSJoda.ZoneId java-time-ZoneId15397] (.withZone this15396 java-time-ZoneId15397)))
(clojure.core/defn get-zone {:arglists (quote (["java.time.Clock"]))} (^js/JSJoda.ZoneId [^js/JSJoda.Clock this15398] (.zone this15398)))
(clojure.core/defn hash-code {:arglists (quote (["java.time.Clock"]))} (^int [^js/JSJoda.Clock this15399] (.hashCode this15399)))
(clojure.core/defn system {:arglists (quote (["java.time.ZoneId"]))} (^js/JSJoda.Clock [^js/JSJoda.ZoneId java-time-ZoneId15400] (js-invoke java.time.Clock "system" java-time-ZoneId15400)))
(clojure.core/defn instant {:arglists (quote (["java.time.Clock"]))} (^js/JSJoda.Instant [^js/JSJoda.Clock this15401] (.instant this15401)))
(clojure.core/defn equals {:arglists (quote (["java.time.Clock" "java.lang.Object"]))} (^boolean [^js/JSJoda.Clock this15402 ^java.lang.Object java-lang-Object15403] (.equals this15402 java-lang-Object15403)))
