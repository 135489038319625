(ns io.klei.lms.frontend.feature.student.students-by-school
  (:require
    [pyramid.core :as p]
    [goog.string :as g.string]
    [reagent.core :as r]
    [io.klei.lms.frontend.entity.user :as e.user]
    [io.klei.lms.frontend.entity.student :as entity.student]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [re-frame.core :as rf]
    [goog.object :as g.object]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.route-utils :as r.utils]
    [io.klei.lms.frontend.shared.utils :as utils]))


(defn students-table [{:keys [school/id]}]
  (r/with-let [match @(rf/subscribe [:router.sub/match])
               students (rf/subscribe [:feat.students-by-school.sub/students])
               state_ (r/atom {:open? false
                               :selected-student-id nil})]
    [:<>
     [ui/table
      {:columns [{:key :student/student-number
                  :title "Learner Number"
                  :dataIndex :student/student-number}
                 {:key :user/username
                  :title "Username"
                  :dataIndex [:auth/user :user/username]}
                 {:key "name"
                  :title "Name"
                  :dataIndex :name}
                 {:key "action"
                  :title ""
                  :width 120
                  :render (fn [_ ^js record]
                            (r/as-element
                              [ui/button
                               {:type "link"
                                :onClick (fn [_]
                                           (rf/dispatch [:router.event/navigate
                                                         :page.user/single
                                                         {:path-params (merge (r.utils/org+school-params match)
                                                                              {:user-id (g.object/getValueByKeys record "user" "id")})
                                                          :query-params {:role (e.user/user-role (g.object/getValueByKeys record "user" "roles"))}}]))}
                               "View"]))}]

       :dataSource (->> @students
                        (mapv (fn [s]
                                (assoc s :key (:student/id s)
                                         :name (entity.student/last-first-name s)))))}]]))

(def student-pull-pattern
  [:student/id
   :student/student-number
   {:auth/user [:user/id
                :user/username
                :user/email
                :user/roles]}
   :person/first-name
   :person/last-name])

(rf/reg-event-fx
  :feat.students-by-school.event/get-by-school-id
  (fn [_ [_ school-id]]
    {:dispatch [::pathom/query {:entity {:school/id school-id}
                                :eql [{:school/students student-pull-pattern}]
                                :on-success [:feat.students-by-school.event/get-by-school-id-success]}]}))

(rf/reg-event-fx
  :feat.students-by-school.event/get-by-school-id-success
  (fn [{:keys [db]} [_ response]]
    {:db (utils/add db response)}))

(rf/reg-sub
  :feat.students-by-school.sub/students
  (fn [db [_]]
    (let [students (p/pull db [{:school/students student-pull-pattern}])]
      (:school/students students))))
