(ns io.klei.lms.frontend.page.school-admin.teacher
  (:require
    [goog.object :as gobject]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.entity.teacher :as entities.teacher]
    [io.klei.lms.frontend.page.layout :as layout]
    [io.klei.lms.frontend.feature.teacher-new :as f.teacher-new]
    [io.klei.lms.frontend.feature.teacher-list :as f.teacher-list]
    [io.klei.lms.frontend.feature.teacher-edit :as f.teacher-edit]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]))

(defn teacher-content []
  (let [teacher-id @(rf/subscribe [:feat.teacher-edit.sub/selected-teacher-id])
        teacher @(rf/subscribe [:entity.teacher.sub/one-by-id teacher-id])
        open? @(rf/subscribe [:feat.teacher-new.sub/open?])]
    [:<>
     [ui/row
      [ui/col {:span 24}
       [ui/button
        {:icon (r/as-element [icon/plus])
         :style {:float "right"}
         :on-click #(rf/dispatch [:feat.teacher-new.event/open])}
        "Add New Teacher"]]
      (when open?
        [ui/drawer
         {:className "teacher-drawer"
          :title "Add New Teacher"
          :placement "right"
          :open true
          :onClose #(rf/dispatch [:feat.teacher-new.event/close])
          :width 640}
         [ui/row
          [ui/col {:span 24}
           [f.teacher-new/teacher-form
            {:on-submit #(rf/dispatch [:feat.teacher-new.event/add-teacher %])}]]]])]

     [ui/divider]

     [ui/row
      [ui/col {:span 24}
       [f.teacher-list/teacher-list
        {:on-click (fn [^js record _index _e]
                     (rf/dispatch [:feat.teacher-edit.event/open (gobject/getValueByKeys record "teacher" "id")]))}]
       (when teacher
         [ui/drawer
          {:className "teacher-drawer"
           :title "Edit Teacher"
           :placement "right"
           :open true
           :onClose #(rf/dispatch [:feat.teacher-edit.event/close])
           :width 640}
          [ui/row
           [ui/col {:span 24}
            [f.teacher-edit/teacher-form
             (let [section-ids (->> teacher
                                    :teacher/sections
                                    (mapv :section/id))]
               {:initial-values {:teacher-id (:teacher/id teacher)
                                 :first-name (:teacher/first-name teacher)
                                 :middle-name (:teacher/middle-name teacher)
                                 :last-name (:teacher/last-name teacher)
                                 :email (-> teacher :auth/user :user/email)
                                 :section-ids section-ids}
                :on-submit #(rf/dispatch [:feat.teacher-edit.event/edit-teacher %])})]]]])]]]))
