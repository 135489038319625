(ns io.klei.lms.frontend.entity.viewer-teacher
  (:require
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.utils :as utils]))

(rf/reg-event-fx
  :entity.viewer-teacher/get-viewer-teacher
  (fn [{db :db} _]
    {:http-xhrio
     (utils/http-map db {:method :get
                         :uri "/viewer/teacher"
                         :on-success [:entity.viewer-teacher/get-viewer-teacher-response]
                         :on-failure []})}))

(rf/reg-event-fx
  :entity.viewer-teacher/get-viewer-teacher-response
  (fn [{db :db} [_ response]]
    {:db (assoc db :entity.viewer-teacher.db/viewer-teacher response)}))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :entity.viewer-teacher.sub/sections
  (fn [db _]
    (get-in db [:entity.viewer-teacher.db/viewer-teacher :sections] [])))

(rf/reg-sub
  :entity.viewer-teacher.sub/viewer-teacher
  (fn [db _]
    (get db :entity.viewer-teacher.db/viewer-teacher)))
