(ns io.klei.lms.frontend.entity.schema
  (:require
   [io.klei.schema.malli :as schema.malli]
   [re-frame.core :as rf]
   [malli.core :as m]
   [malli.experimental.time :as m.time]
   ;; supports json https://github.com/metosin/malli#json-schema---malliexperimentaltimejson-schema
   [malli.experimental.time.json-schema]
   [malli.util :as m.util]))

(rf/reg-sub
  :entity.schema.sub/registry
  :<- [:model.sub/model]
  (fn [model]
    (when model
      (merge (m/default-schemas)
             (m.util/schemas)
             (m.time/schemas)
             (merge (schema.malli/data-schemas->malli-schemas (:data-schemas model))
                    (:attributes model))))))
