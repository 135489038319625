(ns io.klei.lms.frontend.app.routes
  (:require
    ;; COMMON
    [io.klei.lms.frontend.page.layout :as page.layout]
    [io.klei.lms.frontend.page.profile :as page.profile]
    [io.klei.lms.frontend.page.school :as page.school]
    [io.klei.lms.frontend.page.forms :as page.forms]

    ;; STUDENT
    [io.klei.lms.frontend.page.login :as page.login]
    [io.klei.lms.frontend.page.dashboard :as page.dashboard]
    [io.klei.lms.frontend.page.student :as page.student]

    ;; TEACHER
    [io.klei.lms.frontend.page.teacher.section-view :as page.teacher.section-view]

    ;; SCHOOL ADMIN
    [io.klei.lms.frontend.page.school-admin.registration :as page.school-admin.registration]
    [io.klei.lms.frontend.page.school-admin.register-student :as page.school-admin.register-student]
    [io.klei.lms.frontend.page.school-admin.materials :as page.school-admin.materials]
    [io.klei.lms.frontend.page.school-admin.teacher :as page.school-admin.teacher]
    [io.klei.lms.frontend.page.school-admin.section :as page.school-admin.section]
    [io.klei.lms.frontend.page.school-admin.course :as page.school-admin.course]
    [io.klei.lms.frontend.page.school-admin.subject :as page.school-admin.subject]
    [io.klei.lms.frontend.page.school-admin.new-material :as page.school-admin.new-material]

    ;; USER
    [io.klei.lms.frontend.page.user.index :as page.user.index]
    [io.klei.lms.frontend.page.user.single :as page.user.single]))

(def routes
  [["/" {:allow-anonymous? true
         :name :pages/login
         :title "Login"
         :views [page.login/login-page]}]
   ["" {:allow-anonymous? false}
    ;; TODO: optionally mount routes depending on role. This will prevent accessing page completely
    ;;------------------------------------------------------------
    ;; COMMON
    ["/:org-id"
     {:views [page.layout/sider-layout]}
     ["" {:name :page/org-admin-dashboard
          :title "Dashboard"
          :views [page.dashboard/dashboard-content]}]

     ["/my/profile"
      {:name :page.my/profile
       :title "Profile"
       :views [page.profile/profile-content]}]

     ["/my/forms"
      {:name :page.my/forms
       :title "Forms"
       :views [page.forms/forms-content]}]

     ["/school/:school-id"
      ["" {:name :page/dashboard
           :title "Dashboard"
           :views [page.dashboard/dashboard-content]}]
      ;; STUDENT
      ["/student"
       ["" {:name :page.student/index
            :title "Students"
            :views [page.student/index-content]}]
       ["/student/new"
        {:name :page.student/new
         :views [page.student/new-student-content]}]]

      ;; SCHOOL
      ["/school" {:name :page.school/index
                  :title "Schools"
                  :views [page.school/school-content]}]

      ;; TEACHER
      ["/teacher"
       [""
        {:name :page.school-admin/teacher
         :title "Add New Teacher"
         :views [page.school-admin.teacher/teacher-content]}]

       ["/section/:section-id" {:name :page.teacher/section-view
                                :title "Section"
                                :views [page.teacher.section-view/view-content]}]]
      ;; REGISTRATION
      ["/registration"
       {:name :page.school-admin/registration
        :title "Registration"
        :views [page.school-admin.registration/registration-content]}]
      ["/registration/new-student"
       {:name :page.school-admin/register-new-student
        :title "Register New Student"
        :views [page.school-admin.register-student/new-student-content]}]
      ["/registration/existing-student/:student-id"
       {:name :page.school-admin/register-existing-student
        :title "Register Existing Student"
        :views [page.school-admin.register-student/existing-student-content]}]

      ;; MATERIAL
      ["/material"
       {:name :page.school-admin/material
        :title "Materials"
        :views [page.school-admin.materials/materials-content]}]

      ["/material/new"
       {:name :page.school-admin/new-material
        :title "Add New Material"
        :views [page.school-admin.new-material/new-material-content]}]

      ;; SECTION
      ["/ay/:ay-id/sections"
       {:name :page.school-admin/sections
        :title "Sections"
        :views [page.school-admin.section/section-content]}]

      ;; COURSE
      ["/course"
       {:name :page.school-admin/course
        :title "Course"
        :views [page.school-admin.course/courses-content]}]

      ;; SUBJECT
      ["/subject"
       {:name :page.school-admin/subject
        :title "Subjects"
        :views [page.school-admin.subject/subjects-content]}]

      ;; USER
      ["/user"
       {:name :page.user/index
        :title "Users"
        :views [page.user.index/index-content]}]

      ["/user/:user-id"
       {:name :page.user/single
        :title "Users"
        :views [page.user.single/single-content]}]]]]])
