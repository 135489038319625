(ns io.klei.lms.frontend.app.db
  (:require
    [antd]
    [pyramid.core :as p]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.entity.current-user :as entities.current-user]
    [taoensso.timbre :as log]))

(def access-token-localstorage-key "access_token")

(def default-db
  {})

(rf/reg-event-fx
  :app.event/initialize-db
  [(rf/inject-cofx :local-store-access-token)
   (rf/inject-cofx :local-store-avatar)]
  (fn [{:keys [local-store-access-token local-store-avatar]} _]
    (let [{:keys [user access-token]} local-store-access-token
          ;; expiration is 1970 when nil is threaded
          expiration (utils/token-expiration user)
          token-expired? (utils/date-expired? expiration)
          db (entities.current-user/set-session-id default-db)]
      (if-not token-expired?
        {:db (p/add db {:entity.current-user.db/access-token access-token
                        :entity.current-user.db/user-id user
                        :entity.current-user.db/token-expiration expiration
                        :entity.current-user.db/avatar local-store-avatar})
         :fx (entities.current-user/init-fx-by-user db user)}
        {:db db
         :localstorage/remove access-token-localstorage-key
         :dispatch [:router.event/navigate :pages/login]}))))

(rf/reg-event-db
  :add-entity
  (fn [db [_ m]]
    (p/add db m)))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :app-db
  (fn [db _]
    db))

;;------------------------------------------------------------
;; CO-EFFECTS
(rf/reg-cofx
  :local-store-access-token
  (fn [cofx _]
    (let [access-token (js/localStorage.getItem access-token-localstorage-key)]
      (assoc cofx :local-store-access-token {:access-token access-token
                                             :user (some-> access-token
                                                           utils/jwt-decode)}))))

(rf/reg-cofx
  :local-store-avatar
  (fn [cofx _]
    (let [{:keys [user]} (:local-store-access-token cofx)]
      (assoc cofx :local-store-avatar (js/localStorage.getItem (entities.current-user/user-avatar-localstorage-key (:user/id user)))))))

;;------------------------------------------------------------
;; EFFECTS
(rf/reg-fx
  :window/title
  (fn [opts]
    (set! (.-title js/document) (:title opts))))

(rf/reg-fx
  :msclarity/identify
  (fn [{:keys [id session-id page-id friendly-name]}]
    (when-let [^js c-fn js/window.clarity]
      (c-fn "identify" id session-id page-id friendly-name))))

(rf/reg-fx
  :msclarity/set
  (fn [m]
    (when-let [^js c-fn js/window.clarity]
      (doseq [[k v] m]
        (if (keyword? k)
          (c-fn "set" (str (symbol k)) (clj->js v))
          (log/errorf "The passed msclarity/set key `%s` is not a keyword. It will be ignored." k))))))

(rf/reg-fx
  :msclarity/event
  (fn [event-name]
    (when-let [^js c-fn js/window.clarity]
      (c-fn "event" event-name))))

(rf/reg-fx
  :msclarity/upgrade
  (fn [reason]
    (when-let [^js c-fn js/window.clarity]
      (c-fn "upgrade" reason))))

(comment
  (def token "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyL2VtYWlsIjoiamFpbWVAa2xlaS5pbyIsInVzZXIvcm9sZXMiOlsic3R1ZGVudCJdLCJ4dC9pZCI6InVzZXItamFpbWUiLCJleHAiOjE2NTI1Mjc1NjR9.l4XW9j897YZ7Wu5g7CD6BHbr8zSz9VzzNO1DOFZSVnc")
  (def exp (-> (utils/jwt-decode token) :exp))
  (js/Date. (* exp 1000)))
