(ns io.klei.lms.frontend.feature.viewer-info
  (:require
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.entity.section :as entities.section]
    [io.klei.lms.frontend.entity.student :as entities.student]
    [io.klei.lms.frontend.entity.teacher :as entities.teacher]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.shared.ui :as ui]))

(defn viewer-info [{:keys [name info]}]
  (let [{:keys [name info]} @(rf/subscribe [:feat.viewer-info.sub/info])]
    [:div.student-info
     [ui/title {:level 3} name]
     (into [:<>]
           (->> info
                (mapv (fn [{:keys [label text]}]
                        (r/as-element
                          [:div.student-info__field
                           [:div.student-info__label label]
                           [:div.student-info__text text]])))))]))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :feat.viewer-info.sub/info
  :<- [:entity.current-user.sub/roles]
  :<- [:entity.current-user.sub/user]
  :<- [:entity.viewer-student.sub/viewer-student]
  :<- [:entity.viewer-teacher.sub/viewer-teacher]
  (fn [[roles user viewer-student viewer-teacher] _]
    (cond
      (roles :student)
      (let [student (:student viewer-student)]
        {:name (entities.student/last-first-name student)
         :info [{#_#_:label "Student No"
                 ;; required by SRK for ACTVET. Label Student No. is non-compliant.
                 ;; This is the only label to be change as it is viewable on Student side
                 ;; which they use during checking.
                 ;; TODO: labels should be customizable per tenant
                 :label "Learner No"
                 :text (:student/student-number student)}
                {:label "Section"
                 :text (entities.section/name-with-level (-> viewer-student :section))}
                {:label "Username"
                 :text (:user/username user)}]})

      (roles :teacher)
      (let [teacher (:teacher viewer-teacher)]
        {:name (entities.teacher/last-first-name teacher)
         :info [{:label "Username"
                 :text (:user/username user)}
                {:label "Email"
                 :text (:user/email user)}
                {:label "Mobile"
                 :text (:user/mobile user)}]})

      :else
      {:info [{:label "Username"
               :text (:user/username user)}
              {:label "Email"
               :text (:user/email user)}
              {:label "Mobile"
               :text (:user/mobile user)}]})))


