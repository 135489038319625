(ns io.klei.lms.frontend.page.teacher.section-view
  (:require
    [re-frame.core :as rf]
    [io.klei.lms.frontend.entity.student :as entities.student]
    [io.klei.lms.frontend.feature.pdf-viewer :as features.pdf-viewer]
    [io.klei.lms.frontend.feature.material-list :as features.material-list]
    [io.klei.lms.frontend.shared.ui :as ui]))

(defn view-content []
  (let [section @(rf/subscribe [:entity.section.sub/section])
        students @(rf/subscribe [:entity.section.sub/section-students])
        file-url @(rf/subscribe [:feat.pdf-viewer.sub/file-url])
        preview-open? @(rf/subscribe [:feat.pdf-viewer.sub/open?])]
    [:<>
     [ui/row {:gutter [24, 24]}
      [ui/col {:span 24}
       [ui/table {:pagination false
                  :columns [{:title "Student"
                             :dataIndex "name"
                             :key "name"}
                            {:title "Learner No."
                             :dataIndex "student-number"
                             :key "student-number"}
                            {:title "Email"
                             :dataIndex "email"
                             :key "email"}]
                  :dataSource (mapv (fn [s]
                                      {:key (:student/id s)
                                       :name (entities.student/last-first-name s)
                                       :student-number (:student/student-number s)
                                       :email (entities.student/student-email s)})
                                    students)}]]

      [ui/col
       [ui/section-heading "Books"]
       [features.material-list/material-cards {:materials (:section/materials section)}]
       (when (and preview-open? file-url)
         [:f> features.pdf-viewer/pdf-viewer {:url file-url
                                              :open? preview-open?
                                              :on-close #(rf/dispatch [:feat.pdf-viewer.event/close-preview])}])]]]))

