(ns io.klei.lms.frontend.shared.icon
  (:require
    [reagent.core :as r]
    ["@ant-design/icons/BookOutlined$default" :as BookOutlined]
    ["@ant-design/icons/BuildOutlined$default" :as BuildOutlined]
    ["@ant-design/icons/CloseOutlined$default" :as CloseOutlined]
    ["@ant-design/icons/DashboardOutlined$default" :as DashboardOutlined]
    ["@ant-design/icons/DeleteOutlined$default" :as DeleteOutlined]
    ["@ant-design/icons/DownOutlined$default" :as DownOutlined]
    ["@ant-design/icons/EditOutlined$default" :as EditOutlined]
    ["@ant-design/icons/LoadingOutlined$default" :as LoadingOutlined]
    ["@ant-design/icons/LogoutOutlined$default" :as LogoutOutlined]
    ["@ant-design/icons/PlusOutlined$default" :as PlusOutlined]
    ["@ant-design/icons/ShareAltOutlined$default" :as ShareAltOutlined]
    ["@ant-design/icons/UploadOutlined$default" :as UploadOutlined]
    ["@ant-design/icons/UserOutlined$default" :as UserOutlined]
    ["@ant-design/icons/UserAddOutlined$default" :as UserAddOutlined]
    ["@ant-design/icons/UsergroupAddOutlined$default" :as UsergroupAddOutlined]
    ["@ant-design/icons/MinusCircleOutlined$default" :as MinusCircleOutlined]))

(def book (r/adapt-react-class BookOutlined))
(def build (r/adapt-react-class BuildOutlined))
(def close (r/adapt-react-class CloseOutlined))
(def dashboard (r/adapt-react-class DashboardOutlined))
(def delete (r/adapt-react-class DeleteOutlined))
(def down (r/adapt-react-class DownOutlined))
(def edit (r/adapt-react-class EditOutlined))
(def loading (r/adapt-react-class LoadingOutlined))
(def logout (r/adapt-react-class LogoutOutlined))
(def minus-circle (r/adapt-react-class MinusCircleOutlined))
(def plus (r/adapt-react-class PlusOutlined))
(def share (r/adapt-react-class ShareAltOutlined))
(def upload (r/adapt-react-class UploadOutlined))
(def user (r/adapt-react-class UserOutlined))
(def user-add (r/adapt-react-class UserAddOutlined))
(def user-group-add (r/adapt-react-class UsergroupAddOutlined))
