(ns io.klei.lms.frontend.page.layout
  (:require
    [re-frame.core :as rf]
    [reagent.core :as r]
    ["react-responsive" :as responsive]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.feature.sider :as f.sider]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.feature.avatar-dropdown :as f.avatar-dropdown]))

(defn nest-component [match]
  (let [[view-component & nested-views] (get-in match [:data :views])]
    (when view-component
      ;; renders the view component
      [:f> view-component {:match (assoc-in match [:data :views] nested-views)}])))

(defn sider-layout [{:keys [match]}]
  (let [school-name @(rf/subscribe [:entity.current-user.sub/school-name])
        mobile? (responsive/useMediaQuery #js {:maxWidth 767})]
    [ui/layout {:id (-> match :data :name)
                :class "sider-page-layout"}
     [ui/sider {:collapsible true
                :collapsedWidth (if mobile? 0 80)
                :breakpoint "lg"
                :theme "light"}
      [:<>
       [:div.logo
        [:div.logo__img]]
       [f.sider/sider-menu]]]
     [ui/layout
      [ui/header {:class "sider-page-layout__header"}
       [:img.sider-page-layout__school-logo
        {:src (utils/logo-url "/logo/srk.jpg")}]
       [:span.sider-page-layout__school-name school-name]
       [f.avatar-dropdown/avatar-dropdown]]
      [ui/title {:level 5
                 :class "sider-page-layout__title"} (-> match :data :title)]
      [ui/content {:class "sider-page-layout__content"}
       [nest-component match]]
      [ui/footer]]]))
