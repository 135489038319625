(ns io.klei.lms.frontend.feature.change-password
  (:require
    [goog.object :as gobject]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(def password-min-length 8)

;;------------------------------------------------------------
;; UI
(defn change-password-form []
  [ui/form
   {:ref #(rf/dispatch [:feat.change-password.event/set-form %])
    :layout "horizontal"
    :colon false
    :requiredMark true
    :labelCol {:xs {:span 24}
               :sm {:span 3}}
    :wrapperCol {:xs {:span 24}
                 :sm {:span 8}}
    :onFinish (fn [values]
                (rf/dispatch [:feat.change-password.event/submit values]))}
   [ui/form-item
    {:label "Old Password"
     :name "old-password"
     :rules [{:required true}]}
    [ui/input-password]]

   [ui/form-item
    {:label "New Password"
     :name "new-password"
     :rules [{:required true}
             (fn [o]
               #js {:validator
                    (fn [_ value]
                      (if (< (count value) password-min-length)
                        (js/Promise.reject
                          (js/Error.
                            (str "Password must be " password-min-length " characters or more.")))
                        (js/Promise.resolve)))})]}
    [ui/input-password]]

   [ui/form-item
    {:label "Confirm Password"
     :name "confirm-new-password"
     :rules [{:required true
              :message "Please type again your new password here."}
             (fn [o]
               #js {:validator
                    (fn [_ value]
                      (let [get-value (gobject/get o "getFieldValue")]
                        (if (or (not value)
                                (= value (get-value "new-password")))
                          (js/Promise.resolve)
                          (js/Promise.reject (js/Error. "The two new passwords that you entered does not match.")))))})]
     :dependencies ["new-password"]}
    [ui/input-password]]

   [ui/form-item
    {:wrapper-col {:span 11}}
    [ui/button
     {:style {:float "right"}
      :type "primary"
      :htmlType "submit"}
     "Change Password"]]])
;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-fx
  :feat.change-password.event/submit
  (fn [{db :db} [_ values]]
    {:http-xhrio
     (utils/http-map db {:method :post
                         :uri "/auth/change-password"
                         :params values
                         :on-success [:feat.change-password.event/submit-success]
                         :on-failure [:feat.change-password.event/submit-failure]})}))

(rf/reg-event-fx
  :feat.change-password.event/submit-success
  (fn [{db :db} [_ response]]
    {:dispatch [:toast-notification {:type :success
                                     :message "Your password has been updated."}]
     :antd/form-reset (get db :feat.change-password.db/form)}))

(rf/reg-event-fx
  :feat.change-password.event/submit-failure
  (fn [{db :db} [_ _failure]]
    {:dispatch [:toast-notification {:type :error
                                     :message "Failed to update password. Please ensure that the old password is correct."}]
     :antd/form-reset (get db :feat.change-password.db/form)}))

(rf/reg-event-fx
  :feat.change-password.event/set-form
  (fn [{db :db} [_ form]]
    {:db (assoc db :feat.change-password.db/form form)}))

