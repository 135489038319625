(ns io.klei.lms.frontend.entity.material.book
  (:require
    [reagent.core :as r]
    [io.klei.lms.frontend.shared.ui :as ui]))

;;--------------------------------------------------------------------------------------------------------------
;; UI

(defn book-card [{:keys [title src on-click]}]
  [ui/card
   {:class "book-card"
    :on-click #(on-click %)
    :hoverable true
    :cover (r/as-element [:img {:src src}])}
   [ui/card-meta {:title (r/as-element [ui/text {:type "secondary"
                                                 :alt title} title])}]])
