(ns cljc.java-time.temporal.chrono-unit (:refer-clojure :exclude [abs get range format min max next name resolve short]) (:require [cljc.java-time.extn.calendar-awareness] [goog.object] [java.time.temporal :refer [ChronoUnit]]))
(def millis (goog.object/get java.time.temporal.ChronoUnit "MILLIS"))
(def minutes (goog.object/get java.time.temporal.ChronoUnit "MINUTES"))
(def micros (goog.object/get java.time.temporal.ChronoUnit "MICROS"))
(def half-days (goog.object/get java.time.temporal.ChronoUnit "HALF_DAYS"))
(def millennia (goog.object/get java.time.temporal.ChronoUnit "MILLENNIA"))
(def years (goog.object/get java.time.temporal.ChronoUnit "YEARS"))
(def decades (goog.object/get java.time.temporal.ChronoUnit "DECADES"))
(def days (goog.object/get java.time.temporal.ChronoUnit "DAYS"))
(def centuries (goog.object/get java.time.temporal.ChronoUnit "CENTURIES"))
(def weeks (goog.object/get java.time.temporal.ChronoUnit "WEEKS"))
(def hours (goog.object/get java.time.temporal.ChronoUnit "HOURS"))
(def eras (goog.object/get java.time.temporal.ChronoUnit "ERAS"))
(def seconds (goog.object/get java.time.temporal.ChronoUnit "SECONDS"))
(def months (goog.object/get java.time.temporal.ChronoUnit "MONTHS"))
(def nanos (goog.object/get java.time.temporal.ChronoUnit "NANOS"))
(def forever (goog.object/get java.time.temporal.ChronoUnit "FOREVER"))
(clojure.core/defn values {:arglists (quote ([]))} (^"java.lang.Class" [] (js-invoke java.time.temporal.ChronoUnit "values")))
(clojure.core/defn value-of {:arglists (quote (["java.lang.String"] ["java.lang.Class" "java.lang.String"]))} (^js/JSJoda.ChronoUnit [^java.lang.String java-lang-String15596] (js-invoke java.time.temporal.ChronoUnit "valueOf" java-lang-String15596)) (^java.lang.Enum [^java.lang.Class java-lang-Class15597 ^java.lang.String java-lang-String15598] (js-invoke java.time.temporal.ChronoUnit "valueOf" java-lang-Class15597 java-lang-String15598)))
(clojure.core/defn ordinal {:arglists (quote (["java.time.temporal.ChronoUnit"]))} (^int [^js/JSJoda.ChronoUnit this15599] (.ordinal this15599)))
(clojure.core/defn is-duration-estimated {:arglists (quote (["java.time.temporal.ChronoUnit"]))} (^boolean [^js/JSJoda.ChronoUnit this15600] (.isDurationEstimated this15600)))
(clojure.core/defn to-string {:arglists (quote (["java.time.temporal.ChronoUnit"]))} (^java.lang.String [^js/JSJoda.ChronoUnit this15601] (.toString this15601)))
(clojure.core/defn is-date-based {:arglists (quote (["java.time.temporal.ChronoUnit"]))} (^boolean [^js/JSJoda.ChronoUnit this15602] (.isDateBased this15602)))
(clojure.core/defn add-to {:arglists (quote (["java.time.temporal.ChronoUnit" "java.time.temporal.Temporal" "long"]))} (^js/JSJoda.Temporal [^js/JSJoda.ChronoUnit this15603 ^js/JSJoda.Temporal java-time-temporal-Temporal15604 ^long long15605] (.addTo this15603 java-time-temporal-Temporal15604 long15605)))
(clojure.core/defn name {:arglists (quote (["java.time.temporal.ChronoUnit"]))} (^java.lang.String [^js/JSJoda.ChronoUnit this15606] (.name this15606)))
(clojure.core/defn is-supported-by {:arglists (quote (["java.time.temporal.ChronoUnit" "java.time.temporal.Temporal"]))} (^boolean [^js/JSJoda.ChronoUnit this15607 ^js/JSJoda.Temporal java-time-temporal-Temporal15608] (.isSupportedBy this15607 java-time-temporal-Temporal15608)))
(clojure.core/defn get-declaring-class {:arglists (quote (["java.time.temporal.ChronoUnit"]))} (^java.lang.Class [^js/JSJoda.ChronoUnit this15609] (.declaringClass this15609)))
(clojure.core/defn between {:arglists (quote (["java.time.temporal.ChronoUnit" "java.time.temporal.Temporal" "java.time.temporal.Temporal"]))} (^long [^js/JSJoda.ChronoUnit this15610 ^js/JSJoda.Temporal java-time-temporal-Temporal15611 ^js/JSJoda.Temporal java-time-temporal-Temporal15612] (.between this15610 java-time-temporal-Temporal15611 java-time-temporal-Temporal15612)))
(clojure.core/defn hash-code {:arglists (quote (["java.time.temporal.ChronoUnit"]))} (^int [^js/JSJoda.ChronoUnit this15613] (.hashCode this15613)))
(clojure.core/defn compare-to {:arglists (quote (["java.time.temporal.ChronoUnit" "java.lang.Enum"]))} (^int [^js/JSJoda.ChronoUnit this15614 ^java.lang.Enum java-lang-Enum15615] (.compareTo this15614 java-lang-Enum15615)))
(clojure.core/defn get-duration {:arglists (quote (["java.time.temporal.ChronoUnit"]))} (^js/JSJoda.Duration [^js/JSJoda.ChronoUnit this15616] (.duration this15616)))
(clojure.core/defn equals {:arglists (quote (["java.time.temporal.ChronoUnit" "java.lang.Object"]))} (^boolean [^js/JSJoda.ChronoUnit this15617 ^java.lang.Object java-lang-Object15618] (.equals this15617 java-lang-Object15618)))
(clojure.core/defn is-time-based {:arglists (quote (["java.time.temporal.ChronoUnit"]))} (^boolean [^js/JSJoda.ChronoUnit this15619] (.isTimeBased this15619)))
