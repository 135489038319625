(ns io.klei.lms.frontend.feature.material.new-material
  (:require
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.object :as gobject]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(def max-thumbnail-size-MB 5)
(def max-material-size-MB 50)

(defn- remove-thumbnail [db]
  (-> db
      (assoc :feat.material.new.db/thumbnail-file-loaded? false)
      (assoc :feat.material.new.db/thumbnail-preview nil)))

(defn- remove-material [db]
  (-> db
      (assoc :feat.material.new.db/material-file-uploaded? false)))

;;------------------------------------------------------------
;; UI
(defn image-uploader []
  (let [thumbnail-preview @(rf/subscribe [:feat.material.new.sub/thumbnail-preview])]
    [:<>
     [ui/upload
      {:accept "image/*"
       :listType "picture"
       :showUploadList false
       :maxCount 1
       :beforeUpload (fn [file]
                       (if (< (utils/bytes->MB (gobject/get file "size")) max-thumbnail-size-MB)
                         true
                         (do
                           (rf/dispatch [:toast-notification {:type :error
                                                              :message (str "Thumbnail should be less than " max-thumbnail-size-MB " MB")}])
                           ui/upload-list-ignore)))

       ;; https://github.com/react-component/upload#customrequest
       :customRequest (fn [^js upload-object]
                        (rf/dispatch [:feat.material.new.event/on-thumbnail-upload upload-object]))}

      (when-not thumbnail-preview
        [ui/button {:type "dashed"
                    :icon (r/as-element [icon/upload])}
         "Upload Thumbnail"])]
     (when thumbnail-preview
       [:div {:style {:display "flex"
                      :gap "12px"}}
        [ui/image {:src thumbnail-preview
                   :width 150}]
        [ui/button {:type "text"
                    :icon (r/as-element [icon/delete])
                    :onClick #(rf/dispatch [:feat.material.new.event/on-thumbnail-remove])}
         "Remove"]])]))

(defn material-uploader []
  (let [uploaded? @(rf/subscribe [:feat.material.new.sub/material-file-uploaded?])
        material-filename @(rf/subscribe [:feat.material.new.sub/material-filename])]
    [:<>
     [ui/upload
      {:accept ".pdf"
       :showUploadList false
       :maxCount 1
       :beforeUpload (fn [file]
                       (if (< (utils/bytes->MB (gobject/get file "size")) max-material-size-MB)
                         true
                         (do
                           (rf/dispatch [:toast-notification {:type :error
                                                              :message (str "Material should be less than " max-material-size-MB " MB")}])
                           ui/upload-list-ignore)))
       ;; https://github.com/react-component/upload#customrequest
       :customRequest (fn [^js upload-object]
                        (rf/dispatch [:feat.material.new.event/on-material-upload upload-object]))}

      (when-not uploaded?
        [ui/button {:type "dashed"
                    :icon (r/as-element [icon/upload])}
         "Upload Material"])]
     (when uploaded?
       [:div {:style {:display "flex"
                      :gap "12px"
                      :align-items "center"}}
        [:span material-filename]
        [ui/button {:type "text"
                    :icon (r/as-element [icon/delete])
                    :onClick #(rf/dispatch [:feat.material.new.event/on-material-remove])}
         "Remove"]])]))

(defn new-material-form []
  (let [^js form @(rf/subscribe [:feat.material.new.sub/form])
        course-options @(rf/subscribe [:entity.course.sub/select-options])
        submitting? @(rf/subscribe [:feat.material.new.sub/submitting?])]
    [ui/form
     {:ref #(rf/dispatch [:feat.material.new.event/set-form %])
      :className "new-material-form"
      :layout "horizontal"
      :colon false
      :requiredMark true
      :labelCol {:xs {:span 24}
                 :sm {:span 3}}
      :wrapperCol {:xs {:span 24}
                   :sm {:span 8}}
      :onFinish (fn [values]
                  (rf/dispatch [:feat.material.new.event/on-form-submit values]))}
     [ui/form-item
      {:label "Title"
       :name "title"
       :rules [{:required true}]}
      [ui/input]]
     [ui/form-item
      {:label "Description"
       :name "description"}
      [ui/textarea]]
     [ui/form-item
      {:label "Thumbnail"
       :name "thumbnail-file-id"
       :rules [{:required true}]}
      [image-uploader]]
     [ui/form-item
      {:label "Material"
       :name "material-file-id"
       :rules [{:required true}]}
      [material-uploader]]
     [ui/form-item
      {:label "Share Access"
       :name "course-ids"}
      [ui/select-with-options {:mode "multiple"
                               :options course-options
                               :filterOption utils/name-select-filter-option-fn
                               :onChange (fn [values]
                                           (.setFieldsValue form #js {:course-ids values}))}]]
     [ui/form-item {:wrapperCol {:offset 9 :span 8}}
      [ui/button {:type "primary"
                  :htmlType "submit"
                  :loading submitting?}
       "Add Material"]]]))


;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-fx
  :feat.material.new.event/set-form
  (fn [{db :db} [_ form]]
    {:db (assoc db :feat.material.new.db/form form)}))

(rf/reg-event-fx
  :feat.material.new.event/on-thumbnail-upload
  (fn [{db :db} [_ ^js upload-object]]
    {:db (-> db
             (assoc :feat.material.new.db/thumbnail-file-uploaded? false))
     :dispatch [:feat.presign-upload.event/start
                {:upload-id (random-uuid)
                 :file (gobject/get upload-object "file")
                 :params {:upload-config-id :material-thumbnail
                          :original-filename (gobject/getValueByKeys upload-object "file" "name")
                          :content-length (gobject/getValueByKeys upload-object "file" "size")
                          :content-type (gobject/getValueByKeys upload-object "file" "type")}
                 :on-progress [:feat.material.new.event/on-thumbnail-upload-progress upload-object]
                 :on-complete [:feat.material.new.event/on-thumbnail-upload-complete]}]}))

(rf/reg-event-fx
  :feat.material.new.event/on-thumbnail-upload-progress
  (fn [_ [_ ^js upload-object _upload-config progress-event]]
    {:antd/custom-request-progress [upload-object progress-event]}))

(rf/reg-event-fx
  :feat.material.new.event/on-thumbnail-upload-complete
  (fn [{db :db} [_ upload-config file-id _response]]
    (let [form (get db :feat.material.new.db/form)]
      {:antd.form/set-fields-value [form {:thumbnail-file-id file-id}]
       :file-reader/read {:blob (:file upload-config)
                          :read-as :data-url
                          :on-load [:feat.material.new.event/on-thumbnail-file-loaded]}})))

(rf/reg-event-fx
  :feat.material.new.event/on-thumbnail-file-loaded
  (fn [{db :db} [_ ^js result]]
    {:db (assoc db :feat.material.new.db/thumbnail-preview result)}))

(rf/reg-event-fx
  :feat.material.new.event/on-material-upload
  (fn [{db :db} [_ ^js upload-object]]
    {:db (-> db
             (assoc :feat.material.new.db/material-file-uploaded? false)
             (assoc :feat.material.new.db/material-file (gobject/get upload-object "file")))
     :dispatch [:feat.presign-upload.event/start
                {:upload-id (random-uuid)
                 :file (gobject/get upload-object "file")
                 :params {:upload-config-id :material
                          :original-filename (gobject/getValueByKeys upload-object "file" "name")
                          :content-length (gobject/getValueByKeys upload-object "file" "size")
                          :content-type (gobject/getValueByKeys upload-object "file" "type")}
                 :on-progress [:feat.material.new.event/on-material-upload-progress upload-object]
                 :on-complete [:feat.material.new.event/on-material-upload-complete]}]}))

(rf/reg-event-fx
  :feat.material.new.event/on-material-upload-progress
  (fn [_ [_ ^js upload-object _upload-config progress-event]]
    {:antd/custom-request-progress [upload-object progress-event]}))

(rf/reg-event-fx
  :feat.material.new.event/on-material-upload-complete
  (fn [{db :db} [_ _upload-config file-id _response]]
    (let [form (get db :feat.material.new.db/form)]
      {:db (assoc db :feat.material.new.db/material-file-uploaded? true)
       :antd.form/set-fields-value [form {:material-file-id file-id}]})))

(rf/reg-event-fx
  :feat.material.new.event/on-material-remove
  (fn [{db :db} [_]]
    (let [form (get db :feat.material.new.db/form)]
      {:db (remove-material db)
       :antd.form/set-fields-value [form {:material-file-id nil}]})))

(rf/reg-event-fx
  :feat.material.new.event/on-thumbnail-remove
  (fn [{db :db} [_]]
    (let [form (get db :feat.material.new.db/form)]
      {:db (remove-thumbnail db)
       :antd.form/set-fields-value [form {:thumbnail-file-id nil}]})))


(rf/reg-event-fx
  :feat.material.new.event/on-course-ids-change
  (fn [{db :db} [_ checked-values]]
    (let [form (get db :feat.material.new.db/form)]
      {:antd.form/set-fields-value [form {:course-ids checked-values}]})))

(rf/reg-event-fx
  :feat.material.new.event/on-form-submit
  (fn [{db :db} [_ values]]
    {:db (assoc db :feat.material.new.db/submitting? true)
     :http-xhrio (utils/http-map db {:method :post
                                     :uri "/material"
                                     :params values
                                     :on-success [:feat.material.new.event/on-form-submit-success]})}))

(rf/reg-event-fx
  :feat.material.new.event/on-form-submit-success
  (fn [{db :db} [_ response]]
    (let [form (get db :feat.material.new.db/form)]
      {:db (-> db
               (assoc :feat.material.new.db/submitting? false)
               (remove-thumbnail)
               (remove-material))
       :antd/form-reset form
       :antd.form/set-fields-value [form {:material-file-id nil
                                          :thumbnail-file-id nil}]
       :dispatch [:toast-notification {:type :success
                                       :message "Material has been added."}]})))


;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :feat.material.new.sub/form
  (fn [db [_]]
    (get db :feat.material.new.db/form)))

(rf/reg-sub
  :feat.material.new.sub/thumbnail-preview
  (fn [db _]
    (get db :feat.material.new.db/thumbnail-preview)))

(rf/reg-sub
  :feat.material.new.sub/material-filename
  (fn [db _]
    (-> (get db :feat.material.new.db/material-file)
        (gobject/get "name"))))

(rf/reg-sub
  :feat.material.new.sub/material-file-uploaded?
  (fn [db _]
    (get db :feat.material.new.db/material-file-uploaded?)))

(rf/reg-sub
  :feat.material.new.sub/submitting?
  (fn [db _]
    (get db :feat.material.new.db/submitting? false)))
