(ns io.klei.lms.frontend.entity.viewer-school-admin
  (:require
    [pyramid.core :as p]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.utils :as utils]))

(rf/reg-event-fx
  :entity.viewer-school-admin/get-viewer-school-admin
  (fn [{db :db} _]
    {:http-xhrio
     (utils/http-map db {:method :get
                         :uri "/viewer/school-admin"
                         :on-success [:entity.viewer-school-admin/get-viewer-admin-response]
                         :on-failure []})}))

(rf/reg-event-db
  :entity.viewer-school-admin/get-viewer-admin-response
  (fn [db [_ response]]
    (let [current-ay (:current-academic-year response)
          response (update-in response
                              [:current-academic-year :academic-year/sections]
                              (fn [sections]
                                (->> sections
                                     (mapv #(assoc % :section/academic-year [:academic-year/id (:academic-year/id current-ay)])))))]
      (utils/add db response))))
