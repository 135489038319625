(ns io.klei.lms.frontend.entity.student
  (:require
    [pyramid.core :as p]
    [pyramid.query :as p.query]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.string :as gstring]
    [goog.string.format]
    [io.klei.lms.frontend.shared.config :as config]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn last-first-name [student]
  (->> [(:person/last-name student) (:person/first-name student)]
       (keep identity)
       (clojure.string/join ", ")))

(defn student-email [student]
  (some-> student :auth/user :user/email))

(defn signature-url [student file-id]
  (gstring/format "%s/%s/%s"
                  config/user-document-host
                  (-> student :auth/user :user/id)
                  file-id))

(def student-pull-pattern
  [:student/id
   :student/student-number
   :student/has-lrn?
   :student/lrn
   :student/returning?
   :student/previous-school-attended
   :student/preferred-distance-learning-modalities
   :student/medical-history
   :student/emergency-contact-person
   :student/signatory-person
   :student/close-kin
   :student/senior-high
   :student/supporting-documents
   :student/school-code
   {:student/latest-registration [:registration/id
                                  :registration/date
                                  {:registration/section [:section/id
                                                          :section/name]}
                                  {:registration/level [:level/id
                                                        :level/name]}]}
   {:auth/user [:user/id
                :user/username
                :user/email
                :user/roles
                {:user/organization [:organization/id]}
                {:user/school [:school/id]}]}
   :person/first-name
   :person/middle-name
   :person/last-name
   :person/extension-name
   :person/nickname
   :person/gender
   :person/date-of-birth
   :person/place-of-birth
   :person/psa-birth-certificate-number
   :person/email
   :person/mobile-number
   :person/citizenship
   :person/religion
   :person/skin-color
   :person/height
   :person/weight
   :person/skin-color
   :person/hair-color
   :person/eye-color
   :person/primary-language
   :person/mother-tongue
   :person/pwd?
   :person/disabilities
   :person/indigenous?
   :person/indigenous-group
   :person/beneficiary-4p?
   :person/household-number-4p
   :person/home-address-ph
   :person/home-address-uae
   :person/father
   :person/mother
   :person/guardian])

(rf/reg-sub
  :entity.student.sub/one-by-user-id
  (fn [db [_ user-id]]
    (let [res (p.query/q [:find '?e
                          :where
                          ['?e :auth/user '?user]
                          ['?user :user/id user-id]]
                         db)]
      (when (seq res)
        (utils/pull db (ffirst res) student-pull-pattern)))))

(rf/reg-sub
  :entity.student.sub/one-by-id
  (fn [db [_ student-id]]
    (let [res (p.query/q [:find '?e
                          :where
                          ['?e :student/id student-id]]
                         db)]
      (when (seq res)
        (utils/pull db (ffirst res) student-pull-pattern)))))

(rf/reg-event-fx
  :entity.student.event/get-by-school-id
  (fn [_ [_ school-id]]
    {:dispatch [::pathom/query {:entity {:school/id school-id}
                                :eql [{:school/students [:student/id
                                                         :student/student-number
                                                         :person/first-name
                                                         :person/last-name]}]
                                :on-success [:entity.student.event/get-by-school-id-success]}]}))

(rf/reg-event-fx
  :entity.student.event/get-by-school-id-success
  (fn [{:keys [db]} [_ response]]
    {:db (utils/add db response)}))

(rf/reg-sub
  :entity.student.sub/students-by-school-id
  (fn [db [_ school-id]]
    (utils/pull db [{:school/students [:student/id
                                       :student/student-number
                                       :person/first-name
                                       :person/last-name]}])))

(rf/reg-event-fx
  :entity.student.event/get-by-id
  (fn [_ [_ student-id]]
    {:dispatch [::pathom/query {:entity {:student/id student-id}
                                :eql [:student/id
                                      :student/student-number
                                      :student/latest-registration
                                      :person/first-name
                                      :person/last-name]
                                :on-success [:entity.student.event/get-by-id-success]}]}))

(rf/reg-event-fx
  :entity.student.event/get-by-id-success
  (fn [{:keys [db]} [_ response]]
    {:db (utils/add db response)}))

(rf/reg-event-fx
  :entity.student.event/get-registrations
  (fn [{:keys [db]} [_ user-id]]
    {:dispatch [::pathom/query {:entity {:user/id user-id}
                                :eql [:student/id
                                      :student/registrations]
                                :on-success [:entity.student.event/get-registrations-success]}]}))

(rf/reg-event-fx
  :entity.student.event/get-registrations-success
  (fn [{:keys [db]} [_ response]]
    {:db (utils/add db response)}))

(rf/reg-sub
  :entity.student.sub/registrations
  (fn [db [_ student-id]]
    (->> (utils/pull db [:student/id student-id] [{:student/registrations [:registration/id
                                                                           :registration/date
                                                                           :registration/school-code
                                                                           :registration/signatory-person
                                                                           :registration/signatory-person-signature-file-id
                                                                           :registration/signatory-person-signed-date
                                                                           :registration/acknowledged-code-of-conduct?
                                                                           :registration/signature-type
                                                                           {:registration/academic-year [:academic-year/id
                                                                                                         :academic-year/name]}
                                                                           {:registration/section [:section/id
                                                                                                   :section/name
                                                                                                   {:section/academic-year
                                                                                                    [:academic-year/id
                                                                                                     :academic-year/name]}]}
                                                                           {:registration/level [:level/id
                                                                                                 :level/name]}]}])
         :student/registrations
         (map (juxt :registration/id identity))
         (into {}))))
