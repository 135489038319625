(ns io.klei.lms.frontend.entity.user
  (:require
    [ajax.core :as ajax]
    [pyramid.core :as p]
    [pyramid.query :as p.query]
    [reagent.core :as r]
    [goog.string :as g.string]
    [clojure.set :as set]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.entity.student :as e.student]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [io.klei.lms.frontend.shared.re-frame.form-tx :as rf.form-tx]
    [io.klei.lms.frontend.shared.utils :as utils]))

;; poor mans is-a hierarchy
(def role-sets {:super-admin #{:super-admin}
                :org-admin #{:org-admin :super-admin}
                :school-admin #{:school-admin :org-admin :super-admin}
                :student #{:student}
                :teacher #{:teacher}})

(defn user-isa? [user role]
  (seq (set/intersection (:user/roles user) (role-sets role))))

(defn has-role? [user role]
  (contains? (:user/roles user) role))

(defn email [user]
  (some-> user :user/email))

(defn org-id [user]
  (some-> user
          :user/organization
          :organization/id))

(defn school-id [user]
  (some-> user
          :user/school
          :school/id))

(defn user-role [roles]
  (condp #(contains? %2 %1) (set roles)
    "student" "student"
    "teacher" "teacher"
    "school-admin" "school-admin"
    "org-admin" "org-admin"))

(def user-pull-pattern
  [:user/id
   :user/username
   :user/email
   :user/roles])

(defn pull-user [db user-id]
  (utils/pull db [:user/id user-id] user-pull-pattern))

(defn roles->keyword-set [roles]
  (or (some->> (seq roles)
               (map keyword)
               set)
      #{}))

(defn user-roles->keyword-set [user]
  (update user :user/roles roles->keyword-set))

(defn default-role [roles]
  (condp #(contains? %2 %1) (roles->keyword-set roles)
    :super-admin :super-admin
    :org-admin :org-admin
    :school-admin :school-admin
    :student :student
    :teacher :teacher
    ;; just to prevent some console errors when logging out.
    ;; seems like some subscriptions our getting triggered but user has been deleted to app db and localstorage
    :unknown-role))

(defn user->default-role [user]
  (default-role (:user/roles user)))

;;------------------------------------------------------------
;; UI
(defn user-form []
  (let [form_ (atom nil)
        handle-role-change (fn [e]
                             (.setFieldsValue @form_ #js {"user/roles" (.. e -target -value)}))]
    (fn [{:keys [initial-values on-submit actions school-options role-options]}]
      [ui/form {:ref #(reset! form_ %)
                :initialValues (clj->js (or initial-values {}))
                :onFinish #(on-submit @form_ %)}
       [ui/form-item
        {:label "Select Role"
         :name "user/roles"
         :rules [{:required true}]}
        [ui/radio-group
         [ui/space {:direction "vertical"}
          [ui/radio {:value "org-admin" :onChange handle-role-change} "Org Admin"]
          [ui/radio {:value "school-admin" :onChange handle-role-change} "School Admin"]
          [ui/radio {:value "teacher" :onChange handle-role-change} "Teacher"]
          [ui/radio {:value "student" :onChange handle-role-change} "Student"]]]]

       [ui/form-item
        {:label "School"
         :name "school/id"
         :rules [{:required true}]}
        [ui/select-with-options {:mode "multiple"
                                 :options school-options
                                 :onChange (fn [val]
                                             (.setFieldsValue @form_ #js {"school/id" val}))}]]

       [ui/form-item {:label "First Name"
                      :name "person/first-name"
                      :rules [{:required true}]}
        [ui/input]]

       [ui/form-item {:label "Last Name"
                      :name "person/last-name"
                      :rules [{:required true}]}
        [ui/input]]

       [ui/form-item {:label "Email"
                      :name "user/email"
                      :rules [{:required true}]}
        [ui/input]]

       [ui/form-item {:label "Username"
                      :name "user/username"
                      :rules [{:required true}]}
        [ui/input]]

       [ui/form-item {:wrapperCol {:span 24}}
        actions]])))



;;------------------------------------------------------------
;; EVENTS
(defn role->pull-pattern [role]
  (case (keyword role)
    :student
    e.student/student-pull-pattern


    user-pull-pattern))


(rf/reg-event-fx
  :entity.user.event/get-info
  (fn [{db :db} [_ user-id role]]
    {:db (assoc db :entity.user.db/get-info-fetch-status :in-flight)
     :dispatch [::pathom/query {:entity {:user/id user-id}
                                :eql (role->pull-pattern role)
                                :on-success [:entity.user.event/get-info-success user-id]}]}))

(rf/reg-event-db
  :entity.user.event/get-info-success
  (fn [db [_ _user-id response]]
    (-> db
        (assoc :entity.user.db/get-info-fetch-status :success)
        (utils/add response))))

(rf/reg-event-fx
  :entity.user.event/get-all
  (fn [{db :db} [_]]
    {:db (assoc db :entity.user.db/get-all-loading? true)
     :http-xhrio (utils/http-map db {:method :get
                                     :uri "/user"
                                     :on-success [:entity.user.event/get-all-success]})}))

(rf/reg-event-db
  :entity.user.event/get-all-success
  (fn [db [_ response]]
    (->> response
         (mapv user-roles->keyword-set)
         (utils/add db))))

(rf/reg-event-fx
  :entity.user.event/get
  (fn [_ [_ user-id]]
    {:dispatch [::pathom/query {:entity {:user/id user-id}
                                :eql user-pull-pattern
                                :on-success [:entity.user.event/get-success user-id]}]}))

(rf/reg-event-db
  :entity.user.event/get-success
  (fn [db [_ user-id response]]
    (utils/add db response)))


(rf/reg-sub
  :entity.user.sub/list
  (fn [db]
    (->> (for [id (keys (:user/id db))]
           (-> (p/pull db [{[:user/id id] user-pull-pattern}])
               (get [:user/id id])))
         (sort-by :user/username))))

(rf/reg-sub
  :entity.user.sub/one-by-id
  (fn [db [_ user-id]]
    (utils/pull db [:user/id user-id] user-pull-pattern)))

(rf/reg-event-fx
  :entity.user.event/add
  [(rf.form-tx/start :entity.user.event/add)]
  (fn [{db :db} [_ form values]]
    {::pathom/mutation {:op-name 'io.klei.lms.pathom.mutations/add-user
                        :params values
                        :on-success [:entity.user.event/add-success form]}}))


(rf/reg-event-fx
  :entity.user.event/add-success
  [(rf.form-tx/complete :entity.user.event/add {:message "User has been successfully added."})]
  (fn [{db :db} [_ _form response]]
    {:db (utils/add db response)}))

