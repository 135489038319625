(ns io.klei.lms.frontend.entity.teacher
  (:require
    [pyramid.core :as p]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.entity.user :as entities.user]))

(defn last-first-name [teacher]
  (str (:teacher/last-name teacher) ", " (:teacher/first-name teacher)))

(defn email [teacher]
  (some-> teacher :auth/user :user/email))

(defn ->teacher [teacher]
  {:id (:teacher/id teacher)
   :name (last-first-name teacher)
   :email (email teacher)
   :sections (->> (:teacher/sections teacher)
                  (sort-by :section/name))})

;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-fx
  :entity.teacher.event/get-all
  (fn [{db :db} _]
    {:http-xhrio (utils/http-map db {:method :get
                                     :uri "/teacher"
                                     :on-success [:entity.teacher.event/get-all-success]})}))

(rf/reg-event-db
  :entity.teacher.event/get-all-success
  (fn [db [_ response]]
    (when (seq response)
      (p/add db response))))

(rf/reg-event-fx
  :entity.teacher.event/add-teacher
  (fn [{db :db} [_ payload dispatch-fx]]
    {:http-xhrio (utils/http-map db [:method :post
                                     :uri "/teacher"
                                     :params payload
                                     :on-success [:entity.teacher.event/add-teacher-success dispatch-fx]])}))

(rf/reg-event-fx
  :entity.teacher.event/add-teacher-success
  (fn [{db :db} [_ dispatch-fx response]]
    (let [fx [[:dispatch [:toast-notification {:type "success"
                                               :message "Teacher has been successfully saved."}]]]]
      {:db (p/add db response)
       :fx (into fx (or dispatch-fx []))})))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :entity.teacher.sub/all-ids
  :<- [:entity-by-id :teacher/id]
  (fn [by-id _]
    (keys by-id)))

(rf/reg-sub
  :entity.teacher.sub/list
  :<- [:app-db]
  :<- [:entity.teacher.sub/all-ids]
  (fn [[db ids] _]
    (when (seq ids)
      (->> (utils/pull-many db :teacher/id ids [:teacher/id
                                                :teacher/first-name
                                                :teacher/last-name
                                                {:auth/user [:user/email]}
                                                {:teacher/sections [:section/id
                                                                    :section/name]}])
           (sort-by :teacher/first-name)
           (sort-by :teacher/last-name)))))

(rf/reg-sub
  :entity.teacher.sub/one-by-id
  (fn [db [_ teacher-id]]
    (when teacher-id
      (utils/pull db [:teacher/id teacher-id] [:teacher/id
                                               :teacher/first-name
                                               :teacher/middle-name
                                               :teacher/last-name
                                               {:auth/user [:user/email
                                                            :user/username]}
                                               {:teacher/sections [:section/id
                                                                   :section/name]}]))))

(rf/reg-sub
  :entity.teacher.sub/select-options
  :<- [:entity.teacher.sub/list]
  (fn [teachers _]
    (->> teachers
         (mapv (fn [teacher]
                 {:text (last-first-name teacher)
                  :value (:teacher/id teacher)}))
         (sort-by :text))))
