(ns io.klei.lms.frontend.feature.teacher-list
  (:require
    [clojure.string :as string]
    [goog.string :as gstring]
    [goog.string.format]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.object :as gobject]
    [io.klei.lms.frontend.entity.user :as entities.user]
    [io.klei.lms.frontend.entity.teacher :as entities.teacher]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn teacher-list [{:keys [on-click]}]
  (let [data-source @(rf/subscribe [:feat.teacher-list.sub/table-data])]
    [:div "Teacher List"]
    [ui/table
     {:className "kl-table kl-table--drawer kl-teacher-table"
      :dataSource data-source
      :columns [{:title "Name"
                 :key "name"
                 :dataIndex ["teacher" "name"]
                 :className "kl-teacher-table__name"}
                {:title "Email"
                 :key "email"
                 :dataIndex ["teacher" "email"]}
                {:title "Sections"
                 :key "sections"
                 :dataIndex ["teacher" "sections"]
                 :className "kl-teacher-table__sections"
                 :render (fn [^js record]
                           (r/as-element
                             (into [ui/space]
                                   (mapv (fn [section]
                                           [ui/tag (gobject/get section "name")])
                                         record))))}]
      :onRow (fn [^js record index]
               #js {:onClick (fn [^js e]
                              (when on-click
                                (on-click record index e)))})}]))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :feat.teacher-list.sub/table-data
  :<- [:entity.teacher.sub/list]
  (fn [teacher-list _]
    (->> teacher-list
         (mapv (fn [teacher]
                 {:key (:teacher/id teacher)
                  :teacher (entities.teacher/->teacher teacher)})))))

