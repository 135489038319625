(ns io.klei.lms.frontend.shared.route-utils
  (:require
    [reitit.frontend.easy :as rfe]))

(defn org-id [match]
  (some-> match
          :path-params
          :org-id))

(defn school-id [match]
  (some-> match
          :path-params
          :school-id))

(defn org+school-params [match]
  (some-> (get-in match [:path-params])
          (select-keys [:org-id :school-id])))

(defn page-href [& args]
  (apply rfe/href args))
