(ns io.klei.lms.frontend.page.profile
  (:require
    [ajax.core :as ajax]
    [re-frame.core :as rf]
    [reagent.core :as r]
    [goog.object :as gobject]
    [io.klei.lms.frontend.entity.current-user :as entities.current-user]
    [io.klei.lms.frontend.feature.change-password :as f.change-password]
    [io.klei.lms.frontend.feature.viewer-info :as f.viewer-info]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.shared.icon :as icon]))

(defn append-fields [^js form-data fields]
  (doseq [[k v] fields]
    (.append form-data (name k) v))
  form-data)

(def max-upload-size-mb 1)
(defn valid-size? [^js file]
  (< (/ (.-size file) 1000 1000) max-upload-size-mb))

;;------------------------------------------------------------
;; UI
(defn avatar-uploader [{:keys [src on-change uploading?]}]
  (r/with-let [state (r/atom {:uploading? false
                              :file nil})]
    [ui/img-crop
     {:shape "round"
      :rotate false
      :quality 0.5}
     [ui/upload
      {:openFileDialogOnClick true
       :showUploadList false
       ;; https://github.com/react-component/upload#customrequest
       :customRequest (fn [^js upload-object]
                        (rf/dispatch [:feat.upload-avatar.event/on-upload upload-object]))}

      [ui/spin
       {:tip "Uploading..."
        :spinning (-> @state :uploading?)}
       [ui/avatar {:src src
                   :size 180}]]]]))

(defn profile-content [{:keys [match]}]
  (let [avatar-src @(rf/subscribe [:entity.current-user.sub/avatar-url])]
    [:<>
     [ui/row
      [ui/col {:xs 24
               :sm 12}
       [:div.student-section
        [:div
         [avatar-uploader {:src avatar-src}]]

        [:div
         [f.viewer-info/viewer-info]]]]]
     [ui/divider]
     [ui/row
      [ui/col {:sm 17
               :xs 24}
       [ui/section-heading "Change Password"]
       [f.change-password/change-password-form]]]]))

;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-fx
  :feat.upload-avatar.event/on-upload
  (fn [{db :db} [_ ^js upload-object]]
    {:db (-> db
             (assoc :feat.upload-avatar.db/uploaded-file (gobject/get upload-object "file"))
             (assoc :feat.upload-avatar.db/upload-completed? false))
     :http-xhrio (utils/http-map db {:method :post
                                     :uri "/file/upload/start"
                                     :params {:upload-config-id :avatar
                                              :original-filename (gobject/getValueByKeys upload-object "file" "name")
                                              :content-length (gobject/getValueByKeys upload-object "file" "size")
                                              :content-type (gobject/getValueByKeys upload-object "file" "type")}
                                     :on-success [:feat.upload-avatar.event/presign-upload upload-object]})}))

(rf/reg-event-fx
  :feat.upload-avatar.event/presign-upload
  (fn [{db :db} [_ upload-object response]]
    (let [^js form-data (append-fields (js/FormData.)
                                       (dissoc (:fields response) :file-id))
          _ (.append form-data "file" (gobject/getValueByKeys upload-object "file"))]
      ;; s3 will return 204 no content. So the success response is nil.
      ;; Just passing the file-id
      {:http-xhrio {:method :post
                    :uri (:url response)
                    :body form-data
                    :response-format (ajax/json-response-format)
                    :progress-handler #(rf/dispatch [:feat.upload-avatar.event/on-progress upload-object %])
                    :on-success [:feat.upload-avatar.event/on-presign-upload-success (:file-id response)]}})))

(rf/reg-event-fx
  :feat.upload-avatar.event/on-progress
  (fn [{db :db} [_ upload-object progress-event]]
    {:antd/custom-request-progress [upload-object progress-event]}))

(rf/reg-event-fx
  :feat.upload-avatar.event/on-presign-upload-success
  (fn [{db :db} [_ file-id _]]
    {:http-xhrio (utils/http-map db {:method :post
                                     :uri "/file/upload/complete"
                                     :params {:file-id file-id}
                                     :on-success [:feat.upload-avatar.event/on-upload-complete-success file-id]})}))

(rf/reg-event-fx
  :feat.upload-avatar.event/on-upload-complete-success
  (fn [{db :db} [_ file-id _response]]
    {:http-xhrio (utils/http-map db {:method :post
                                     :uri "/user/avatar"
                                     :params {:file-id file-id}
                                     :on-success [:feat.upload-avatar.event/on-user-avatar-success file-id]})}))

(rf/reg-event-fx
  :feat.upload-avatar.event/on-user-avatar-success
  (fn [{db :db} [_ file-id _response]]
    {:dispatch [:entity.current-user.event/set-avatar file-id]
     :localstorage/set [(entities.current-user/user-avatar-localstorage-key (second (-> db :entity.current-user.db/user-id)))
                        file-id]}))

;;https://github.com/react-component/upload#customrequest
(rf/reg-fx
  :antd/custom-request-progress
  (fn [[^js upload-object ^js progress-event]]
    (.onProgress upload-object #js {:percent (utils/progress-event-percent progress-event)})))

;;------------------------------------------------------------
;; SUBS

