(ns io.klei.lms.frontend.feature.update-unique-value
  (:require
    [pyramid.core :as p]
    [goog.string :as g.string]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [goog.object :as g.object]
    [goog.string.format]
    [malli.core :as m]
    [io.klei.lms.frontend.form.core :as f]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn attribute-v [attr entity]
  [attr (get entity attr)])

(defn form [{:keys [unique-name entity key attribute extra]
             :or {extra {}}}]
  (let [ident (attribute-v key entity)
        attribute* (attribute-v attribute entity)
        registry @(rf/subscribe [:entity.schema.sub/registry])
        title (:title (m/properties (get registry attribute)))
        submit-text (g.string/format "Change %s" title)
        message (g.string/format "%s has been successfully updated" title)]
    [f/form
     {:init-val {(first attribute*) (second attribute*)}
      :schema [:map (first attribute*)]
      :submit-text submit-text
      :on-submit (fn [ctx form values]
                   (rf/dispatch [::event.submit
                                 unique-name
                                 ident
                                 (merge (js->clj values :keyword-fn utils/json-key->nskw) extra)
                                 message]))}]))

(rf/reg-event-fx
  ::event.submit
  (fn [{:keys [db]} [_ unique-name ident values message]]
    {:dispatch [::pathom/mutation {:op-name 'io.klei.lms.pathom.mutations/update-unique-attribute
                                   :params {:name unique-name
                                            :ident ident
                                            :value values}
                                   :on-success [::event.submit-success message]}]}))

(rf/reg-event-fx
  ::event.submit-success
  (fn [{:keys [db]} [_ message response]]
    {:dispatch [:toast-notification
                {:type :success
                 :message message}]}))
