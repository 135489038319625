(ns cljc.java-time.local-date-time (:refer-clojure :exclude [abs get range format min max next name resolve short]) (:require [cljc.java-time.extn.calendar-awareness] [goog.object] [java.time :refer [LocalDateTime]]))
(def max (goog.object/get java.time.LocalDateTime "MAX"))
(def min (goog.object/get java.time.LocalDateTime "MIN"))
(clojure.core/defn minus-minutes {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13415 ^long long13416] (.minusMinutes this13415 long13416)))
(clojure.core/defn truncated-to {:arglists (quote (["java.time.LocalDateTime" "java.time.temporal.TemporalUnit"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13417 ^js/JSJoda.TemporalUnit java-time-temporal-TemporalUnit13418] (.truncatedTo this13417 java-time-temporal-TemporalUnit13418)))
(clojure.core/defn minus-weeks {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13419 ^long long13420] (.minusWeeks this13419 long13420)))
(clojure.core/defn to-instant {:arglists (quote (["java.time.LocalDateTime" "java.time.ZoneOffset"]))} (^js/JSJoda.Instant [^js/JSJoda.LocalDateTime this13421 ^js/JSJoda.ZoneOffset java-time-ZoneOffset13422] (.toInstant this13421 java-time-ZoneOffset13422)))
(clojure.core/defn plus-weeks {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13423 ^long long13424] (.plusWeeks this13423 long13424)))
(clojure.core/defn range {:arglists (quote (["java.time.LocalDateTime" "java.time.temporal.TemporalField"]))} (^js/JSJoda.ValueRange [^js/JSJoda.LocalDateTime this13425 ^js/JSJoda.TemporalField java-time-temporal-TemporalField13426] (.range this13425 java-time-temporal-TemporalField13426)))
(clojure.core/defn of-epoch-second {:arglists (quote (["long" "int" "java.time.ZoneOffset"]))} (^js/JSJoda.LocalDateTime [^long long13427 ^int int13428 ^js/JSJoda.ZoneOffset java-time-ZoneOffset13429] (js-invoke java.time.LocalDateTime "ofEpochSecond" long13427 int13428 java-time-ZoneOffset13429)))
(clojure.core/defn get-hour {:arglists (quote (["java.time.LocalDateTime"]))} (^int [^js/JSJoda.LocalDateTime this13430] (.hour this13430)))
(clojure.core/defn at-offset {:arglists (quote (["java.time.LocalDateTime" "java.time.ZoneOffset"]))} (^js/JSJoda.OffsetDateTime [^js/JSJoda.LocalDateTime this13431 ^js/JSJoda.ZoneOffset java-time-ZoneOffset13432] (.atOffset this13431 java-time-ZoneOffset13432)))
(clojure.core/defn minus-hours {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13433 ^long long13434] (.minusHours this13433 long13434)))
(clojure.core/defn of {:arglists (quote (["int" "java.time.Month" "int" "int" "int" "int"] ["int" "java.time.Month" "int" "int" "int" "int" "int"] ["int" "java.time.Month" "int" "int" "int"] ["java.time.LocalDate" "java.time.LocalTime"] ["int" "int" "int" "int" "int"] ["int" "int" "int" "int" "int" "int"] ["int" "int" "int" "int" "int" "int" "int"]))} (^js/JSJoda.LocalDateTime [G__13436 G__13437 G__13438 G__13439 G__13440 G__13441] (js-invoke java.time.LocalDateTime "of" G__13436 G__13437 G__13438 G__13439 G__13440 G__13441)) (^js/JSJoda.LocalDateTime [G__13443 G__13444 G__13445 G__13446 G__13447 G__13448 G__13449] (js-invoke java.time.LocalDateTime "of" G__13443 G__13444 G__13445 G__13446 G__13447 G__13448 G__13449)) (^js/JSJoda.LocalDateTime [G__13451 G__13452 G__13453 G__13454 G__13455] (js-invoke java.time.LocalDateTime "of" G__13451 G__13452 G__13453 G__13454 G__13455)) (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDate java-time-LocalDate13456 ^js/JSJoda.LocalTime java-time-LocalTime13457] (js-invoke java.time.LocalDateTime "of" java-time-LocalDate13456 java-time-LocalTime13457)))
(clojure.core/defn with-month {:arglists (quote (["java.time.LocalDateTime" "int"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13458 ^int int13459] (.withMonth this13458 int13459)))
(clojure.core/defn is-equal {:arglists (quote (["java.time.LocalDateTime" "java.time.chrono.ChronoLocalDateTime"]))} (^boolean [^js/JSJoda.LocalDateTime this13460 ^js/JSJoda.ChronoLocalDateTime java-time-chrono-ChronoLocalDateTime13461] (.isEqual this13460 java-time-chrono-ChronoLocalDateTime13461)))
(clojure.core/defn get-nano {:arglists (quote (["java.time.LocalDateTime"]))} (^int [^js/JSJoda.LocalDateTime this13462] (.nano this13462)))
(clojure.core/defn get-year {:arglists (quote (["java.time.LocalDateTime"]))} (^int [^js/JSJoda.LocalDateTime this13463] (.year this13463)))
(clojure.core/defn minus-seconds {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13464 ^long long13465] (.minusSeconds this13464 long13465)))
(clojure.core/defn get-second {:arglists (quote (["java.time.LocalDateTime"]))} (^int [^js/JSJoda.LocalDateTime this13466] (.second this13466)))
(clojure.core/defn plus-nanos {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13467 ^long long13468] (.plusNanos this13467 long13468)))
(clojure.core/defn get-day-of-year {:arglists (quote (["java.time.LocalDateTime"]))} (^int [^js/JSJoda.LocalDateTime this13469] (.dayOfYear this13469)))
(clojure.core/defn plus {:arglists (quote (["java.time.LocalDateTime" "java.time.temporal.TemporalAmount"] ["java.time.LocalDateTime" "long" "java.time.temporal.TemporalUnit"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13470 ^js/JSJoda.TemporalAmount java-time-temporal-TemporalAmount13471] (.plus this13470 java-time-temporal-TemporalAmount13471)) (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13472 ^long long13473 ^js/JSJoda.TemporalUnit java-time-temporal-TemporalUnit13474] (.plus this13472 long13473 java-time-temporal-TemporalUnit13474)))
(clojure.core/defn with-hour {:arglists (quote (["java.time.LocalDateTime" "int"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13475 ^int int13476] (.withHour this13475 int13476)))
(clojure.core/defn with-minute {:arglists (quote (["java.time.LocalDateTime" "int"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13477 ^int int13478] (.withMinute this13477 int13478)))
(clojure.core/defn plus-minutes {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13479 ^long long13480] (.plusMinutes this13479 long13480)))
(clojure.core/defn query {:arglists (quote (["java.time.LocalDateTime" "java.time.temporal.TemporalQuery"]))} (^java.lang.Object [^js/JSJoda.LocalDateTime this13481 ^js/JSJoda.TemporalQuery java-time-temporal-TemporalQuery13482] (.query this13481 java-time-temporal-TemporalQuery13482)))
(clojure.core/defn get-day-of-week {:arglists (quote (["java.time.LocalDateTime"]))} (^js/JSJoda.DayOfWeek [^js/JSJoda.LocalDateTime this13483] (.dayOfWeek this13483)))
(clojure.core/defn to-string {:arglists (quote (["java.time.LocalDateTime"]))} (^java.lang.String [^js/JSJoda.LocalDateTime this13484] (.toString this13484)))
(clojure.core/defn plus-months {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13485 ^long long13486] (.plusMonths this13485 long13486)))
(clojure.core/defn is-before {:arglists (quote (["java.time.LocalDateTime" "java.time.chrono.ChronoLocalDateTime"]))} (^boolean [^js/JSJoda.LocalDateTime this13487 ^js/JSJoda.ChronoLocalDateTime java-time-chrono-ChronoLocalDateTime13488] (.isBefore this13487 java-time-chrono-ChronoLocalDateTime13488)))
(clojure.core/defn minus-months {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13489 ^long long13490] (.minusMonths this13489 long13490)))
(clojure.core/defn minus {:arglists (quote (["java.time.LocalDateTime" "long" "java.time.temporal.TemporalUnit"] ["java.time.LocalDateTime" "java.time.temporal.TemporalAmount"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13491 ^long long13492 ^js/JSJoda.TemporalUnit java-time-temporal-TemporalUnit13493] (.minus this13491 long13492 java-time-temporal-TemporalUnit13493)) (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13494 ^js/JSJoda.TemporalAmount java-time-temporal-TemporalAmount13495] (.minus this13494 java-time-temporal-TemporalAmount13495)))
(clojure.core/defn at-zone {:arglists (quote (["java.time.LocalDateTime" "java.time.ZoneId"]))} (^js/JSJoda.ZonedDateTime [^js/JSJoda.LocalDateTime this13496 ^js/JSJoda.ZoneId java-time-ZoneId13497] (.atZone this13496 java-time-ZoneId13497)))
(clojure.core/defn plus-hours {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13498 ^long long13499] (.plusHours this13498 long13499)))
(clojure.core/defn plus-days {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13500 ^long long13501] (.plusDays this13500 long13501)))
(clojure.core/defn to-local-time {:arglists (quote (["java.time.LocalDateTime"]))} (^js/JSJoda.LocalTime [^js/JSJoda.LocalDateTime this13502] (.toLocalTime this13502)))
(clojure.core/defn get-long {:arglists (quote (["java.time.LocalDateTime" "java.time.temporal.TemporalField"]))} (^long [^js/JSJoda.LocalDateTime this13503 ^js/JSJoda.TemporalField java-time-temporal-TemporalField13504] (.getLong this13503 java-time-temporal-TemporalField13504)))
(clojure.core/defn with-year {:arglists (quote (["java.time.LocalDateTime" "int"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13505 ^int int13506] (.withYear this13505 int13506)))
(clojure.core/defn with-nano {:arglists (quote (["java.time.LocalDateTime" "int"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13507 ^int int13508] (.withNano this13507 int13508)))
(clojure.core/defn to-epoch-second {:arglists (quote (["java.time.LocalDateTime" "java.time.ZoneOffset"]))} (^long [^js/JSJoda.LocalDateTime this13509 ^js/JSJoda.ZoneOffset java-time-ZoneOffset13510] (.toEpochSecond this13509 java-time-ZoneOffset13510)))
(clojure.core/defn until {:arglists (quote (["java.time.LocalDateTime" "java.time.temporal.Temporal" "java.time.temporal.TemporalUnit"]))} (^long [^js/JSJoda.LocalDateTime this13511 ^js/JSJoda.Temporal java-time-temporal-Temporal13512 ^js/JSJoda.TemporalUnit java-time-temporal-TemporalUnit13513] (.until this13511 java-time-temporal-Temporal13512 java-time-temporal-TemporalUnit13513)))
(clojure.core/defn with-day-of-month {:arglists (quote (["java.time.LocalDateTime" "int"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13514 ^int int13515] (.withDayOfMonth this13514 int13515)))
(clojure.core/defn get-day-of-month {:arglists (quote (["java.time.LocalDateTime"]))} (^int [^js/JSJoda.LocalDateTime this13516] (.dayOfMonth this13516)))
(clojure.core/defn from {:arglists (quote (["java.time.temporal.TemporalAccessor"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.TemporalAccessor java-time-temporal-TemporalAccessor13517] (js-invoke java.time.LocalDateTime "from" java-time-temporal-TemporalAccessor13517)))
(clojure.core/defn is-after {:arglists (quote (["java.time.LocalDateTime" "java.time.chrono.ChronoLocalDateTime"]))} (^boolean [^js/JSJoda.LocalDateTime this13518 ^js/JSJoda.ChronoLocalDateTime java-time-chrono-ChronoLocalDateTime13519] (.isAfter this13518 java-time-chrono-ChronoLocalDateTime13519)))
(clojure.core/defn minus-nanos {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13520 ^long long13521] (.minusNanos this13520 long13521)))
(clojure.core/defn is-supported {:arglists (quote (["java.time.LocalDateTime" "java.time.temporal.TemporalField"] ["java.time.LocalDateTime" "java.time.temporal.TemporalUnit"]))} (^boolean [this13522 G__13523] (.isSupported ^js/JSJoda.LocalDateTime this13522 G__13523)))
(clojure.core/defn minus-years {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13524 ^long long13525] (.minusYears this13524 long13525)))
(clojure.core/defn get-chronology {:arglists (quote (["java.time.LocalDateTime"]))} (^js/JSJoda.Chronology [^js/JSJoda.LocalDateTime this13526] (.chronology this13526)))
(clojure.core/defn parse {:arglists (quote (["java.lang.CharSequence" "java.time.format.DateTimeFormatter"] ["java.lang.CharSequence"]))} (^js/JSJoda.LocalDateTime [^java.lang.CharSequence java-lang-CharSequence13527 ^js/JSJoda.DateTimeFormatter java-time-format-DateTimeFormatter13528] (js-invoke java.time.LocalDateTime "parse" java-lang-CharSequence13527 java-time-format-DateTimeFormatter13528)) (^js/JSJoda.LocalDateTime [^java.lang.CharSequence java-lang-CharSequence13529] (js-invoke java.time.LocalDateTime "parse" java-lang-CharSequence13529)))
(clojure.core/defn with-second {:arglists (quote (["java.time.LocalDateTime" "int"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13530 ^int int13531] (.withSecond this13530 int13531)))
(clojure.core/defn to-local-date {:arglists (quote (["java.time.LocalDateTime"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDateTime this13532] (.toLocalDate this13532)))
(clojure.core/defn get-minute {:arglists (quote (["java.time.LocalDateTime"]))} (^int [^js/JSJoda.LocalDateTime this13533] (.minute this13533)))
(clojure.core/defn hash-code {:arglists (quote (["java.time.LocalDateTime"]))} (^int [^js/JSJoda.LocalDateTime this13534] (.hashCode this13534)))
(clojure.core/defn adjust-into {:arglists (quote (["java.time.LocalDateTime" "java.time.temporal.Temporal"]))} (^js/JSJoda.Temporal [^js/JSJoda.LocalDateTime this13535 ^js/JSJoda.Temporal java-time-temporal-Temporal13536] (.adjustInto this13535 java-time-temporal-Temporal13536)))
(clojure.core/defn with {:arglists (quote (["java.time.LocalDateTime" "java.time.temporal.TemporalField" "long"] ["java.time.LocalDateTime" "java.time.temporal.TemporalAdjuster"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13537 ^js/JSJoda.TemporalField java-time-temporal-TemporalField13538 ^long long13539] (.with this13537 java-time-temporal-TemporalField13538 long13539)) (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13540 ^js/JSJoda.TemporalAdjuster java-time-temporal-TemporalAdjuster13541] (.with this13540 java-time-temporal-TemporalAdjuster13541)))
(clojure.core/defn now {:arglists (quote ([] ["java.time.ZoneId"] ["java.time.Clock"]))} (^js/JSJoda.LocalDateTime [] (js-invoke java.time.LocalDateTime "now")) (^js/JSJoda.LocalDateTime [G__13543] (js-invoke java.time.LocalDateTime "now" G__13543)))
(clojure.core/defn get-month-value {:arglists (quote (["java.time.LocalDateTime"]))} (^int [^js/JSJoda.LocalDateTime this13544] (.monthValue this13544)))
(clojure.core/defn with-day-of-year {:arglists (quote (["java.time.LocalDateTime" "int"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13545 ^int int13546] (.withDayOfYear this13545 int13546)))
(clojure.core/defn compare-to {:arglists (quote (["java.time.LocalDateTime" "java.time.chrono.ChronoLocalDateTime"]))} (^int [^js/JSJoda.LocalDateTime this13547 ^js/JSJoda.ChronoLocalDateTime java-time-chrono-ChronoLocalDateTime13548] (.compareTo this13547 java-time-chrono-ChronoLocalDateTime13548)))
(clojure.core/defn get-month {:arglists (quote (["java.time.LocalDateTime"]))} (^js/JSJoda.Month [^js/JSJoda.LocalDateTime this13549] (.month this13549)))
(clojure.core/defn of-instant {:arglists (quote (["java.time.Instant" "java.time.ZoneId"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.Instant java-time-Instant13550 ^js/JSJoda.ZoneId java-time-ZoneId13551] (js-invoke java.time.LocalDateTime "ofInstant" java-time-Instant13550 java-time-ZoneId13551)))
(clojure.core/defn plus-seconds {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13552 ^long long13553] (.plusSeconds this13552 long13553)))
(clojure.core/defn get {:arglists (quote (["java.time.LocalDateTime" "java.time.temporal.TemporalField"]))} (^int [^js/JSJoda.LocalDateTime this13554 ^js/JSJoda.TemporalField java-time-temporal-TemporalField13555] (.get this13554 java-time-temporal-TemporalField13555)))
(clojure.core/defn equals {:arglists (quote (["java.time.LocalDateTime" "java.lang.Object"]))} (^boolean [^js/JSJoda.LocalDateTime this13556 ^java.lang.Object java-lang-Object13557] (.equals this13556 java-lang-Object13557)))
(clojure.core/defn format {:arglists (quote (["java.time.LocalDateTime" "java.time.format.DateTimeFormatter"]))} (^java.lang.String [^js/JSJoda.LocalDateTime this13558 ^js/JSJoda.DateTimeFormatter java-time-format-DateTimeFormatter13559] (.format this13558 java-time-format-DateTimeFormatter13559)))
(clojure.core/defn plus-years {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13560 ^long long13561] (.plusYears this13560 long13561)))
(clojure.core/defn minus-days {:arglists (quote (["java.time.LocalDateTime" "long"]))} (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDateTime this13562 ^long long13563] (.minusDays this13562 long13563)))
