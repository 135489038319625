(ns io.klei.lms.frontend.entity.school
  (:require
    [goog.string :as gstring]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.re-frame.form-tx :as rf.form-tx]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn school-card [props & children]
  (into [ui/card props]
        children))

(defn form []
  (let [*form (atom nil)]
    (fn [{:keys [initial-values on-submit actions]}]
      [ui/form {:ref #(reset! *form %)
                :initialValues (clj->js (or initial-values {}))
                :onFinish #(on-submit @*form %)}
       [ui/form-item {:label "Name"
                      :name "name"
                      :rules [{:required true}]}
        [ui/input]]

       [ui/form-item {:label "Code"
                      :name "code"
                      :rules [{:required true}]}
        [ui/input]]

       [ui/form-item {:wrapperCol {:span 24}}
        actions]])))


(rf/reg-event-fx
  :entity.school.event/get-by-org-id
  (fn [{db :db} [_ org-id]]
    {:http-xhrio (utils/http-map db {:method :get
                                     :uri "/school"
                                     :params {:org-id org-id}
                                     :on-success [:e.school.event/get-by-org-id-success]})}))

(rf/reg-event-fx
  :e.school.event/get-by-org-id-success
  (fn [{db :db} [_ response]]
    {:db (utils/add db response)}))

(def ^:private school-pull-pattern
  [:school/id
   :school/code
   :school/name
   {:school/organization [:organization/id
                          :organization/name]}])

(rf/reg-sub
  :e.school.sub/by-id
  (fn [db]
    (-> (get db :school/id)
        (keys))))


(rf/reg-sub
  :e.school.sub/one-by-id
  (fn [db [_ school-id]]
    (when school-id
      (utils/pull db [:school/id school-id] school-pull-pattern))))


(rf/reg-sub
  :e.school.sub/list
  (fn [db _]
    (let [ids (-> (get db :school/id)
                  (keys))]
      (some->> (utils/pull-many db :school/id ids school-pull-pattern)
               (sort-by :school/name)))))

(rf/reg-event-fx
  :e.school.event/add
  [(rf.form-tx/start :e.school.event/add)]
  (fn [{db :db} [_ form values]]
    {:http-xhrio (utils/http-map db {:method :post
                                     :uri "/school"
                                     :params values
                                     :on-success [:e.school.event/add-success form]})}))

(rf/reg-event-fx
  :e.school.event/add-success
  [(rf.form-tx/complete :e.school.event/add {:message "School has been successfully added."})]
  (fn [{db :db} [_ _form response]]
    {:db (utils/add db response)}))

(rf/reg-event-fx
  :e.school.event/edit
  [(rf.form-tx/start :e.school.event/edit)]
  (fn [{db :db} [_ _form school-id values]]
    {:http-xhrio (utils/http-map db {:method :patch
                                     :uri (gstring/format "/school/%s" school-id)
                                     :params values
                                     :on-success [:e.school.event/edit-success]})}))

(rf/reg-event-fx
  :e.school.event/edit-success
  [(rf.form-tx/complete :e.school.event/edit {:message "School has been successfully updated."})]
  (fn [{db :db} [_ _form response]]
    {:db (utils/add db response)}))

(rf/reg-sub
  :entity.school.sub/all-ids
  :<- [:entity-by-id :school/id]
  (fn [by-id _]
    (keys by-id)))

(rf/reg-sub
  :entity.school.sub/select-options
  :<- [:entity-by-id :school/id]
  :<- [:entity.school.sub/all-ids]
  (fn [[by-id ids] [_]]
    (->> ids
         (map (comp (fn [school]
                      {:value (:school/id school)
                       :text (:school/name school)})
                    (fn [id]
                      (by-id id))))
         (sort-by :text))))
