(ns io.klei.schema.malli
  (:require
   [malli.core :as m]
   [malli.experimental.time.generator]
   [malli.experimental.time :as m.time]
   [malli.util :as m.util]))

(defn data-schemas->malli-schemas [schemas]
  (->> schemas
       (map (fn [[k schema]]
              [k (:malli/schema schema)]))
       (into {})))

(defn make-schema-registry [model]
  (merge
   (m/default-schemas)
   (m.util/schemas)
   (m.time/schemas)
   (merge
    (data-schemas->malli-schemas (:data-schemas model))
    (:attributes model))))
