(ns io.klei.lms.frontend.entity.academic-year
  (:require
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.utils :as utils]))

;;------------------------------------------------------------
;; UTILS
(defn set-academic-year [db academic-year]
  (assoc db :entity.academic-year.db/academic-year academic-year))

(defn academic-years-by-date
  ([by-id] (academic-years-by-date by-id >))
  ([by-id sort-by-fn]
   (->> by-id
        (sort-by (comp :academic-year/start-date second) sort-by-fn))))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :entity.academic-year.sub/current
  (fn [db _]
    (when-let [ay-ident (get db :current-academic-year)]
      (utils/pull db ay-ident))))

(rf/reg-sub
  :entity.academic-year.sub/latest
  :<- [:entity-by-id :academic-year/id]
  (fn [by-id _]
    (->> (vals by-id)
         (filter (fn [ay]
                   (contains? #{"ongoing" "enrollment"} (:academic-year/state ay))))
         (sort-by :academic-year/start-date >)
         (first))))

(rf/reg-sub
  :entity.academic-year.sub/academic-year
  (fn [db _]
    (get db :entity.academic-year.db/academic-year)))

(rf/reg-sub
  :entity.academic-year.sub/select-options
  :<- [:entity-by-id :academic-year/id]
  (fn [by-id]
    (->> by-id
         (sort-by (comp :academic-year/start-date second) >)
         (mapv (fn [[_ academic-year]]
                 {:value (:academic-year/id academic-year)
                  :text (:academic-year/name academic-year)
                  :label (:academic-year/name academic-year)})))))

(rf/reg-sub
  :entity.academic-year.sub/by-level-section-options
  :<- [:entity.academic-year.db/academic-year]
  (fn [academic-year [_ level-id]]
    (some->> academic-year
             :academic-year/sections
             (filter (fn [section]
                       (= (:section/level section) level-id)))
             (map (fn [ay]
                    {:value (:section/id ay)
                     ;; keeping :text just in case needed for non antd select
                     :text (:section/name ay)
                     ;; antd
                     :label (:section/name ay)})))))
