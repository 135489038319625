(ns io.klei.lms.frontend.feature.analytics
  (:require [io.klei.lms.frontend.entity.current-user :as e.current-user]
            [re-frame.core :as rf]
            [taoensso.timbre :as log]))

(def page->event
  {:page.school-admin/register-new-student "register-new-student"
   :page.school-admin/register-existing-student "register-existing-student"
   :page.school-admin/material "material"
   :page.school-admin/new-material "new-material"
   :page.school-admin/teacher "teacher"
   :page.school-admin/sections "sections"})

(def upgraded-events
  "Mapping of priority events.
  :upgrade-fn returns a vector of two elements: [boolean, string]. The first element is a boolean, when true
  the :msclarity/upgrade will get triggered passing second element as reason."
  {"forms-section-changed" {:upgrade-fn (fn [{:keys [section]}]
                                         [(contains? #{"signature" "supporting-documents"} section)
                                          (str "Form section changed to " section)])}
   "register-new-student" {:upgrade-fn (constantly [true "Register new student"])}
   "register-existing-student" {:upgrade-fn (constantly [true "Register existing student"])}})

(def all-events (set (concat
                       (vals page->event)
                       (keys upgraded-events))))

(defn navigate-fx [db route-kw opts]
  (let [user (e.current-user/current-user db)
        session-id (e.current-user/get-session-id db)
        page-name (str (symbol route-kw))]
    (cond-> [[:dispatch [:analytics/identify {:id (:user/id user)
                                              :session-id session-id
                                              :page-id page-name
                                              :friendly-name (:user/username user)}]]]
     (page->event route-kw)
      (into [[:dispatch [:analytics/event (page->event route-kw)]]]))))

(rf/reg-event-fx
  :analytics/identify
  (fn [_ [_ params]]
    {:fx [[:msclarity/identify params]]}))

(rf/reg-event-fx
  :analytics/event
  (fn [_ [_ name params]]
    (let [upgrade-fn (get-in upgraded-events [name :upgrade-fn] (constantly [false nil]))
          [upgrade? reason] (upgrade-fn params)]
      (if (contains? all-events name)
        {:fx (cond-> [[:msclarity/event name]
                      [:msclarity/set params]]
              upgrade?
              (into [[:msclarity/upgrade reason]]))}
        (log/error "Unknown analytics/event name" name)))))

(rf/reg-event-fx
  :analytics/tags
  (fn [_ [_ params]]
    {:fx [[:msclarity/set params]]}))
