(ns io.klei.lms.frontend.feature.user.edit-email
  (:require
    [pyramid.core :as p]
    [goog.string :as g.string]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.object :as g.object]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(rf/reg-event-db
  :feat.user-edit-email.event/close
  (fn [db _]
    (assoc db :feat.user-edit-email.db/selected-user-id nil)))

(rf/reg-sub
  :feat.user-edit-email.sub/submitting?
  (fn [db _]
    (get db :feat.user-edit-email.db/submitting? false)))

(rf/reg-event-fx
  :feat.user-edit-email.event/change-email
  (fn [{db :db} [_ user-id values]]
    {:db (assoc db :feat.user-edit-email.db/submitting? true)
     :http-xhrio (utils/http-map db {:method :post
                                     :uri (g.string/format "/user/%s/change-email" user-id)
                                     :params values
                                     :on-success [:feat.user-edit-email.event/change-email-success]
                                     :on-failure [:feat.user-edit-email.event/change-email-failure]})}))

(rf/reg-event-fx
  :feat.user-edit-email.event/change-email-success
  (fn [{db :db} [_ response]]
    {:db (-> db
             (assoc :feat.user-edit-email.db/submitting? false)
             (utils/add response))
     :dispatch [:toast-notification {:type :success
                                     :message "User email has been updated."}]}))

(rf/reg-event-fx
  :feat.user-edit-email.event/change-email-failure
  (fn [{db :db} [_ failure]]
    {:db (assoc db :feat.user-edit-email.db/submitting? false)
     :dispatch [:toast-notification {:type :error
                                     :message (some-> failure :response :message)}]}))
