(ns io.klei.lms.frontend.entity.section
  (:require
    [re-frame.core :as rf]
    [pyramid.core :as p]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.shared.ui :as ui]))

;;------------------------------------------------------------
;; UTILS
(defn name-with-level [section]
  (let [level (:section/level section)]
    (str (:level/name level) " - " (:section/name section))))

;;------------------------------------------------------------
;; UI
(defn section-card [{:keys [data]}]
  [ui/card {:className "section-card"}
   (:section/name data)])



;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-fx
  :entity.section.event/get-by-id
  (fn [{db :db} [_ section-id]]
    {:http-xhrio
     (utils/http-map db {:method :get
                         :uri (str "/section/" section-id)
                         :on-success [:entity.section.event/get-by-id-success]})}))

(rf/reg-event-db
  :entity.section.event/get-by-id-success
  (fn [db [_ response]]
    (assoc db :entity.section.db/section response)))

(rf/reg-event-fx
  :entity.section.event/get-section-students
  (fn [{db :db} [_ section-id]]
    {:http-xhrio (utils/http-map db {:method :get
                                     :uri (str "/student/section/" section-id)
                                     :on-success [:entity.section.event/get-section-students-success]})}))

(rf/reg-event-db
  :entity.section.event/get-section-students-success
  (fn [db [_ response]]
    (assoc db :entity.section.db/section-students response)))

(rf/reg-event-fx
  :entity.section.event/get-sections-by-ay-level-id
  (fn [{:keys [db]} [_ ay-id level-id]]
    {:dispatch [::pathom/query {:entity {:academic-year/id ay-id
                                         :level/id level-id}
                                :eql [{:academic-year/sections-by-level
                                       [:section/id
                                        :section/name]}]
                                :on-success [:entity.section.event/get-sections-by-ay-level-id-success ay-id level-id]}]}))

(rf/reg-event-fx
  :entity.section.event/get-sections-by-ay-level-id-success
  (fn [{:keys [db]} [_ ay-id level-id response]]
    (let [ay-sections (get db :entity.section.db/by-ay-level-id {})]
      {:db (p/add db {:entity.section.db/by-ay-level-id
                      (assoc-in ay-sections
                                ;; pyramid.core/pull does not recognize string for nested map
                                [(keyword ay-id) (keyword level-id)]
                                (-> response :academic-year/sections-by-level))})})))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :entity.section.sub/section
  (fn [db _]
    (get db :entity.section.db/section [])))

(rf/reg-sub
  :entity.section.sub/section-students
  (fn [db _]
    (get db :entity.section.db/section-students [])))

(def section-pull-pattern
  [:section/id
   :section/name
   {:section/level [:level/id
                    :level/name]}
   {:section/teachers [:teacher/id
                       :teacher/first-name
                       :teacher/last-name]}
   {:section/course [:course/id
                     :course/name
                     {:course/subjects [:subject/id
                                        :subject/name]}]}
   {:section/schedules [:section-schedule/id
                        :section-schedule/start-time
                        :section-schedule/end-time
                        :section-schedule/day
                        {:section-schedule/subject [:subject/id
                                                    :subject/name]}]}
   {:section/academic-year [:academic-year/id
                            :academic-year/name]}])


(rf/reg-sub
  :entity.section.sub/one-by-id
  (fn [db [_ section-id]]
    (when section-id
      (utils/pull db [:section/id section-id] section-pull-pattern))))

(rf/reg-sub
  :entity.section.sub/all-ids
  :<- [:entity-by-id :section/id]
  (fn [by-id _]
    (keys by-id)))

(rf/reg-sub
  :entity.section.sub/list
  :<- [:app-db]
  :<- [:entity.section.sub/all-ids]
  (fn [[db ids] _]
    (some->> (utils/pull-many db :section/id ids section-pull-pattern)
             (sort-by :section/name))))

(rf/reg-sub
  :entity.section.sub/by-ay-level-id
  (fn [db [_ ay-id level-id]]
    (let [[ay-kw level-kw] [(keyword ay-id) (keyword level-id)]]
      (some-> (p/pull db [{:entity.section.db/by-ay-level-id
                          [{ay-kw [{level-kw [:section/id
                                              :section/name]}]}]}])
             (get-in [:entity.section.db/by-ay-level-id ay-kw level-kw])))))

(rf/reg-sub
  :entity.section.sub/select-options
  :<- [:entity-by-id :section/id]
  :<- [:entity.section.sub/all-ids]
  (fn [[by-id ids] [_]]
    (->> ids
         (map (comp (fn [section]
                      {:value (:section/id section)
                       :label (:section/name section)
                       :text (:section/name section)})
                    (fn [id]
                      (by-id id))))
         (sort-by :text))))

(rf/reg-sub
  :entity.section.sub/by-ay-level-id-select-options
  (fn [[_ ay-id level-id]]
    (rf/subscribe [:entity.section.sub/by-ay-level-id ay-id level-id]))
  (fn [sections]
    (->> sections
         (utils/entities->select-options [:section/id :section/name])
         (sort-by :text))))

(rf/reg-sub
  :entity.section.sub/academic-year-sections
  (fn [db _]
    (:academic-year/sections (p/pull db [{:academic-year/sections section-pull-pattern}]))))
