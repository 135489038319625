(ns io.klei.lms.frontend.page.forms
  (:require
    [pyramid.core :as p]
    ["react-responsive" :as responsive]
    [reagent.core :as r]
    [taoensso.timbre :as log]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.feature.student.profile-sections :as f.student.profile-sections]
    [io.klei.lms.frontend.shared.ui :as ui]))



(defn forms-content [{:keys [match]}]
  (let [user @(rf/subscribe [:entity.current-user.sub/user])]
    [:f> f.student.profile-sections/student-profile-sections
     {:user-id (:user/id user)}]))
