(ns io.klei.lms.frontend.entity.course
  (:require
    [pyramid.core :as p]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-fx
  :entity.course.event/get-all
  (fn [{db :db} [_]]
    {:db (assoc db :entity.course.db/get-all-loading? false)
     :http-xhrio (utils/http-map db {:method :get
                                     :uri "/course"
                                     :on-success [:entity.course.event/get-all-success]})}))

(rf/reg-event-fx
  :entity.course.event/get-all-success
  (fn [{db :db} [_ response]]
    {:db (-> db
             (assoc :entity.course.db/all-by-id (utils/normalize-sorted :course/id response :course/order))
             (assoc :entity.course.db/get-all-loading? true)
             (utils/add response))}))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :entity.course.sub/select-options
  :<- [:entity-by-id :course/id]
  (fn [by-id]
    (->> by-id
         (mapv (fn [[_ course]]
                 {:value (:course/id course)
                  :text (:course/name course)}))
         (sort-by :text))))

(def course-pull-pattern
  [:course/id
   :course/name
   {:course/subjects
    [:subject/id
     :subject/name]}])

(rf/reg-sub
  :entity.course.sub/list
  (fn [db]
    (->> (for [id (keys (:course/id db))]
           (-> (p/pull db [{[:course/id id] course-pull-pattern}])
               (get [:course/id id])))
         (sort-by :course/name))))

(rf/reg-sub
  :entity.course.sub/one-by-id
  (fn [db [_ course-id]]
    (when course-id
      (utils/pull db [:course/id course-id] course-pull-pattern))))
