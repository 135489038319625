(ns io.klei.lms.frontend.shared.re-frame.form-tx
  (:require
    [pyramid.core :as p]
    [goog.string :as gstring]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.object :as gobject]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn button-form-tx
  [{:keys [tx-id]}]
  (let [in-flight? @(rf/subscribe [:form-tx.sub/in-flight? tx-id])]
    [ui/button {:type "primary"
                :htmlType "submit"
                :loading in-flight?
                :style {:margin-left "auto"
                        :display "block"}}
     "Save"]))

(defn- set-tx-id [context tx-id]
  (assoc-in context [:coeffects :form-tx-id] tx-id))

(defn context-db [context]
  (or (-> context :effects :db)
      (-> context :coeffects :db)))

(defn start [tx-id]
  (rf/->interceptor
    :id :form-tx/start
    :before (fn start-before [context]
              (set-tx-id context tx-id))
    :after (fn start-after [context]
             (let [new-db (assoc-in (context-db context) [:form-tx.db/tx tx-id] {:status :in-flight})]
               (rf/assoc-effect context :db new-db)))))

(defn complete [tx-id {:keys [message]}]
  (rf/->interceptor
    :id :form-tx/complete
    :before (fn complete-before [context]
              (set-tx-id context tx-id))
    :after (fn complete-after [context]
             (let [event (rf/get-coeffect context :event)
                   form (second event)
                   new-db (update (context-db context) :form-tx.db/tx dissoc tx-id)]
               (-> context
                   (rf/assoc-effect :db new-db)
                   (rf/assoc-effect :antd/form-reset form)
                   (update-in [:effects :fx] conj [:dispatch [:toast-notification {:type :success
                                                                                   :message message}]]))))))
(rf/reg-sub
  :form-tx.sub/in-flight?
  (fn [db [_ tx-id]]
    (= (:status (get-in db [:form-tx.db/tx tx-id]))
       :in-flight)))
