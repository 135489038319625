(ns io.klei.lms.frontend.feature.sider
  (:require
    [clojure.set :as cset]
    [re-frame.core :as rf]
    [reagent.core :as r]
    [goog.object :as object]
    [clojure.string :as string]
    [io.klei.lms.frontend.entity.user :as e.user]
    [io.klei.lms.frontend.entity.current-user :as e.current-user]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.icon :as icon]))

;;------------------------------------------------------------
;; UI
(defn sider-menu []
  (let [selected-keys (rf/subscribe [:feat.sider.sub/selected-menu-keys])
        current-user-menu-items (rf/subscribe [:feat.sider.sub/current-user-menu-items])]
    [ui/menu {:theme "light"
              :selected-keys @selected-keys
              :items @current-user-menu-items
              :on-select (fn [o]
                           (rf/dispatch [:feat.sider.event/on-menu-select o]))}]))

;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-fx
  :feat.sider.event/on-menu-select
  (fn [{db :db} [_ o]]
    (let [cuser (e.current-user/current-user db)
          match (get db :router.db/match)
          org-id (or (-> match :path-params :org-id) (e.user/org-id cuser))
          school-id (or (-> match :path-params :school-id) (e.user/school-id cuser))
          page-id (keyword (object/get o "key"))
          ay-id (get-in db [:current-academic-year 1])
          fx (cond-> []
               (= page-id :page.school-admin/sections)
               (into [[:dispatch [:router.event/navigate page-id {:path-params {:org-id org-id
                                                                                :school-id school-id
                                                                                :ay-id ay-id}}]]])

               :else
               (into [[:dispatch [:router.event/navigate page-id {:path-params {:org-id org-id
                                                                                :school-id school-id
                                                                                :ay-id ay-id}}]]]))]

      {:db (-> db
               (assoc :feat.sider.db/selected-menu-keys (object/get o "selectedKeys")))
       :fx fx})))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :feat.sider.sub/selected-menu-keys
  :<- [:router.sub/match]
  (fn [match _]
    (some->> match
             :data
             :name
            (str)
            (drop 1)
            (string/join))))

(rf/reg-sub
  :feat.sider.sub/current-user-menu-items
  :<- [:entity.current-user.sub/user]
  (fn [user _]
    ;; Using the utils/ns-keyword->str will help during refactoring the name of keywords.
    ;; When using string directly, they are missed.
    (let [roles (or (:user/roles user) #{})
          admin-items-to-remove (cond
                                  (roles :school-admin) #{(utils/nskw->str :page.school/index)}
                                  :else #{})]
      (cond
        (seq (cset/intersection roles #{:school-admin :org-admin}))
        (remove
          (fn [{:keys [key]}]
            (contains? admin-items-to-remove key))
          [{:key (utils/nskw->str :page/dashboard)
            :label "Dashboard"
            :icon (r/as-element [icon/dashboard])}
           {:key (utils/nskw->str :page.school-admin/registration)
            :label "Registrations"
            :icon (r/as-element [icon/user-group-add])}
           {:key (utils/nskw->str :page.school-admin/material)
            :label "Materials"
            :icon (r/as-element [icon/book])}
           {:key (utils/nskw->str :page.student/index)
            :label "Students"
            :icon (r/as-element [icon/user])}
           {:key (utils/nskw->str :page.school-admin/teacher)
            :label "Trainers"
            :icon (r/as-element [icon/user])}
           {:key (utils/nskw->str :page.school-admin/sections)
            :label "Sections"
            :icon (r/as-element [icon/build])}
           {:key (utils/nskw->str :page.school-admin/course)
            :label "Courses"
            :icon (r/as-element [icon/build])}
           {:key (utils/nskw->str :page.school-admin/subject)
            :label "Subjects"
            :icon (r/as-element [icon/build])}
           {:key (utils/nskw->str :page.user/index)
            :label "Users"
            :icon (r/as-element [icon/user])}
           {:key (utils/nskw->str :page.school/index)
            :label "Schools"
            :icon (r/as-element [icon/build])}])

        (roles :student)
        [{:key (utils/nskw->str :page/dashboard)
          :label "Dashboard"
          :icon (r/as-element [icon/dashboard])}]

        (roles :teacher)
        [{:key (utils/nskw->str :page/dashboard)
          :label "Dashboard"
          :icon (r/as-element [icon/dashboard])}]

        :else
        []))))
