(ns io.klei.lms.frontend.page.login
  (:require
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.feature.login-by-password :as f.login-by-password]))

(defn login-page []
  [:div.login-page
   [:div.login-page__container
    [:div.login-page__content
     [:div.login-page__logo
      [:img {:src "img/klei-logo-light.svg"}]]
     [ui/title {:level 3
                :style {:text-align "center"}}
      "Login"]
     [:div.login-page__copy
      [ui/text {:type "secondary"
                :style {:margin "auto"}} "Welcome back! Please enter your login details."]]
     [:div {:style {:margin-top "32px"}}]
     [f.login-by-password/login-form]]]])
