(ns io.klei.lms.frontend.page.student
  (:require
    [pyramid.core :as p]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.feature.student.profile-sections :as f.profile-sections]
    [io.klei.lms.frontend.feature.student.new-student :as f.student-new]
    [io.klei.lms.frontend.feature.student.students-by-school :as f.student-list]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.route-utils :as r.utils]
    [io.klei.lms.frontend.shared.ui :as ui]))

(defn index-content [{:keys [match]}]
  [:<>
   #_[ui/button {:style {:margin-left "auto"
                         :display "block"
                         :marginBottom "8px"}
                 :icon (r/as-element [icon/plus])
                 :onClick #(rf/dispatch [:router.event/navigate :page.student/new {:path-params (r.utils/org+school-params match)}])}
      "Add Student"]
   [f.student-list/students-table]])

(defn new-student-content [{:keys [match]}]
  [ui/row
   [ui/col {:md 12
            :xs 24}
    [f.student-new/new-student-form (r.utils/org+school-params match)]]])
