(ns cljc.java-time.local-date (:refer-clojure :exclude [abs get range format min max next name resolve short]) (:require [cljc.java-time.extn.calendar-awareness] [goog.object] [java.time :refer [LocalDate]]))
(def max (goog.object/get java.time.LocalDate "MAX"))
(def min (goog.object/get java.time.LocalDate "MIN"))
(clojure.core/defn minus-weeks {:arglists (quote (["java.time.LocalDate" "long"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13154 ^long long13155] (.minusWeeks this13154 long13155)))
(clojure.core/defn plus-weeks {:arglists (quote (["java.time.LocalDate" "long"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13156 ^long long13157] (.plusWeeks this13156 long13157)))
(clojure.core/defn length-of-year {:arglists (quote (["java.time.LocalDate"]))} (^int [^js/JSJoda.LocalDate this13158] (.lengthOfYear this13158)))
(clojure.core/defn range {:arglists (quote (["java.time.LocalDate" "java.time.temporal.TemporalField"]))} (^js/JSJoda.ValueRange [^js/JSJoda.LocalDate this13159 ^js/JSJoda.TemporalField java-time-temporal-TemporalField13160] (.range this13159 java-time-temporal-TemporalField13160)))
(clojure.core/defn get-era {:arglists (quote (["java.time.LocalDate"]))} (^js/JSJoda.Era [^js/JSJoda.LocalDate this13161] (.era this13161)))
(clojure.core/defn of {:arglists (quote (["int" "int" "int"] ["int" "java.time.Month" "int"]))} (^js/JSJoda.LocalDate [G__13163 G__13164 G__13165] (js-invoke java.time.LocalDate "of" G__13163 G__13164 G__13165)))
(clojure.core/defn with-month {:arglists (quote (["java.time.LocalDate" "int"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13166 ^int int13167] (.withMonth this13166 int13167)))
(clojure.core/defn is-equal {:arglists (quote (["java.time.LocalDate" "java.time.chrono.ChronoLocalDate"]))} (^boolean [^js/JSJoda.LocalDate this13168 ^js/JSJoda.ChronoLocalDate java-time-chrono-ChronoLocalDate13169] (.isEqual this13168 java-time-chrono-ChronoLocalDate13169)))
(clojure.core/defn get-year {:arglists (quote (["java.time.LocalDate"]))} (^int [^js/JSJoda.LocalDate this13170] (.year this13170)))
(clojure.core/defn to-epoch-day {:arglists (quote (["java.time.LocalDate"]))} (^long [^js/JSJoda.LocalDate this13171] (.toEpochDay this13171)))
(clojure.core/defn get-day-of-year {:arglists (quote (["java.time.LocalDate"]))} (^int [^js/JSJoda.LocalDate this13172] (.dayOfYear this13172)))
(clojure.core/defn plus {:arglists (quote (["java.time.LocalDate" "long" "java.time.temporal.TemporalUnit"] ["java.time.LocalDate" "java.time.temporal.TemporalAmount"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13173 ^long long13174 ^js/JSJoda.TemporalUnit java-time-temporal-TemporalUnit13175] (.plus this13173 long13174 java-time-temporal-TemporalUnit13175)) (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13176 ^js/JSJoda.TemporalAmount java-time-temporal-TemporalAmount13177] (.plus this13176 java-time-temporal-TemporalAmount13177)))
(clojure.core/defn is-leap-year {:arglists (quote (["java.time.LocalDate"]))} (^boolean [^js/JSJoda.LocalDate this13178] (.isLeapYear this13178)))
(clojure.core/defn query {:arglists (quote (["java.time.LocalDate" "java.time.temporal.TemporalQuery"]))} (^java.lang.Object [^js/JSJoda.LocalDate this13179 ^js/JSJoda.TemporalQuery java-time-temporal-TemporalQuery13180] (.query this13179 java-time-temporal-TemporalQuery13180)))
(clojure.core/defn get-day-of-week {:arglists (quote (["java.time.LocalDate"]))} (^js/JSJoda.DayOfWeek [^js/JSJoda.LocalDate this13181] (.dayOfWeek this13181)))
(clojure.core/defn to-string {:arglists (quote (["java.time.LocalDate"]))} (^java.lang.String [^js/JSJoda.LocalDate this13182] (.toString this13182)))
(clojure.core/defn plus-months {:arglists (quote (["java.time.LocalDate" "long"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13183 ^long long13184] (.plusMonths this13183 long13184)))
(clojure.core/defn is-before {:arglists (quote (["java.time.LocalDate" "java.time.chrono.ChronoLocalDate"]))} (^boolean [^js/JSJoda.LocalDate this13185 ^js/JSJoda.ChronoLocalDate java-time-chrono-ChronoLocalDate13186] (.isBefore this13185 java-time-chrono-ChronoLocalDate13186)))
(clojure.core/defn minus-months {:arglists (quote (["java.time.LocalDate" "long"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13187 ^long long13188] (.minusMonths this13187 long13188)))
(clojure.core/defn minus {:arglists (quote (["java.time.LocalDate" "long" "java.time.temporal.TemporalUnit"] ["java.time.LocalDate" "java.time.temporal.TemporalAmount"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13189 ^long long13190 ^js/JSJoda.TemporalUnit java-time-temporal-TemporalUnit13191] (.minus this13189 long13190 java-time-temporal-TemporalUnit13191)) (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13192 ^js/JSJoda.TemporalAmount java-time-temporal-TemporalAmount13193] (.minus this13192 java-time-temporal-TemporalAmount13193)))
(clojure.core/defn plus-days {:arglists (quote (["java.time.LocalDate" "long"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13194 ^long long13195] (.plusDays this13194 long13195)))
(clojure.core/defn get-long {:arglists (quote (["java.time.LocalDate" "java.time.temporal.TemporalField"]))} (^long [^js/JSJoda.LocalDate this13196 ^js/JSJoda.TemporalField java-time-temporal-TemporalField13197] (.getLong this13196 java-time-temporal-TemporalField13197)))
(clojure.core/defn with-year {:arglists (quote (["java.time.LocalDate" "int"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13198 ^int int13199] (.withYear this13198 int13199)))
(clojure.core/defn length-of-month {:arglists (quote (["java.time.LocalDate"]))} (^int [^js/JSJoda.LocalDate this13200] (.lengthOfMonth this13200)))
(clojure.core/defn until {:arglists (quote (["java.time.LocalDate" "java.time.chrono.ChronoLocalDate"] ["java.time.LocalDate" "java.time.temporal.Temporal" "java.time.temporal.TemporalUnit"]))} (^js/JSJoda.Period [^js/JSJoda.LocalDate this13201 ^js/JSJoda.ChronoLocalDate java-time-chrono-ChronoLocalDate13202] (.until this13201 java-time-chrono-ChronoLocalDate13202)) (^long [^js/JSJoda.LocalDate this13203 ^js/JSJoda.Temporal java-time-temporal-Temporal13204 ^js/JSJoda.TemporalUnit java-time-temporal-TemporalUnit13205] (.until this13203 java-time-temporal-Temporal13204 java-time-temporal-TemporalUnit13205)))
(clojure.core/defn of-epoch-day {:arglists (quote (["long"]))} (^js/JSJoda.LocalDate [^long long13206] (js-invoke java.time.LocalDate "ofEpochDay" long13206)))
(clojure.core/defn with-day-of-month {:arglists (quote (["java.time.LocalDate" "int"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13207 ^int int13208] (.withDayOfMonth this13207 int13208)))
(clojure.core/defn get-day-of-month {:arglists (quote (["java.time.LocalDate"]))} (^int [^js/JSJoda.LocalDate this13209] (.dayOfMonth this13209)))
(clojure.core/defn from {:arglists (quote (["java.time.temporal.TemporalAccessor"]))} (^js/JSJoda.LocalDate [^js/JSJoda.TemporalAccessor java-time-temporal-TemporalAccessor13210] (js-invoke java.time.LocalDate "from" java-time-temporal-TemporalAccessor13210)))
(clojure.core/defn is-after {:arglists (quote (["java.time.LocalDate" "java.time.chrono.ChronoLocalDate"]))} (^boolean [^js/JSJoda.LocalDate this13211 ^js/JSJoda.ChronoLocalDate java-time-chrono-ChronoLocalDate13212] (.isAfter this13211 java-time-chrono-ChronoLocalDate13212)))
(clojure.core/defn is-supported {:arglists (quote (["java.time.LocalDate" "java.time.temporal.TemporalField"] ["java.time.LocalDate" "java.time.temporal.TemporalUnit"]))} (^boolean [this13213 G__13214] (.isSupported ^js/JSJoda.LocalDate this13213 G__13214)))
(clojure.core/defn minus-years {:arglists (quote (["java.time.LocalDate" "long"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13215 ^long long13216] (.minusYears this13215 long13216)))
(clojure.core/defn get-chronology {:arglists (quote (["java.time.LocalDate"]))} (^js/JSJoda.IsoChronology [^js/JSJoda.LocalDate this13217] (.chronology this13217)))
(clojure.core/defn parse {:arglists (quote (["java.lang.CharSequence" "java.time.format.DateTimeFormatter"] ["java.lang.CharSequence"]))} (^js/JSJoda.LocalDate [^java.lang.CharSequence java-lang-CharSequence13218 ^js/JSJoda.DateTimeFormatter java-time-format-DateTimeFormatter13219] (js-invoke java.time.LocalDate "parse" java-lang-CharSequence13218 java-time-format-DateTimeFormatter13219)) (^js/JSJoda.LocalDate [^java.lang.CharSequence java-lang-CharSequence13220] (js-invoke java.time.LocalDate "parse" java-lang-CharSequence13220)))
(clojure.core/defn hash-code {:arglists (quote (["java.time.LocalDate"]))} (^int [^js/JSJoda.LocalDate this13221] (.hashCode this13221)))
(clojure.core/defn adjust-into {:arglists (quote (["java.time.LocalDate" "java.time.temporal.Temporal"]))} (^js/JSJoda.Temporal [^js/JSJoda.LocalDate this13222 ^js/JSJoda.Temporal java-time-temporal-Temporal13223] (.adjustInto this13222 java-time-temporal-Temporal13223)))
(clojure.core/defn with {:arglists (quote (["java.time.LocalDate" "java.time.temporal.TemporalField" "long"] ["java.time.LocalDate" "java.time.temporal.TemporalAdjuster"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13224 ^js/JSJoda.TemporalField java-time-temporal-TemporalField13225 ^long long13226] (.with this13224 java-time-temporal-TemporalField13225 long13226)) (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13227 ^js/JSJoda.TemporalAdjuster java-time-temporal-TemporalAdjuster13228] (.with this13227 java-time-temporal-TemporalAdjuster13228)))
(clojure.core/defn now {:arglists (quote ([] ["java.time.ZoneId"] ["java.time.Clock"]))} (^js/JSJoda.LocalDate [] (js-invoke java.time.LocalDate "now")) (^js/JSJoda.LocalDate [G__13230] (js-invoke java.time.LocalDate "now" G__13230)))
(clojure.core/defn at-start-of-day {:arglists (quote (["java.time.LocalDate" "java.time.ZoneId"] ["java.time.LocalDate"]))} (^js/JSJoda.ZonedDateTime [^js/JSJoda.LocalDate this13231 ^js/JSJoda.ZoneId java-time-ZoneId13232] (.atStartOfDay this13231 java-time-ZoneId13232)) (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDate this13233] (.atStartOfDay this13233)))
(clojure.core/defn get-month-value {:arglists (quote (["java.time.LocalDate"]))} (^int [^js/JSJoda.LocalDate this13234] (.monthValue this13234)))
(clojure.core/defn with-day-of-year {:arglists (quote (["java.time.LocalDate" "int"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13235 ^int int13236] (.withDayOfYear this13235 int13236)))
(clojure.core/defn compare-to {:arglists (quote (["java.time.LocalDate" "java.time.chrono.ChronoLocalDate"]))} (^int [^js/JSJoda.LocalDate this13237 ^js/JSJoda.ChronoLocalDate java-time-chrono-ChronoLocalDate13238] (.compareTo this13237 java-time-chrono-ChronoLocalDate13238)))
(clojure.core/defn get-month {:arglists (quote (["java.time.LocalDate"]))} (^js/JSJoda.Month [^js/JSJoda.LocalDate this13239] (.month this13239)))
(clojure.core/defn of-year-day {:arglists (quote (["int" "int"]))} (^js/JSJoda.LocalDate [^int int13240 ^int int13241] (js-invoke java.time.LocalDate "ofYearDay" int13240 int13241)))
(clojure.core/defn get {:arglists (quote (["java.time.LocalDate" "java.time.temporal.TemporalField"]))} (^int [^js/JSJoda.LocalDate this13242 ^js/JSJoda.TemporalField java-time-temporal-TemporalField13243] (.get this13242 java-time-temporal-TemporalField13243)))
(clojure.core/defn equals {:arglists (quote (["java.time.LocalDate" "java.lang.Object"]))} (^boolean [^js/JSJoda.LocalDate this13244 ^java.lang.Object java-lang-Object13245] (.equals this13244 java-lang-Object13245)))
(clojure.core/defn at-time {:arglists (quote (["java.time.LocalDate" "java.time.LocalTime"] ["java.time.LocalDate" "int" "int" "int"] ["java.time.LocalDate" "int" "int" "int" "int"] ["java.time.LocalDate" "java.time.OffsetTime"] ["java.time.LocalDate" "int" "int"]))} (^java.lang.Object [this13246 G__13247] (.atTime ^js/JSJoda.LocalDate this13246 G__13247)) (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDate this13248 ^int int13249 ^int int13250 ^int int13251] (.atTime this13248 int13249 int13250 int13251)) (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDate this13252 ^int int13253 ^int int13254 ^int int13255 ^int int13256] (.atTime this13252 int13253 int13254 int13255 int13256)) (^js/JSJoda.LocalDateTime [^js/JSJoda.LocalDate this13257 ^int int13258 ^int int13259] (.atTime this13257 int13258 int13259)))
(clojure.core/defn format {:arglists (quote (["java.time.LocalDate" "java.time.format.DateTimeFormatter"]))} (^java.lang.String [^js/JSJoda.LocalDate this13260 ^js/JSJoda.DateTimeFormatter java-time-format-DateTimeFormatter13261] (.format this13260 java-time-format-DateTimeFormatter13261)))
(clojure.core/defn plus-years {:arglists (quote (["java.time.LocalDate" "long"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13262 ^long long13263] (.plusYears this13262 long13263)))
(clojure.core/defn minus-days {:arglists (quote (["java.time.LocalDate" "long"]))} (^js/JSJoda.LocalDate [^js/JSJoda.LocalDate this13264 ^long long13265] (.minusDays this13264 long13265)))
