(ns io.klei.lms.frontend.shared.ui
  (:require
    [antd]
    ["@rjsf/antd" :as fantd]
    ["@rjsf/validator-ajv8$default" :as validator]
    ["antd-img-crop$default" :as antd-img-crop]
    ["intl-tel-input" :as intl-tel-input]
    [goog.object :as gobj]
    [clojure.string :as string]
    [reagent.core :as r]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.utils :as utils]))

(def default-form-props
  {:validator validator
   :uiSchema {"ui:submitButtonOptions" {"props"      {"type" "primary"}
                                        "submitText" "Save"}}})

(defn auto-form [props]
  (let [new-props (utils/deep-merge default-form-props props)]
    [:> fantd/Form new-props]))

;;------------------------------------------------------------
;; ANTD Components

;; Typography
(def typography (r/adapt-react-class antd/Typography))
(def text (r/adapt-react-class antd/Typography.Text))
(def title (r/adapt-react-class antd/Typography.Title))
(def paragraph (r/adapt-react-class antd/Typography.Paragraph))
(defn drawer []
  (let [this (r/current-component)
        props (r/props this)]
    (into [:> antd/Drawer
           (r/merge-props {:width 640}
                          props)]
          (r/children this))))


;; MENU
(def menu (r/adapt-react-class antd/Menu))
(def menu-item (r/adapt-react-class antd/Menu.Item))
(def sub-menu (r/adapt-react-class antd/Menu.SubMenu))
(def tabs (r/adapt-react-class antd/Tabs))

;; LAYOUT
(def layout (r/adapt-react-class antd/Layout))
(def header (r/adapt-react-class antd/Layout.Header))
(def sider (r/adapt-react-class antd/Layout.Sider))
(def content (r/adapt-react-class antd/Layout.Content))
(def footer (r/adapt-react-class antd/Layout.Footer))

;; GRID
(def row (r/adapt-react-class antd/Row))
(def col (r/adapt-react-class antd/Col))

;; FORMS
(defn form []
  (let [this (r/current-component)
        props (r/props this)]
    (into [:> antd/Form
           (r/merge-props {:layout "horizontal"
                           :requiredMark true
                           :autoComplete "off"
                           :labelCol {:span 5}
                           :wrapperCol {:span 19}
                           :colon false}
                          props)]
          (r/children this))))

(def form-item (r/adapt-react-class antd/Form.Item))
(def form-list (r/adapt-react-class antd/Form.List))
(def input (r/adapt-react-class antd/Input))
(def input-password (r/adapt-react-class antd/Input.Password))
(def input-search (r/adapt-react-class antd/Input.Search))
(def textarea (r/adapt-react-class antd/Input.TextArea))

(def select (r/adapt-react-class antd/Select))
(def select-option (r/adapt-react-class antd/Select.Option))
(defn select-with-options [props]
  (into [select (dissoc props :options)]
        (mapv (fn [o]
                [select-option {:key (:value o)
                                :value (:value o)} (:text o)])
              (:options props))))

(def dropdown (r/adapt-react-class antd/Dropdown))


(def radio-group (r/adapt-react-class antd/Radio.Group))
(def radio (r/adapt-react-class antd/Radio))
(def radio-button (r/adapt-react-class antd/Radio.Button))

(def checkbox (r/adapt-react-class antd/Checkbox))
(def checkbox-group (r/adapt-react-class antd/Checkbox.Group))

(def datepicker (r/adapt-react-class antd/DatePicker))
(def timepicker (r/adapt-react-class antd/TimePicker))
(def range-picker (r/adapt-react-class antd/DatePicker.RangePicker))

;; TABLE
(def table (r/adapt-react-class antd/Table))

;;(def descriptions (r/adapt-react-class antd/Descriptions))
;;(def descriptions-item (r/adapt-react-class antd/Descriptions.Item))

(def button (r/adapt-react-class antd/Button))

(def popconfirm (r/adapt-react-class antd/Popconfirm))



(def anchor (r/adapt-react-class antd/Anchor))

(def alert (r/adapt-react-class antd/Alert))

(def divider (r/adapt-react-class antd/Divider))
(def space (r/adapt-react-class antd/Space))

(def alist (r/adapt-react-class antd/List))
(def alist-item (r/adapt-react-class antd/List.Item))

;; CARD
(def card (r/adapt-react-class antd/Card))
(def card-meta (r/adapt-react-class antd/Card.Meta))

(def tooltip (r/adapt-react-class antd/Tooltip))

(def progress (r/adapt-react-class antd/Progress))

(def modal (r/adapt-react-class antd/Modal))

(def image (r/adapt-react-class antd/Image))
(defn avatar [{:keys [src size]
               :or {size "24px"}}]
  [:div.avatar
   [:> antd/Avatar
    {:src (r/as-element [image {:src src
                                :fallback (utils/avatar-fallback-url)
                                :preview false}])
     :size size}]])

(def upload-list-ignore antd/Upload.LIST_IGNORE)
(def upload (r/adapt-react-class antd/Upload))
(def img-crop (r/adapt-react-class antd-img-crop))

(def spin (r/adapt-react-class antd/Spin))

(def tag (r/adapt-react-class antd/Tag))

(defn loading []
  [icon/loading {:spin true}])

(defn section-heading [text]
  [title {:className "section-heading"
          :level 4} text])

;; https://github.com/jackocnr/intl-tel-input
(defn tel-input [{:keys [on-change]}]
 (let [^js inputRef (atom nil)
       ^js iti (atom nil)
       input-val (r/atom nil)]
   (r/create-class
     {:display-name "tel-input"
      :reagent-render
      (fn [{:keys [on-change]}]
        [input {:ref #(reset! inputRef %)
                :onChange (fn [o]
                            (reset! input-val (.getNumber ^js @iti))
                            (on-change {:value (.getNumber ^js @iti)
                                        :is-valid? (.isValidNumber ^js @iti)
                                        :country-data (.getSelectedCountryData ^js @iti)}))}])
      :component-did-mount
      (fn [this]
        (let [props (r/props this)
              on-change (:on-change props)]
          (reset! iti (intl-tel-input
                        (.-input ^js @inputRef)
                        (clj->js {:preferredCountries ["ae" "ph"]
                                  :utilsScript "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"})))
          (.addEventListener (.-input ^js @inputRef) "countrychange"
                             (fn []
                               (on-change (.getNumber ^js @iti))))))})))
