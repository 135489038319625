(ns io.klei.lms.frontend.feature.student.new-student
  (:require
    [clojure.string :as str]
    [goog.object :as g.object]
    [io.klei.lms.frontend.form.core :as f]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [io.klei.lms.frontend.shared.utils :as utils]
    [malli.core :as m]
    [re-frame.core :as rf]))

(defn new-student-form [{:keys [org-id school-id]}]
  (let [registry @(rf/subscribe [:entity.schema.sub/registry])]
    (when registry
      [f/form {:schema (m/schema [:map
                                  :user/username
                                  :user/password]
                                 {:registry registry})
               :on-submit (fn [_ctx form values]
                            (rf/dispatch [::new-student form (assoc
                                                               values
                                                               :organization/id org-id
                                                               :school/id school-id)]))
               :ui-schema {:user/password {"ui:options" {"inputType" "password"}}}}])))

(rf/reg-event-fx
  ::new-student
  (fn [_ [_ ^js form value]]
    {:dispatch [::pathom/mutation {:op-name    'io.klei.lms.pathom.mutations/new-student
                                   :params     value
                                   :on-success [::new-student-success form value]
                                   :on-failure [::new-student-failure form value]}]}))

(rf/reg-event-fx
  ::new-student-success
  (fn [{:keys [db]} [_ ^js form value response]]
    {:db (utils/add db response)
     :fx [[:dispatch [:toast-notification {:type :success
                                           :message "Student created."}]]
          [:form/reset form]]}))

(rf/reg-event-fx
  ::new-student-failure
  (fn [{:keys [db]} [_ ^js form ^js ctx message]]
    {:dispatch [:toast-notification {:type :error
                                     :message message}]}))


