(ns io.klei.lms.frontend.feature.drawer-form
  (:require
    [pyramid.core :as p]
    [goog.string :as gstring]
    [goog.string.format]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.object :as gobject]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn drawer [props & content]
  (let [open? @(rf/subscribe [:f.drawer.sub/open? (:id props)])]
    (into [ui/drawer (merge props
                            {:open open?
                             :on-close #(rf/dispatch [:f.drawer.event/close (:id props)])})]
          content)))

(rf/reg-event-fx
  :f.drawer.event/open
  (fn [{:keys [db]} [_ drawer-id dispatch-event]]
    (cond-> {:db (assoc-in db [:f.drawer.db/open? drawer-id] true)}
            dispatch-event (assoc :dispatch (conj dispatch-event drawer-id)))))

(rf/reg-event-db
  :f.drawer.event/close
  (fn [db [_ id]]
    (update db :f.drawer.db/open? dissoc id)))

(rf/reg-sub
  :f.drawer.sub/open?
  (fn [db [_ id]]
    (get-in db [:f.drawer.db/open? id] false)))
