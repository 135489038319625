(ns io.klei.lms.frontend.page.school
  (:require
    [pyramid.core :as p]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.entity.school :as e.school]
    [io.klei.lms.frontend.feature.drawer-form :as drawer]
    [io.klei.lms.frontend.shared.re-frame.form-tx :as form-tx]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.route-utils :as r.utils]
    [io.klei.lms.frontend.shared.ui :as ui]))

(defn school-content [{:keys [match]}]
  (let [*state (r/atom {:school-id-to-edit nil})]
    (fn []
      [:<>
       [ui/row
        [ui/col {:span 24}
         [ui/button
          {:icon (r/as-element [icon/plus])
           :on-click #(rf/dispatch [:f.drawer.event/open :add-school])
           :style {:float "right"}}
          "Add School"]]
        [drawer/drawer
         {:title "Add School"
          :id :add-school}
         [e.school/form
          {:on-submit (fn [form values]
                        (rf/dispatch [:e.school.event/add
                                      form
                                      (assoc (js->clj values) :org-id (r.utils/org-id match))]))
           :actions (r/as-element [form-tx/button-form-tx {:tx-id :e.school.event/add}])}]]]

       (let [schools @(rf/subscribe [:e.school.sub/list])
             school-to-edit @(rf/subscribe [:e.school.sub/one-by-id (:school-id-to-edit @*state)])]
         [:<>
          [ui/row {:style {:margin-top "16px"}}
           [ui/col {:span 24}
            [ui/row {:gutter [32 32]}
             (doall
               (for [school schools]
                 ^{:key (:school/id school)}
                 [ui/col {:span 6}
                  [e.school/school-card
                   {:title (:school/name school)
                    :size "size"
                    :extra (r/as-element [ui/button {:type "text"
                                                     :icon (r/as-element [icon/edit])
                                                     :on-click (fn []
                                                                 (rf/dispatch [:f.drawer.event/open :edit-school])
                                                                 (swap! *state assoc :school-id-to-edit (:school/id school)))}])}]]))]]]
          [drawer/drawer
           {:title "Edit School"
            :id :edit-school}
           ^{:key (:school/id school-to-edit)}
           [e.school/form
            {:initial-values {:code (:school/code school-to-edit)
                              :name (:school/name school-to-edit)}
             :on-submit (fn [form values]
                          (rf/dispatch [:e.school.event/edit
                                        form
                                        (:school/id school-to-edit)
                                        (assoc (js->clj values) :org-id (-> school-to-edit :school/organization :organization/id))]))
             :actions (r/as-element [form-tx/button-form-tx {:tx-id :e.school.event/edit}])}]]])])))
