(ns io.klei.lms.frontend.page.school-admin.section
  (:require
    [clojure.string :as string]
    [pyramid.core :as p]
    [goog.object :as gobject]
    [goog.string :as gstring]
    ["moment" :as moment]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.entity.section :as entities.section]
    [io.klei.lms.frontend.page.layout :as layout]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.entity.teacher :as entities.teacher]
    [clojure.set :as set]))

;;------------------------------------------------------------
;; UI
(defn section-table []
  (let [sections (rf/subscribe [:entity.section.sub/academic-year-sections])]
    [ui/table
     {:className "kl-table kl-table--drawer"
      :columns [{:title "Name"
                 :key "name"
                 :dataIndex "name"}
                {:title "Level"
                 :key "level"
                 :dataIndex ["level" "name"]}
                {:title "Course"
                 :key "course"
                 :dataIndex ["course" "name"]}
                {:title "Teachers"
                 :key "teachers"
                 :dataIndex ["teachers"]
                 :render (fn [^js record]
                           (r/as-element (into [ui/space]
                                               (for [teacher record]
                                                 [ui/tag
                                                  (gobject/get teacher "name")]))))}]
      :dataSource (->> @sections
                       (mapv (fn [section]
                               {:key (:section/id section)
                                :id (:section/id section)
                                :name (:section/name section)
                                :level {:id (-> section :section/level :level/id)
                                        :name (-> section :section/level :level/name)}
                                :course {:id (-> section :section/course :course/id)
                                         :name (-> section :section/course :course/name)}
                                :teachers (->> (:section/teachers section)
                                               (sort-by :teacher/first-name)
                                               (sort-by :teacher/last-name)
                                               (mapv (fn [teacher]
                                                       {:id (:teacher/id teacher)
                                                        :name (entities.teacher/last-first-name teacher)})))})))
      :onRow (fn [^js section index]
               #js {:onClick (fn [^js e]
                               (rf/dispatch [:feat.section-edit.event/open (gobject/get section "id")]))})}]))

(defn section-edit-form []
  (let [section-id @(rf/subscribe [:feat.section-edit.sub/selected-section-id])
        section @(rf/subscribe [:entity.section.sub/one-by-id section-id])
        submitting? @(rf/subscribe [:feat.section-edit.sub/submitting?])
        ^js form @(rf/subscribe [:feat.section-edit.sub/form])
        teacher-options @(rf/subscribe [:entity.teacher.sub/select-options])
        course-options @(rf/subscribe [:entity.course.sub/select-options])
        level-options @(rf/subscribe [:entity.level.sub/select-options])
        teacher-ids (->> section
                         (:section/teachers section)
                         (sort-by :teacher/first-name)
                         (sort-by :teacher/last-name)
                         (mapv :teacher/id))]
    [ui/form
     {:ref #(rf/dispatch [:feat.section-edit.event/set-form %])
      :labelCol {:span 5}
      :wrapperCol {:span 19}
      :initialValues (clj->js {:name (:section/name section)
                               :level-id (-> section :section/level :level/id)
                               :course-id (-> section :section/course :course/id)
                               :teacher-ids teacher-ids})
      :onFinish (fn [values]
                  (rf/dispatch [:feat.section-edit.event/edit-section section-id values]))}
     [ui/form-item
      {:label "Name"
       :name "name"
       :rules [{:required true}]}
      [ui/input]]
     [ui/form-item
      {:label "Level"
       :name "level-id"
       :rules [{:required true}]}
      [ui/select-with-options {:options level-options
                               :defaultValue (-> section :section/level :level/id)
                               :filterOption utils/default-select-filter-option-fn
                               :onChange (fn [val]
                                           (.setFieldsValue form #js {:level-id val}))}]]
     [ui/form-item
      {:label "Course"
       :name "course-id"
       :rules [{:required true}]}
      [ui/select-with-options {:options course-options
                               :defaultValue (-> section :section/course :course/id)
                               :filterOption utils/default-select-filter-option-fn
                               :onChange (fn [val]
                                           (.setFieldsValue form #js {:course-id val}))}]]
     [ui/form-item
      {:label "Assign Teachers"
       :name "teacher-ids"
       :extra "Teacher will gain access to this section."}
      [ui/select-with-options {:mode "multiple"
                               :options teacher-options
                               :defaultValue teacher-ids
                               :filterOption utils/name-select-filter-option-fn
                               :onChange (fn [values]
                                           (.setFieldsValue form #js {:teacher-ids values}))}]]
     [ui/form-item {:wrapperCol {:span 24}}
      [ui/button {:type "primary"
                  :htmlType "submit"
                  :loading submitting?
                  :style {:margin-left "auto"
                          :display "block"}}
       "Save"]]]))

(defn week-schedule [{:keys [grouped-schedules]}]
  [:div.week-schedule
   (doall
     (for [group-by-day grouped-schedules]
       (do
         ^{:key (hash group-by-day)}
         [:div.week-schedule__row
          [:div.week-schedule__day (utils/day-name (key group-by-day))]
          (if-let [scheds (seq (val group-by-day))]
            (into [:div.week-schedule__schedule-list]
                  (for [s scheds]
                    [:div.week-schedule__schedule
                     [ui/space {:size 24}
                      [:span (str (:section-schedule/start-time s) " - " (:section-schedule/end-time s))]
                      [:span (-> s :section-schedule/subject :subject/name)]
                      [ui/button
                       {:type "link"
                        :danger true
                        :icon (r/as-element [icon/minus-circle])
                        :onClick #(rf/dispatch [:feat.section-edit.event/delete-schedule (:section-schedule/id s)])}]]]))
            "--")])))])

(defn add-schedule-form [{:keys [schedule section]}]
  (let [selected-days (get schedule :days)
        selected-subject-id (get schedule :subject-id)
        submitting? @(rf/subscribe [:feat.section-edit.sub/submitting?])]
    [:div
     [ui/form
      {:layout "vertical"}
      [ui/form-item
       {:label "Select subject to schedule"
        :name "subject-id"
        :rules [{:required true}]
        :validateStatus (get-in schedule [:errors :subject-id :validate-status])
        :help (get-in schedule [:errors :subject-id :help])}
       [ui/select-with-options
        {:placeholder "Select Subject"
         :options (->> section
                       :section/course
                       :course/subjects
                       (mapv (fn [subject]
                               {:text (:subject/name subject)
                                :value (:subject/id subject)})))
         :filterOption utils/default-select-filter-option-fn
         :value selected-subject-id
         :onChange #(rf/dispatch [:feat.section-edit.event/set-subject-id %])}]]
      [ui/checkbox-group
       {:options (->> utils/days-of-the-week
                      (mapv (fn [day]
                              {:label (string/capitalize day)
                               :value day})))
        :value (clj->js selected-days)
        :onChange (fn [days]
                    (rf/dispatch [:feat.section-edit.event/set-days (vec days)]))}]]
     [:div
      [:br]
      (when (seq selected-days)
        [:p "Select time"])
      (into [:div.kl-section-scheduler__day-entry-list]
            (for [day selected-days]
              [:div.kl-section-scheduler__day-entry-list-item
               [:strong (string/capitalize day)]
               [ui/form-item
                {:validateStatus (get-in schedule [:errors day :start-time :validate-status])
                 :help (get-in schedule [:errors day :start-time :help])}
                [ui/timepicker
                 {:placeholder "Start Time"
                  :popupClassName "kl-section-scheduler__timepicker"
                  :format "HH:mm"
                  :showNow false
                  :minuteStep 15
                  :value (some-> (get-in schedule [day :start-time])
                                 (moment "HH:mm"))
                  :onSelect #(rf/dispatch [:feat.section-edit.event/set-time day :start-time (utils/format-time %)])}]]
               [ui/form-item
                {:validateStatus (get-in schedule [:errors day :end-time :validate-status])
                 :help (get-in schedule [:errors day :end-time :help])}
                [ui/timepicker
                 {:placeholder "End Time"
                  :popupClassName "kl-section-scheduler__timepicker"
                  :format "HH:mm"
                  :showNow false
                  :minuteStep 15
                  :value (some-> (get-in schedule [day :end-time])
                                 (moment "HH:mm"))
                  :onSelect (fn [val]
                              (rf/dispatch [:feat.section-edit.event/set-time day :end-time (utils/format-time val)]))}]]]))
      [ui/space
       {:style {:float "right"}}
       [ui/button
        {:type "link"
         :style {:float "right"}
         :onClick #(rf/dispatch [:feat.section-edit.event/cancel-schedule])}
        "Cancel"]
       (when (seq selected-days)
         [ui/button
          {:type "primary"
           :loading submitting?
           :onClick #(rf/dispatch [:feat.section-edit.event/save-schedule])}
          "Save Schedule"])]]]))

(defn section-add-form []
  (let [submitting? @(rf/subscribe [:feat.section-add.sub/submitting?])
        ^js form @(rf/subscribe [:feat.section-add.sub/form])
        teacher-options @(rf/subscribe [:entity.teacher.sub/select-options])
        course-options @(rf/subscribe [:entity.course.sub/select-options])
        level-options @(rf/subscribe [:entity.level.sub/select-options])
        ay-options @(rf/subscribe [:entity.academic-year.sub/select-options])
        latest-ay @(rf/subscribe [:entity.academic-year.sub/latest])]
    [ui/form
     {:ref #(rf/dispatch [:feat.section-add.event/set-form %])
      :labelCol {:span 5}
      :wrapperCol {:span 19}
      :initialValues (clj->js {:academic-year-id (:academic-year/id latest-ay)})
      :onFinish (fn [values]
                  (rf/dispatch [:feat.section-add.event/add-section form values]))}
     [ui/form-item
      {:label "Name"
       :name "name"
       :rules [{:required true}]}
      [ui/input]]
     [ui/form-item
      {:label "Academic Year"
       :name "academic-year-id"
       :rules [{:required true}]}
      [ui/select-with-options {:options ay-options
                               :defaultValue (:academic-year/id latest-ay)
                               :filterOption utils/default-select-filter-option-fn
                               :onChange (fn [val]
                                           (.setFieldsValue form #js {:academic-year-id val}))}]]
     [ui/form-item
      {:label "Level"
       :name "level-id"
       :rules [{:required true}]}
      [ui/select-with-options {:options level-options
                               :filterOption utils/default-select-filter-option-fn
                               :onChange (fn [val]
                                           (.setFieldsValue form #js {:level-id val}))}]]
     [ui/form-item
      {:label "Course"
       :name "course-id"
       :rules [{:required true}]}
      [ui/select-with-options {:options course-options
                               :filterOption utils/default-select-filter-option-fn
                               :onChange (fn [val]
                                           (.setFieldsValue form #js {:course-id val}))}]]
     [ui/form-item
      {:label "Assign Teachers"
       :name "teacher-ids"
       :extra "Teacher will gain access to this section."}
      [ui/select-with-options {:mode "multiple"
                               :options teacher-options
                               :filterOption utils/name-select-filter-option-fn
                               :onChange (fn [values]
                                           (.setFieldsValue form #js {:teacher-ids values}))}]]
     [ui/form-item {:wrapperCol {:span 24}}
      [ui/button {:type "primary"
                  :htmlType "submit"
                  :loading submitting?
                  :style {:margin-left "auto"
                          :display "block"}}
       "Save"]]]))

;;------------------------------------------------------------
;; PAGE

(defn section-content []
  (let [match @(rf/subscribe [:router.sub/match])
        section-id @(rf/subscribe [:feat.section-edit.sub/selected-section-id])
        section @(rf/subscribe [:entity.section.sub/one-by-id section-id])
        ay-id (-> match :path-params :ay-id)
        ays @(rf/subscribe [:entity.academic-year.sub/select-options])
        grouped-schedules @(rf/subscribe [:feat.section-edit.sub/section-schedule-table-data section-id])
        open? @(rf/subscribe [:feat.section-add.sub/open?])]
    [:<>
     [ui/row {:style {:margin-bottom "16px"}}
      [ui/col {:span 24}
       [ui/space
        [ui/select {:defaultValue ay-id
                    :options ays
                    :onChange #(rf/dispatch [:router.event/navigate
                                             :page.school-admin/sections
                                             {:path-params (merge (:path-params match)
                                                                  {:ay-id %})}])}]]
       [ui/button
        {:icon (r/as-element [icon/plus])
         :style {:float "right"}
         :on-click #(rf/dispatch [:feat.section-add.event/open])}
        "Add New Section"]
       (when open?
         [ui/drawer
          {:title "Add New Section"
           :open true
           :onClose #(rf/dispatch [:feat.section-add.event/close])}
          [section-add-form]])]]

     [ui/row
      [ui/col {:span 24}
       [section-table]

       (when section
         [ui/drawer
          {:open true
           :title "Edit Section"
           :width 640
           :onClose #(rf/dispatch [:feat.section-edit.event/close])}
          [section-edit-form]
          [:div
           [ui/title {:level 5} "Schedules"]
           [ui/alert
            {:type "info"
             :description "Use UAE timezone for schedules' time slots. Then, Users will see the schedule in their local timezone."}]
           (when grouped-schedules
             [week-schedule {:grouped-schedules grouped-schedules}])
           (let [schedule @(rf/subscribe [:feat.section-edit.sub/schedule])]
             (cond
               schedule [add-schedule-form {:schedule schedule
                                            :section section}]
               (not schedule)
               [ui/button
                {:type "link"
                 :icon (r/as-element [icon/plus])
                 :style {:float "right"}
                 :onClick #(rf/dispatch [:feat.section-edit.event/add-schedule])}
                "Add Schedule"]))]])]]]))



;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-db
  :feat.section-edit.event/open
  (fn [db [_ section-id]]
    (assoc db :feat.section-edit.db/selected-section-id section-id)))

(rf/reg-event-db
  :feat.section-edit.event/close
  (fn [db [_]]
    (assoc db :feat.section-edit.db/selected-section-id nil)))

(rf/reg-event-db
  :feat.section-edit.event/set-form
  (fn [db [_ form]]
    (assoc db :feat.section-edit.db/form form)))

(rf/reg-event-db
  :feat.section-edit.event/add-schedule
  (fn [db _]
    (assoc db :feat.section-edit.db/schedule {})))

(rf/reg-event-db
  :feat.section-edit.event/cancel-schedule
  (fn [db _]
    (-> db
        (assoc :feat.section-edit.db/schedule nil)
        (assoc :feat.section-edit.db/submitting? false))))

(rf/reg-event-db
  :feat.section-edit.event/set-days
  (fn [db [_ days]]
    (-> db
        (assoc-in [:feat.section-edit.db/schedule :days] days)
        (update-in [:feat.section-edit.db/schedule :errors] (fn [errors]
                                                              (select-keys errors (conj days :subject-id)))))))

(rf/reg-event-db
  :feat.section-edit.event/set-subject-id
  (fn [db [_ subject-id]]
    (-> db
        (assoc-in [:feat.section-edit.db/schedule :subject-id] subject-id)
        (assoc-in [:feat.section-edit.db/schedule :errors :subject-id] nil))))

(rf/reg-event-db
  :feat.section-edit.event/set-time
  (fn [db [_ day start-or-end time]]
    (-> db
        (assoc-in [:feat.section-edit.db/schedule day start-or-end] time)
        (assoc-in [:feat.section-edit.db/schedule :errors day start-or-end] nil))))


(defn validate-time [sched]
  (reduce (fn [sched day]
            (cond-> sched
                    (not (get-in sched [day :start-time]))
                    (assoc-in [:errors day :start-time] {:validate-status "error"
                                                         :help "'start-time' required"})

                    (not (get-in sched [day :end-time]))
                    (assoc-in [:errors day :end-time] {:validate-status "error"
                                                       :help "'end-time' required"})))
          sched
          (:days sched)))


(defn validate-subject [sched]
  (cond-> sched
          (not (:subject-id sched))
          (assoc-in [:errors :subject-id] {:validate-status "error"
                                           :help "'subject' is required"})))

(defn schedule->payload [sched section-id]
  (for [day (:days sched)]
    {:section-schedule/day day
     :section-schedule/section-id section-id
     :section-schedule/subject-id (:subject-id sched)
     :section-schedule/start-time (get-in sched [day :start-time])
     :section-schedule/end-time (get-in sched [day :end-time])}))

(rf/reg-event-fx
  :feat.section-edit.event/save-schedule
  (fn [{db :db} [_]]
    (when-let [sched (some-> (get db :feat.section-edit.db/schedule)
                             (assoc :errors nil)
                             (validate-subject)
                             (validate-time))]
      (if (:errors sched)
        {:db (assoc db :feat.section-edit.db/schedule sched)}
        {:db (assoc db :feat.section-edit.db/submitting? true)
         :http-xhrio (utils/http-map db {:method :post
                                         :uri (gstring/format "/section-schedule/section/%s" (get db :feat.section-edit.db/selected-section-id))
                                         :params (schedule->payload sched (get db :feat.section-edit.db/selected-section-id))
                                         :on-success [:feat.section-edit.event/save-schedule-success]})}))))

(rf/reg-event-fx
  :feat.section-edit.event/save-schedule-success
  (fn [{db :db} [_ response]]
    (let [schedules (->> (:section/schedules response)
                         (map (fn [sched]
                                (-> sched
                                    (dissoc :xt/id)
                                    (update :section-schedule/subject #(vec [:subject/id (:section-schedule/subject-id sched)]))
                                    (assoc :section-schedule/section [:section/id (:section/id response)])))))
          section-ident [:section/id (:section/id response)]
          section (-> (p/pull db [{section-ident entities.section/section-pull-pattern}])
                      (get section-ident)
                      (update-in [:section/schedules] into schedules))]
      {:db (-> db
               (p/add section)
               (assoc :feat.section-edit.db/schedule nil)
               (assoc :feat.section-edit.db/submitting? false))
       :dispatch [:toast-notification {:type :success
                                       :message "Schedule has been saved."}]})))

(rf/reg-event-fx
  :feat.section-edit.event/delete-schedule
  (fn [{db :db} [_ section-schedule-id]]
    {:http-xhrio (utils/http-map db {:method :delete
                                     :uri (gstring/format "/section-schedule/%s" section-schedule-id)
                                     :on-success [:feat.section-edit.event/delete-schedule-success section-schedule-id]})}))

(rf/reg-event-fx
  :feat.section-edit.event/delete-schedule-success
  (fn [{db :db} [_ section-schedule-id response]]
    {:db (utils/delete db [:section-schedule/id section-schedule-id])
     :dispatch [:toast-notification {:type :success
                                     :message "Schedule has been removed."}]}))

(rf/reg-event-fx
  :feat.section-edit.event/edit-section
  (fn [{db :db} [_ section-id values]]
    {:db (assoc db :feat.section-edit.db/submitting? true)
     :http-xhrio (utils/http-map db {:method :patch
                                     :uri (gstring/format "/section/%s" section-id)
                                     :params values
                                     :on-success [:feat.section-edit.event/edit-section-success]})}))

(rf/reg-event-fx
  :feat.section-edit.event/edit-section-success
  (fn [{:keys [db]} [_ response]]
    (let [section (-> response
                      (select-keys [:section/id :section/name :section/teacher-ids])
                      (update :section/teacher-ids (fn [ids]
                                                     (map #(utils/pull db [:teacher/id %]) ids)))
                      (set/rename-keys {:section/teacher-ids :section/teachers})
                      (assoc :section/course [:course/id (:section/course-id response)])
                      (assoc :section/level [:level/id (:section/level-id response)]))]
      {:db (-> db
               (assoc :feat.section-edit.db/submitting? false)
               (p/add section))
       :dispatch [:toast-notification {:type :success
                                       :message "Section has been updated."}]})))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :feat.section-edit.sub/selected-section-id
  (fn [db _]
    (get db :feat.section-edit.db/selected-section-id)))

(rf/reg-sub
  :feat.section-edit.sub/form
  (fn [db _]
    (get db :feat.section-edit.db/form)))

(rf/reg-sub
  :feat.section-edit.sub/submitting?
  (fn [db _]
    (get db :feat.section-edit.db/submitting? false)))

(rf/reg-sub
  :feat.section-edit.sub/schedule
  (fn [db _]
    (get db :feat.section-edit.db/schedule)))

(rf/reg-sub
  :feat.section-edit.sub/teacher-options
  (fn [[_ section-id]]
    (rf/subscribe [:entity.section.sub/one-by-id section-id]))
  (fn [section]
    (->> section
         :section/teachers
         (mapv (fn [teacher]
                 {:text (entities.teacher/last-first-name teacher)
                  :value (:teacher/id teacher)}))
         (sort-by :text))))

(rf/reg-sub
  :feat.section-edit.sub/section-schedule-table-data
  (fn [[_ section-id]]
    (rf/subscribe [:entity.section.sub/one-by-id section-id]))
  (fn [section _]
    (some->> (:section/schedules section)
             (utils/plot-schedules-by-day))))


;;------------------------------------------------------------
;; ADD SECTION

(rf/reg-event-db
  :feat.section-add.event/open
  (fn [db _]
    (assoc db :feat.section-add.db/open? true)))

(rf/reg-event-db
  :feat.section-add.event/close
  (fn [db _]
    (assoc db :feat.section-add.db/open? false)))

(rf/reg-sub
  :feat.section-add.sub/open?
  (fn [db _]
    (get db :feat.section-add.db/open? false)))

(rf/reg-event-db
  :feat.section-add.event/set-form
  (fn [db [_ form]]
    (assoc db :feat.section-add.db/form form)))

(rf/reg-sub
  :feat.section-add.sub/form
  (fn [db _]
    (get db :feat.section-add.db/form)))

(rf/reg-event-fx
  :feat.section-add.event/add-section
  (fn [{db :db} [_ form values]]
    {:db (assoc db :feat.section-add.db/submitting? true)
     :http-xhrio (utils/http-map db {:method :post
                                     :uri "/section"
                                     :params values
                                     :on-success [:feat.section-edit.event/add-section-success form]})}))

(rf/reg-event-fx
  :feat.section-edit.event/add-section-success
  (fn [{db :db} [_ form response]]
    (let [section (-> response
                      (utils/normalize-entity-attr-ids :teacher/id {:section/teacher-ids :section/teachers})
                      (utils/normalize-entity-attr-id :course/id {:section/course-id :section/course})
                      (utils/normalize-entity-attr-id :level/id {:section/level-id :section/level})
                      (utils/normalize-entity-attr-id :academic-year/id {:section/academic-year-id :section/academic-year}))]
      {:db (-> db
               (assoc :feat.section-add.db/submitting? false)
               (utils/add section))
       :antd/form-reset form
       :dispatch [:toast-notification {:type :success
                                       :message "Section has been added."}]})))

(rf/reg-sub
  :feat.section-add.sub/submitting?
  (fn [db _]
    (get db :feat.section-add.db/submitting? false)))
