(ns io.klei.lms.frontend.entity.level
  (:require
    [re-frame.core :as rf]))

;; viewer, like school-admin or maybe other roles as well sets this
(rf/reg-sub
  :entity.level.sub/all
  (fn [{db :db} _]
    (get db :entity.level.db/all)))

(rf/reg-sub
  :entity.level.sub/select-options
  :<- [:entity-by-id :level/id]
  (fn [by-id _]
    (->> by-id
         (map second)
         (sort-by :level/order)
         (mapv (fn [level]
                 {:value (:level/id level)
                  :label (:level/name level)
                  :text (:level/name level)})))))
