(ns io.klei.lms.frontend.model
  (:require
    [pyramid.core :as p]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]
    [malli.json-schema :as m.json-schema]
    [malli.core :as m]
    [malli.util :as m.util]
    [malli.experimental.time :as m.time]))

(rf/reg-event-fx
  :model.event/get-model
  (fn [_ _]
    {:dispatch [::pathom/query {:eql [:model/model]
                                :on-success [:model.event/get-model-success]}]}))

(rf/reg-event-fx
  :model.event/get-model-success
  (fn [{:keys [db]} [_ response]]
    {:db (assoc db :model.db/model (:model/model response))}))

(rf/reg-sub
  :model.sub/model
  (fn [db]
    (get db :model.db/model)))
