(ns io.klei.lms.frontend.shared.re-frame.pathom
  (:require
    [ajax.core :as ajax]
    [cljs-bean.core :as bean]
    [cognitect.transit :as transit]
    [cljs-bean.transit :as bean.transit]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.shared.config :as config]))

(def default-options
  {:uri             config/graph-api-endpoint
   :method          :post
   :format          (ajax/transit-request-format)
   :response-format (ajax/transit-response-format)})

(defn- http-map [db options]
  (utils/http-map db (merge default-options options)))

;; See https://github.com/mfikes/cljs-bean/blob/master/doc/transit.md
(defn roundtrip [x]
  (let [w (transit/writer :json
                    {:handlers (bean.transit/writer-handlers)})
        r (transit/reader :json)]
    (transit/read r (transit/write w x))))

(defn- maybe-js->keywordize-keys
  "Convert JS object keywordize map. Ignore if it's not JS object"
  [values]
  (cond
    (bean/bean? values) (roundtrip values)
    (object? values) (js->clj values :keywordize-keys true)
    :else values))

(defn- mutation-error [response op-name]
  (get-in response [op-name :com.wsscode.pathom3.connect.runner/mutation-error]))

(rf/reg-event-fx
  ::mutation
  (fn [{db :db} [_ {:keys [op-name params] :as args}]]
    {:http-xhrio (http-map db {:params     {:pathom/eql [(list op-name (maybe-js->keywordize-keys params))]}
                               :on-success [::mutation-success args]
                               :on-failure [::mutation-failure args]})}))

(rf/reg-event-fx
  ::mutation-success
  (fn [{:keys [db]} [_ {:keys [op-name on-success on-failure] :as args} response]]
    (let [fx (if-let [error (mutation-error response op-name)]
               (if on-failure
                 [[:dispatch (conj on-failure error)]]
                 [[:dispatch [:toast-notification {:type :error
                                                   :message (mutation-error response op-name)}]]])
               [[:dispatch (conj on-success response)]])]
      {:fx fx})))

(rf/reg-event-fx
  ::mutation-failure
  (fn [_ [_ args response]]
    {:dispatch [:toast-notification {:type :error
                                     :message (:status-text response)}]}))

(rf/reg-event-fx
  ::query
  (fn [{db :db} [_ {:keys [entity eql on-success on-failure]}]]
    {:http-xhrio (http-map db {:params     {:pathom/entity entity
                                            :pathom/eql eql}
                               :on-success on-success
                               :on-failure on-failure})}))

