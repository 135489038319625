(ns io.klei.lms.frontend.entity.core
  (:require
    [re-frame.core :as rf]
    ;; entrypoint of all entities
    ;; so that only this is required for loading from other parts
    [io.klei.lms.frontend.entity.current-user]
    [io.klei.lms.frontend.entity.viewer-student]
    [io.klei.lms.frontend.entity.viewer-teacher]
    [io.klei.lms.frontend.entity.viewer-school-admin]
    [io.klei.lms.frontend.entity.material.book]
    [io.klei.lms.frontend.entity.student]
    [io.klei.lms.frontend.entity.teacher]
    [io.klei.lms.frontend.entity.material]
    [io.klei.lms.frontend.entity.academic-year]
    [io.klei.lms.frontend.entity.level]
    [io.klei.lms.frontend.entity.subject]
    [io.klei.lms.frontend.entity.registration]
    [io.klei.lms.frontend.entity.course]
    [io.klei.lms.frontend.entity.school]
    [io.klei.lms.frontend.entity.schema]))

(defn entity-by-id [db k]
  (get db k))

(rf/reg-sub
  :entity-by-id
  (fn [db [_ k]]
    (entity-by-id db k)))
