(ns io.klei.lms.frontend.form.rjsf
  (:require
    [cljs-bean.core :as bean]
    ["@rjsf/antd" :as fantd]
    ["@rjsf/validator-ajv8$default" :as validator]
    [io.klei.lms.frontend.shared.utils :as utils]
    [malli.core :as m]
    [malli.json-schema :as m.json]
    [re-frame.core :as rf]
    [reagent.core :as r]))

(defn -schema [schema {::m.json/keys [transform definitions] :as options}]
  (transform (m/deref schema) options))

(defn wrap-on-submit [form on-submit]
  (fn [^js ctx]
    (on-submit
      ctx
      form
      (utils/js->nskw (.-formData ctx)))))

(defn wrap-on-change [form on-change]
  (fn [^js ctx]
    (on-change
      ctx
      form
      (utils/js->nskw (.-formData ctx)))))

(defn submit-text [ui-schema text]
  (assoc-in ui-schema ["ui:submitButtonOptions" "submitText"] text))

(defn auto-form [{:keys [schema init-val on-submit ui-schema submit-text dependencies]
                  :or {init-val :init-val}}]
  (let [;; Using plain atom does not work, render fn gets called 2x with form_ nil.
        ;; Using ratom works where render fn gets called 3x. Third call form is not nil
        form_ (r/atom nil)
        registry @(rf/subscribe [:entity.schema.sub/registry])
        ;; redefining the m.json/-schema here because the schema :ref are producing
        ;; JSON Schema definitions https://rjsf-team.github.io/react-jsonschema-form/docs/usage/definitions/.
        ;; The created $ref definitions are mismatched with the schema keys
        json-schema     (with-redefs [m.json/-schema -schema]
                          (-> (m.json/transform (m/schema schema {:registry registry}))))
        schema' (cond-> json-schema
                        dependencies
                        (utils/deep-merge dependencies))]
    (fn [{:keys [on-submit on-change ui-schema init-val]}]
      [:> fantd/Form (cond-> {:ref (fn [el] (when el (reset! form_ el)))
                              :schema (utils/nskw->js schema')
                              :formData (utils/nskw->js init-val)
                              :validator validator
                              :onSubmit (wrap-on-submit @form_ on-submit)
                              :uiSchema (utils/deep-merge
                                          {"ui:submitButtonOptions" {"props" {"type" "primary"}
                                                                     "submitText" (or submit-text "Save")}}
                                          (utils/stringify-keys ui-schema utils/nskw->json-key))}
                             on-change
                             (assoc :onChange (wrap-on-change @form_ on-change)))])))
