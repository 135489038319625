(ns io.klei.lms.frontend.feature.teacher-edit
  (:require
    [pyramid.core :as p]
    [clojure.string :as string]
    [goog.string :as gstring]
    [goog.string.format]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.object :as gobject]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn teacher-form [{:keys [initial-values on-submit]}]
  (let [^js form @(rf/subscribe [:feat.teacher-edit.sub/form])
        submitting? @(rf/subscribe [:feat.teacher-edit.sub/submitting?])
        section-options @(rf/subscribe [:entity.section.sub/select-options])]
    [ui/form
     {:ref #(rf/dispatch [:feat.teacher-edit.event/set-form %])
      :labelCol {:span 5}
      :wrapperCol {:span 19}
      :initialValues (clj->js initial-values)
      :onFinish (fn [values]
                  (gobject/set values "teacher-id" (:teacher-id initial-values))
                  (on-submit values))}
     [ui/form-item
      {:label "First Name"
       :name "first-name"
       :rules [{:required true}]}
      [ui/input]]
     [ui/form-item
      {:label "Middle Name"
       :name "middle-name"
       :rules [{:required false}]}
      [ui/input]]
     [ui/form-item
      {:label "Last Name"
       :name "last-name"
       :rules [{:required true}]}
      [ui/input]]
     [ui/form-item
      {:label "Email"
       :name "email"
       :rules [{:required true :type "email"}]}
      [ui/input]]
     [ui/form-item
      {:label "Assign Sections"
       :name "section-ids"
       :extra "Teacher will gain access to the selected sections."}
      [ui/select-with-options {:mode "multiple"
                               :options section-options
                               :defaultValue (:section-ids initial-values)
                               :filterOption utils/default-select-filter-option-fn
                               :onChange (fn [values]
                                           (.setFieldsValue form #js {:section-ids values}))}]]
     [ui/form-item {:wrapperCol {:span 24}}
      [ui/button {:type "primary"
                  :htmlType "submit"
                  :disabled submitting?
                  :style {:margin-left "auto"
                          :display "block"}}
       "Save"]]]))


;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-fx
  :feat.teacher-edit.event/open
  (fn [{db :db} [_ teacher-id]]
    ;; TODO show drawer with teacher form
    {:db (assoc db :feat.teacher-edit.db/selected-teacher-id teacher-id)}))

(rf/reg-event-fx
  :feat.teacher-edit.event/close
  (fn [{db :db} [_]]
    {:db (assoc db :feat.teacher-edit.db/selected-teacher-id nil)}))

(rf/reg-event-db
  :feat.teacher-edit.event/set-form
  (fn [db [_ form]]
    (assoc db :feat.teacher-edit.db/form form)))

(rf/reg-event-fx
  :feat.teacher-edit.event/edit-teacher
  (fn [{db :db} [_ values]]
    {:http-xhrio (utils/http-map db {:method :patch
                                     :uri "/teacher"
                                     :params values
                                     :on-success [:feat.teacher-edit.event/edit-teacher-success]})}))

(rf/reg-event-fx
  :feat.teacher-edit.event/edit-teacher-success
  (fn [{db :db} [_ response]]
    {:db (p/add db response)
     :dispatch [:toast-notification {:type :success
                                     :message "Teacher has been successfully updated."}]}))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :feat.teacher-edit.sub/form
  (fn [db _]
    (get db :feat.teacher-edit.db/form)))

(rf/reg-sub
  :feat.teacher-edit.sub/submitting?
  (fn [db _]
    (get db :feat.teacher-edit.db/submitting?)))

(rf/reg-sub
  :feat.teacher-edit.sub/open?
  (fn [db _]
    (get db :feat.teacher-edit.db/open?)))

(rf/reg-sub
  :feat.teacher-edit.sub/selected-teacher-id
  (fn [db _]
    (get db :feat.teacher-edit.db/selected-teacher-id)))
