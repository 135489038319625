(ns io.klei.lms.frontend.feature.presign-upload
  (:require
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.object :as gobject]
    [ajax.core :as ajax]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn append-fields [^js form-data fields]
  (doseq [[k v] fields]
    (.append form-data (name k) v))
  form-data)

;;------------------------------------------------------------
;; EVENTS

(rf/reg-event-fx
  :feat.presign-upload.event/start-upload-object
  (fn [{db :db} [_ {:keys [upload-object on-progress on-complete]}]]
    {:dispatch [:feat.presign-upload.event/start
                {:upload-id (random-uuid)
                 :file (gobject/get upload-object "file")
                 :params {:upload-config-id :material-thumbnail
                          :original-filename (gobject/getValueByKeys upload-object "file" "name")
                          :content-length (gobject/getValueByKeys upload-object "file" "size")
                          :content-type (gobject/getValueByKeys upload-object "file" "type")}
                 :on-progress on-progress
                 :on-complete on-complete}]}))

(rf/reg-event-fx
  :feat.presign-upload.event/start
  (fn [{db :db} [_ {:keys [params]
                    :as upload-config}]]
    {:http-xhrio (utils/http-map db {:method :post
                                     :uri "/file/upload/start"
                                     :params params
                                     :on-success [:feat.presign-upload.event/upload upload-config]})}))

(rf/reg-event-fx
  :feat.presign-upload.event/upload
  (fn [{db :db} [_ upload-config response]]
    (let [^js form-data (append-fields (js/FormData.)
                                       (dissoc (:fields response) :file-id))
          _ (.append form-data "file" (:file upload-config))]
      {:http-xhrio {:method :post
                    :uri (:url response)
                    :body form-data
                    :response-format (ajax/json-response-format)
                    :progress-handler #(rf/dispatch [:feat.presign-upload.event/on-progress upload-config %])
                    :on-success [:feat.presign-upload.event/complete upload-config (:file-id response)]}})))

(rf/reg-event-fx
  :feat.presign-upload.event/on-progress
  (fn [{db :db} [_ upload-config progress-event]]
    (let [upload-id (:upload-id upload-config)]
      {:db (assoc-in db [:feat.presign-upload.db/upload upload-id] {:upload-config upload-config
                                                                    :progress-event progress-event})})))

(rf/reg-event-fx
  :feat.presign-upload.event/complete
  (fn [{db :db} [_ upload-config file-id _]]
    {:http-xhrio (utils/http-map db {:method :post
                                     :uri "/file/upload/complete"
                                     :params {:file-id file-id}
                                     :on-success [:feat.presign-upload.event/on-complete-success upload-config file-id]})}))

(rf/reg-event-fx
  :feat.presign-upload.event/on-complete-success
  (fn [{db :db} [_ upload-config file-id response]]
    (let [upload-id (:upload-id upload-config)]
      (cond->
        {:db (assoc-in db [:feat.presign-upload.db/upload upload-id :completed-response] response)}
        (:on-complete upload-config)
        (assoc :dispatch (conj (:on-complete upload-config) upload-config file-id response))))))

(rf/reg-sub
  :feat.presign-upload.sub/progress-percent
  (fn [db [_ upload-id]]
    (when-let [evt (get-in db [:feat.presign-upload.db/upload upload-id :progress-event])]
      (utils/progress-event-percent evt))))

(rf/reg-sub
  :feat.presign-upload.sub/upload-file-entity
  (fn [db [_ upload-id]]
    (get-in db [:feat.presign-upload.db/upload upload-id :completed-response])))
