(ns io.klei.lms.frontend.feature.user.filter-user
  (:require
    [pyramid.core :as p]
    [goog.string :as g.string]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.object :as g.object]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn user-filter []
  [:div.kl-user-filter
   [ui/input-search
    {:className "kl-user-filter__search-input"
     :placeholder "Search username"
     :onSearch #(rf/dispatch [:feat.user-filter.event/search %])}]])

(rf/reg-event-db
  :feat.user-filter.event/search
  (fn [db [_ val]]
    (assoc db :feat.user-filter.db/search-text val)))

(rf/reg-sub
  :feat.user-filter.sub/search-text
  (fn [db _]
    (get db :feat.user-filter.db/search-text)))
