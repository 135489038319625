(ns io.klei.lms.frontend.shared.config
  (:require
    [goog.object :as gobject]))

(def config (js->clj ^js js/window.APP_CONFIG :keywordize-keys true))

(def cloudfront-cookies (:cloudfront-cookies config))

(def api-endpoint (:api-endpoint config))
(def graph-api-endpoint (:graph-api-endpoint config))

(def thumbnail-host (:thumbnail-host config))
(def logo-host (:logo-host config))
(def avatar-host (:avatar-host config))
(def user-document-host (:user-document-host config))

(def form-host (:form-host config))

(def upload-config-material-thumbnail (get-in config [:upload-config :material-thumbnail]))
(def upload-config-material (get-in config [:upload-config :material]))

(def max-upload-size-default 50)
