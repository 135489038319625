(ns io.klei.lms.frontend.entity.viewer-student
  (:require
    [re-frame.core :as rf]
    [reagent.core :as r]
    [io.klei.lms.frontend.shared.build :as build]
    [io.klei.lms.frontend.shared.utils :as utils]
    [goog.object :as gobj]))

;;------------------------------------------------------------
;; UTILS

(defn academic-year-id [viewer-student]
  (some-> viewer-student :section :section/academic-year-id))

(defn level-name [viewer-student]
  (some-> viewer-student :section :section/level :level/name))

(defn student-number [viewer-student]
  (get-in viewer-student [:student :student/student-number]))


(rf/reg-event-fx
  :entity.viewer-student/get-viewer-student
  (fn [{db :db} _]
    {:http-xhrio
     (utils/http-map db {:method :get
                         :uri "/viewer/student"
                         :on-success [:entity.viewer-student/get-viewer-student-response]
                         :on-failure []})}))

(rf/reg-event-db
  :entity.viewer-student/get-viewer-student-response
  (fn [db [_ response]]
    (assoc db :entity.viewer-student.db/viewer-student response)))

(rf/reg-sub
  :entity.viewer-student.sub/viewer-student
  (fn [db _]
    (get db :entity.viewer-student.db/viewer-student)))

;; TODO(jaime): remove not used
(rf/reg-sub
  :entity.viewer-student.sub/subjects-table
  :<- [:entity.viewer-student.sub/viewer-student]
  (fn [viewer-student _]
    (let [section-schedules (get viewer-student :section-schedules)
          columns [{:title "Subject"
                    :dataIndex :subject-name
                    :key :subject-name}
                   {:title "Schedule"
                    :dataIndex :schedules
                    :key :schedules
                    :render (fn [scheds record]
                              (r/as-element [:<>
                                             (doall
                                               (for [s scheds]
                                                 [:div {:key (hash s)} (str (gobj/get s "day") " " (gobj/get s "start-time") "-" (gobj/get s "end-time"))]))]))}]
          dataSource (map (fn [sched] {:key (get-in sched [:section-schedule/subject :section-schedule/id])
                                       :subject-name (get-in sched [:section-schedule/subject :subject/name])
                                       :schedules (get-in sched [:section-schedule/schedules])})
                          section-schedules)]
      {:columns columns
       :dataSource dataSource})))

(rf/reg-sub
  :entity.viewer-student.sub/materials
  (fn [db _]
    (get-in db [:entity.viewer-student.db/viewer-student :materials])))

(rf/reg-sub
  :entity.viewer-student.sub/schedules-table-data
  :<- [:entity.viewer-student.sub/viewer-student]
  (fn [viewer-student]
    (let [table-data (->> (:section-schedules viewer-student)
                          (utils/plot-schedules-by-day))]
      table-data)))

