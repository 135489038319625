(ns io.klei.lms.frontend.entity.subject
  (:require
    [pyramid.core :as p]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))


(rf/reg-event-fx
  :entity.subject.event/get-all
  (fn [{:keys [db]} _]
    {:http-xhrio (utils/http-map db {:method :get
                                     :uri "/subject"
                                     :on-success [:entity.subject.event/get-all-success]})}))

(rf/reg-event-db
  :entity.subject.event/get-all-success
  (fn [db [_ response]]
    (utils/add db response)))

;;------------------------------------------------------------
;; SUBS
(def subject-pull-pattern
  [:subject/id
   :subject/name
   {:subject/general-subject [:general-subject/id
                              :general-subject/name]}])

(rf/reg-sub
  :entity.subject.sub/list
  (fn [db]
    (->> (for [id (keys (:subject/id db))]
           (-> (p/pull db [{[:subject/id id]
                            subject-pull-pattern}])
               (get [:subject/id id])))
         (sort-by :subject/name))))

(rf/reg-sub
  :entity.subject.sub/select-options
  :<- [:entity.subject.sub/list]
  (fn [subjects _]
    (->> subjects
         (mapv (fn [subject]
                 {:text (:subject/name subject)
                  :value (:subject/id subject)}))
         (sort-by :text))))

(rf/reg-sub
  :entity.subject.sub/one-by-id
  (fn [db [_ subject-id]]
    (when subject-id
      (utils/pull db [:subject/id subject-id] subject-pull-pattern))))


;;------------------------------------------------------------
;; GENERAL SUBJECT
(rf/reg-event-fx
  :entity.general-subject.event/get-all
  (fn [{:keys [db]} _]
    {:http-xhrio (utils/http-map db {:method :get
                                     :uri "/general-subject"
                                     :on-success [:entity.general-subject.event/get-all-success]})}))

(rf/reg-event-db
  :entity.general-subject.event/get-all-success
  (fn [db [_ response]]
    (utils/add db response)))

(rf/reg-sub
  :entity.general-subject.sub/list
  (fn [db]
    (->> (for [id (keys (:general-subject/id db))]
           (-> (p/pull db [{[:general-subject/id id]
                            [:general-subject/id
                             :general-subject/name]}])
               (get [:general-subject/id id])))
         (sort-by :general-subject/name))))

(rf/reg-sub
  :entity.general-subject.sub/select-options
  :<- [:entity.general-subject.sub/list]
  (fn [general-subjects _]
    (->> general-subjects
         (mapv (fn [general-subject]
                 {:text (:general-subject/name general-subject)
                  :value (:general-subject/id general-subject)}))
         (sort-by :text))))
