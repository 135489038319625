(ns io.klei.lms.frontend.schema.simpl-schema
  (:require
    ["uniforms-bridge-simple-schema-2" :as ss2]
    ["simpl-schema$default" :as SimpleSchema :refer [toJsonSchema]]
    ["simpl-schema" :refer [toJsonSchema]]
    ["uniforms-antd" :as uantd]
    [io.klei.lms.frontend.shared.utils :as utils]))

(SimpleSchema/extendOptions #js ["klei" "uniforms"])


(defn schema-keyword-fn [kw]
  (utils/nskw->str kw))

(def clj->js-options
  [:keyword-fn schema-keyword-fn])

#_(defn make-validator [schema]
  (let [validator (.compile ajv schema)]
    (fn [model]
      (validator model)
      (when (< 0 (some-> validator .-errors .-length))
        #js {:details (.-errors validator)}))))

(def address-schema
  (SimpleSchema.
    (clj->js {:address/house-number {:type js/String
                                     :label "House No."}
              :address/street-name {:type js/String
                                    :label "Street"}
              :address/barangay {:type js/String
                                 :label "Barangay"}
              :address/municipality {:type js/String
                                     :label "Municipality"}
              :address/province {:type js/String
                                 :label "Province"}
              :address/country {:type js/String
                                :label "Country"}
              :address/zipcode {:type js/String
                                :label "Zip Code"}}
             :keyword-fn schema-keyword-fn)))


(def returning-learner-schema
  (SimpleSchema.
    (clj->js {:level {:type "string"
                      :label "Last Grade Level Completed"
                      :uniforms {:options [{:label "Kindergarten 1"
                                            :value "kindergarten1"}
                                           {:label "Kindergarten 2"
                                            :value "kindergarten2"}
                                           {:label "Grade 1"
                                            :value "level1"}
                                           {:label "Grade 2"
                                            :value "level2"}
                                           {:label "Grade 3"
                                            :value "level3"}
                                           {:label "Grade 4"
                                            :value "level4"}
                                           {:label "Grade 5"
                                            :value "level5"}
                                           {:label "Grade 6"
                                            :value "level6"}
                                           {:label "Grade 7"
                                            :value "level7"}
                                           {:label "Grade 8"
                                            :value "level8"}
                                           {:label "Grade 9"
                                            :value "level9"}
                                           {:label "Grade 10"
                                            :value "level10"}
                                           {:label "Grade 11"
                                            :value "level11"}
                                           {:label "Grade 12"
                                            :value "level12"}]}}
              :school-year {:type js/String
                            :label "Last School Year Completed"}
              :school-name {:type js/String
                            :label "Pervious School Attended"}
              :school-id {:type js/String
                          :optional true
                          :label "School ID"}}
             :keyword-fn schema-keyword-fn)))

(def senior-high-learner-schema
  (SimpleSchema.
    (clj->js {:semester {:type js/String
                         :label "Semester"
                         :uniforms {:options [{:label "1st Semester"
                                               :value "sem1"}
                                              {:label "2nd Semester"
                                               :value "sem2"}
                                              {:label "3rd Semester"
                                               :value "sem3"}]}}
              :track {:type js/String
                      :label "Track"}
              :strand {:type js/String
                       :label "Strand"}}
             :keyword-fn schema-keyword-fn)))

(def guardian-schema
  (SimpleSchema.
    (clj->js {:person/last-name {:type js/String
                                 :label "Last Name"}
              :person/first-name {:type js/String
                                  :label "First Name"}
              :person/middle-name {:type js/String
                                   :label "Middle Name"}
              :person/mobile-number {:type js/String
                                      :label "Contact Number"}}
             :keyword-fn schema-keyword-fn)))

(def distance-learning-modalities-schema
  (SimpleSchema.
    (clj->js {:student/preferred-distance-learning-modalities
              {:type js/Array
               :label "Preferred Distance Learning Modality/ies"
               :uniforms {:checkboxes true
                          :options [{:label "Modular (Print)"
                                     :value "modular-print"}
                                    {:label "Modular (Digital)"
                                     :value "modular-digital"}
                                    {:label "Online"
                                     :value "online"}
                                    {:label "Educational Television"
                                     :value "educational-television"}
                                    {:label "Radio-Based Instruction"
                                     :value "radio-based-instruction"}
                                    {:label "Homeschooling"
                                     :value "homeschooling"}
                                    {:label "Blended"
                                     :value "blended"}
                                    {:label "Face To Face"
                                     :value "face-to-face"}]}}
              :student/preferred-distance-learning-modalities.$ {:type js/String}}
             :keyword-fn schema-keyword-fn)))


;;----------------------------
;;----------- BRIDGE

(def returning-learner-schema-bridge
  (ss2/SimpleSchema2Bridge.
    (SimpleSchema.
      (clj->js {:student/returning-last-completed {:type returning-learner-schema
                                                   :label "For Returning Learner / Transfer / Move in Only"}}
               :keyword-fn schema-keyword-fn))))

(def senior-high-learner-schema-bridge
  (ss2/SimpleSchema2Bridge.
    (SimpleSchema.
      (clj->js {:student/senior-high {:type senior-high-learner-schema
                                      :label "For Learner in Senior High School Only"}}
               :keyword-fn schema-keyword-fn))))

(def guardian-schema-bridge
  (ss2/SimpleSchema2Bridge.
    (SimpleSchema.
      (clj->js {:person/father {:type guardian-schema
                                :label "Father's Name"}
                :person/mother {:type guardian-schema
                                :label "Mother's Name"}
                :person/guardian {:type guardian-schema
                                  :optional true
                                  :label "Guardian's Name (optional)"}}
               :keyword-fn schema-keyword-fn))))

(def learner-schema-bridge
  (ss2/SimpleSchema2Bridge.
    (SimpleSchema.
     (clj->js {:person/last-name {:type js/String
                                  :klei {}
                                  :label "Last Name"}
               :person/first-name {:type js/String
                                   :label "First Name"}
               :person/middle-name {:type js/String
                                    :label "Middle Name"}
               :person/extension-name {:type js/String
                                       :optional true
                                       :label "Extension Name"}
               :person/gender {:type js/String
                               :allowedValues ["male" "female"]
                               :label "Gender"
                               :uniforms {:options [{:label "Male"
                                                     :value "male"}
                                                    {:label "Female"
                                                     :value "female"}]}}
               :student/lrn {:type js/String
                             :label "Learner Reference Number (LRN)"
                             :optional true}
               :student/returning? {:type js/Boolean
                                            :label "Returning (Balik-Aral)?"
                                            :defaultValue false}
               :person/date-of-birth {:type js/Date
                                      :label "Date of Birth"
                                      :uniforms {:showTime false}}
               :person/place-of-birth {:type js/String
                                       :label "Place of Birth (Municipality/City)"}
               :person/psa-birth-certificate-number {:type js/String
                                                     :label "PSA Birth Certificate No. (if available)"
                                                     :optional true}
               :person/mother-tongue {:type js/String
                                      :label "Mother Tongue"}
               :person/pwd? {:type js/Boolean
                             :label "PWD?"
                             :defaultValue false}
               :person/disabilities {:type js/Array
                                     :label "If Yes, specify the type of disability"
                                     :optional true}
               :person/disabilities.$ {:type js/String
                                       :optional true
                                       :allowedValues ["Autism Spectrum Disorder"
                                                       "Hearing Impairment"
                                                       "Visual Impairment"
                                                       "Intellectual Disability"
                                                       "Learning Disability"
                                                       "Multiple Disabilities"
                                                       "Physical Disability"
                                                       "Others"]}
               :person/indigenous? {:type js/Boolean
                                    :label "Belonging to any Indigenous People (IP) Community?"
                                    :defaultValue false}
               :person/indigenous-group {:type js/String
                                         :optional true
                                         :label "If Yes, please specify"}
               :person/beneficiary-4p? {:type js/Boolean
                                        :label "Is your family a beneficiary of 4Ps?"
                                        :defaultValue false}
               :person/household-number-4p {:type js/String
                                            :optional true
                                            :label "If Yes, enter the 4Ps Household ID Number"}}
              :keyword-fn schema-keyword-fn))))

(def learner-address-schema-bridge
  (ss2/SimpleSchema2Bridge.
    (SimpleSchema.
     (clj->js {:person/current-address {:type address-schema
                                        :label "Current Address"}
               :person/permanent-address {:type address-schema
                                          :label "Permanent Address"}}
              :keyword-fn schema-keyword-fn))))

(def distance-learning-modalities-schema-bridge
  (ss2/SimpleSchema2Bridge. distance-learning-modalities-schema))

(def user-account-schema-bridge
  (ss2/SimpleSchema2Bridge.
    (SimpleSchema.
      (clj->js {:user/email {:type js/String
                             :label "Email"
                             :uniforms {:type "email"
                                        :disabled true}}
                :user/password {:type js/String
                                :label "Password"
                                :uniforms {:type "password"}}}
               :keyword-fn schema-keyword-fn))))

(defn ->field-order [kws]
  (->> kws
       (map schema-keyword-fn)
       (clj->js)))
