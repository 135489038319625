(ns io.klei.lms.frontend.page.user.index
  (:require
    [goog.object :as g.object]
    [goog.string :as g.string]
    [clojure.string :as string]
    [pyramid.core :as p]
    [pyramid.query :refer [q]]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.entity.user :as e.user]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [io.klei.lms.frontend.entity.user :as e.user]
    [io.klei.lms.frontend.feature.update-unique-value :as f.unique]
    [io.klei.lms.frontend.feature.drawer-form :as drawer]
    [io.klei.lms.frontend.feature.user.filter-user :as f.user-filter]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.page.layout :as layout]
    [io.klei.lms.frontend.shared.route-utils :as r.utils]
    [io.klei.lms.frontend.shared.re-frame.form-tx :as form-tx]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.shared.ui :as ui]))

(defn user-table [{:keys [on-view]}]
  (let [data-source @(rf/subscribe [:feat.user-list.sub/table-data])]
    [ui/table
     {:dataSource data-source
      :columns [{:title "Email"
                 :key "email"
                 :filterSearch true
                 :dataIndex ["user" "email"]}
                {:title "Username"
                 :key "username"
                 :dataIndex ["user" "username"]}
                {:title "Roles"
                 :key "roles"
                 :dataIndex ["user" "roles"]}
                {:render (fn [^js record]
                           (r/as-element
                             [ui/button {:type "link"
                                         :onClick #(on-view record)}
                              "View"]))}]}]))

(defn user-edit-form [{:keys [user]}]
  (let [current-user @(rf/subscribe [:entity.current-user.sub/user])
        password-submitting? @(rf/subscribe [:feat.user-edit-password.sub/submitting?])
        email-submitting? @(rf/subscribe [:feat.user-edit-email.sub/submitting?])
        open? (r/atom false)]
    (fn [{:keys [user]}]
      [ui/row
       [ui/col {:sm 24 :md 16}
        [ui/space {:direction "vertical"
                   :size "middle"
                   :style {:display "flex"}}
         [f.unique/form
          {:entity user
           :key :user/id
           :unique-name "user_username"
           :attribute :user/username}]
         [ui/form {:layout "vertical"
                   :wrapperCol {:span 20}
                   :labelCol {:span 20}
                   :initialValues (clj->js {:email (:user/email user)})
                   :onFinish (fn [values]
                               (rf/dispatch [:feat.user-edit-email.event/change-email (:user/id user) values]))}
          [ui/form-item {:label "Email"
                         :name "email"
                         :rules [{:required true}]}
           [ui/input]]
          [ui/form-item
           [ui/button {:type "primary"
                       :htmlType "submit"
                       :loading email-submitting?}
            "Change Email"]]]

         [ui/form {:labelCol {:span 20}
                   :layout "vertical"
                   :wrapperCol {:span 20}
                   :onFinish (fn [values]
                               (rf/dispatch [:feat.user-edit-password.event/change-password (:user/id user) values]))}
          [ui/form-item {:label "Password"
                         :name "password"
                         :rules [{:required true}]}
           [ui/input]]
          [ui/form-item
           [ui/button {:type "primary"
                       :htmlType "submit"
                       :loading password-submitting?}
            "Change Password"]]]
         (when (and (e.user/has-role? user :student)
                    (e.user/user-isa? current-user :school-admin))
           [:div
            [ui/divider]
            [ui/button {:danger true
                        :onClick #(reset! open? true)}
             "Delete User"]])]
        (when @open?
          [ui/modal
           {:open true
            :title "Delete User?"
            :onCancel #(reset! open? false)
            :okText "Delete"
            :okButtonProps {:danger true}
            :onOk (fn [_]
                    (reset! open? false)
                    (rf/dispatch [:feat.student-delete.event/delete-by-user-id (:user/id user)]))}
           [ui/paragraph "Are you sure you want to delete the user?"]
           [ui/paragraph "This will delete the student's record and registration."]])]])))

(defn index-content [{:keys [match] :as view-props}]
  (let [school-options @(rf/subscribe [:entity.school.sub/select-options])]
    [:<>
     [ui/row
      [ui/col {:span 24}
       [ui/space {:direction "vertical"
                  :size "large"
                  :style {:display "flex"}}
        [ui/row
         [ui/col [f.user-filter/user-filter]]
         [ui/col {:flex 1}
          #_[ui/button
             {:style {:float "right"}
              :icon (r/as-element [icon/plus])
              :on-click #(rf/dispatch [:f.drawer.event/open :user-add [:page.user.event/open-user-add-drawer]])}
             "Add User"]]]
        [user-table {:on-view (fn [^js record]
                                (rf/dispatch [:router.event/navigate :page.user/single {:path-params (merge (r.utils/org+school-params match)
                                                                                                            {:user-id (g.object/getValueByKeys record "user" "id")})
                                                                                        :query-params {:role (e.user/default-role (g.object/getValueByKeys record "user" "roles"))}}]))}]]
       (let [user-id @(rf/subscribe [:feat.user-edit-password.sub/selected-user-id])
             user @(rf/subscribe [:entity.user.sub/one-by-id user-id])]
         (when user
           [ui/drawer
            {:title "Edit User"
             :open true
             :on-close #(rf/dispatch [:feat.user-edit-password.event/close])}
            [user-edit-form {:user user}]]))
       [drawer/drawer
        {:title "Add User"
         :id :user-add}
        [e.user/user-form
         {:on-submit (fn [form values]
                       (rf/dispatch [:entity.user.event/add
                                     form
                                     (-> (js->clj values :keywordize-keys true)
                                         (assoc :organization/id (r.utils/org-id match))
                                         (update-in [:user/roles] (comp set vector)))]))
          :actions (r/as-element [form-tx/button-form-tx {:tx-id :entity.user.event/add}])
          :school-options school-options}]]]]]))

(rf/reg-sub
  :feat.user-list.sub/table-data
  :<- [:entity.user.sub/list]
  :<- [:feat.user-filter.sub/search-text]
  (fn [[list* search-text] _]
    (->> list*
         (utils/search-by :user/username search-text)
         (mapv (fn [user]
                 {:key (-> user :user/id)
                  :user {:id (-> user :user/id)
                         :username (-> user :user/username)
                         :email (-> user :user/email)
                         :roles (-> user :user/roles)}})))))

(rf/reg-event-fx
  :page.user.event/open-user-add-drawer
  (fn [{:keys [db]} [_ drawer-id]]
    {:dispatch [:entity.school.event/get-by-org-id "org-srk"]}))

(rf/reg-event-fx
  :feat.student-delete.event/delete-by-user-id
  (fn [{:keys [db]} [_ user-id]]
    {:dispatch [::pathom/mutation {:op-name 'io.klei.lms.pathom.mutations/student-delete
                                   :params {:user/id user-id}
                                   :on-success [:feat.student-delete.event/delete-by-user-id-success user-id]}]}))

(rf/reg-event-fx
  :feat.student-delete.event/delete-by-user-id-success
  (fn [{:keys [db]} [_ user-id]]
    (let [student-ident (ffirst (q [:find '?s
                                    :where
                                    ['?s :auth/user '?u]
                                    ['?u :user/id user-id]]
                                   db))
          match (:router.db/match db)]
      {:db (-> db
               (utils/delete [:user/id user-id])
               (utils/delete student-ident))
       :fx [[:dispatch [:toast-notification {:type :success
                                             :message "User has been deleted."}]]
            [:dispatch [:router.event/navigate :page.user/index {:path-params (r.utils/org+school-params match)}]]]})))
