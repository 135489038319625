(ns io.klei.lms.frontend.feature.login-by-password
  (:require
    [ajax.core :as ajax]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.config :as config]
    [io.klei.lms.frontend.shared.utils :as utils]))

;;--------------------------------------------------------------------------------------------------------------
;; UI

(defn login-form []
  (let [error @(rf/subscribe [:feat.login-by-password.sub/error-message])]
    [ui/form {:name "basic"
              :layout "vertical"
              :autoComplete "off"
              :labelCol {:span 24}
              :wrapperCol {:span 24}
              :onFinish #(rf/dispatch [:feat.login-by-password/login %])}

     (when error [ui/form-item [ui/alert {:message error
                                          :type "error"
                                          :showIcon true}]])
     [ui/form-item {:label "Username"
                    :name "username"
                    :rules [{:required true
                             :message "Please input your username."}]}
      [ui/input {:autoCorrect "off"
                 :autoCapitalize "none"}]]
     [ui/form-item {:label "Password"
                    :name "current-password"
                    :rules [{:required true
                             :message "Please input your password."}]}
      [ui/input-password]]

     [ui/button {:type "primary" :htmlType "submit" :block true} "Login"]]))


;;--------------------------------------------------------------------------------------------------------------
;; MODEL

(rf/reg-event-fx
  :feat.login-by-password/login
  (fn [{db :db} [_ payload]]
    {:http-xhrio (utils/http-map db
                                 {:method :post
                                  :uri "/auth/login"
                                  :params payload
                                  :with-credentials true
                                  :on-success [:feat.login-by-password/login-success]
                                  :on-failure [:feat.login-by-password/login-failure]})}))

(rf/reg-event-fx
  :feat.login-by-password/login-success
  (fn [{db :db} [_ response]]
    {:db (assoc db :feat.login-by-password.db/error-message nil)
     :dispatch [:entity.current-user.event/access-token-login (:access-token response)]}))

(rf/reg-event-db
  :feat.login-by-password/login-failure
  (fn [db [_ result]]
    (assoc-in db [:feat.login-by-password.db/error-message] (get-in result [:response :message]))))

;;--------------------------------------------------------------------------------------------------------------
;; Subs

(rf/reg-sub
  :feat.login-by-password.sub/error-message
  (fn [db _]
    (get-in db [:feat.login-by-password.db/error-message])))



