(ns io.klei.lms.frontend.page.school-admin.new-material
  (:require
    [reagent.core :as r]
    [io.klei.lms.frontend.feature.material.new-material :as f.material.new-material]
    [io.klei.lms.frontend.shared.ui :as ui]))

(defn new-material-content []
  (let []
    [ui/row
     [ui/col {:span 24}
      [f.material.new-material/new-material-form]]]))

