(ns io.klei.lms.frontend.app.router
  (:require
    [re-frame.core :as rf]
    [reitit.frontend :as reitit.frontend]
    [reitit.frontend.controllers :as rfc]
    [reitit.frontend.easy :as rfe]
    [taoensso.timbre :as log]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [io.klei.lms.frontend.entity.user :as e.user]
    [io.klei.lms.frontend.entity.current-user :as e.current-user]
    [io.klei.lms.frontend.app.routes :as routes]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.shared.route-utils :as r.utils]
    [io.klei.lms.frontend.feature.analytics :as analytics]))

(defn match-fx [cofx match]
  (let [page-name (-> match :data :name)
        org-id (r.utils/org-id match)
        school-id (r.utils/school-id match)
        db (:db cofx)]
    (cond
      ;;------------------------------------------------------------
      ;; COMMON
      (= page-name :pages/login)
      (when-let [access-token (:entity.current-user.db/access-token db)]
        [[:dispatch [:entity.current-user.event/access-token-login access-token]]])

      ;;------------------------------------------------------------
      ;; TEACHER
      (= page-name :page.teacher/section-view)
      (let [section-id (-> match :path-params :section-id)]
        [[:dispatch [:entity.section.event/get-by-id section-id]]
         [:dispatch [:entity.section.event/get-section-students section-id]]])

      ;;------------------------------------------------------------
      ;; SCHOOL-ADMIN
      (= page-name :page.school-admin/registration)
      [[:dispatch [:feat.registration.by-date.event/set-filter
                   {:date-range (utils/default-year-range)
                    :pagination utils/default-pagination}]]]

      (= page-name :page.school-admin/register-new-student)
      [[:dispatch [:feat.registration.new-student.event/get-academic-year-sections]]]

      (= page-name :page.school-admin/register-existing-student)
      [[:dispatch [:feat.registration.existing-student.event/get-academic-year-sections]]
       [:dispatch [:entity.student.event/get-by-id (-> match :path-params :student-id)]]]

      (= page-name :page.school-admin/material)
      [[:dispatch [:entity.material.event/get-all]]
       [:dispatch [:entity.course.event/get-all]]]

      (= page-name :page.school-admin/new-material)
      [[:dispatch [:entity.course.event/get-all]]]

      (= page-name :page.school-admin/teacher)
      [[:dispatch [:entity.teacher.event/get-all]]]

      (= page-name :page.school-admin/sections)
      [[:dispatch [:entity.teacher.event/get-all]]
       [:dispatch [:entity.course.event/get-all]]
       [:dispatch [::pathom/query {:entity {:academic-year/id (-> match :path-params :ay-id)}
                                   :eql [{:academic-year/sections [:section/id
                                                                   :section/name
                                                                   :section/level
                                                                   :section/teachers
                                                                   :section/course
                                                                   :section/schedules]}]
                                   :on-success [:entity/add]}]]]

      (= page-name :page.school-admin/course)
      [[:dispatch [:entity.course.event/get-all]]
       [:dispatch [:entity.subject.event/get-all]]]

      (= page-name :page.school-admin/subject)
      [[:dispatch [:entity.subject.event/get-all]]
       [:dispatch [:entity.general-subject.event/get-all]]]

      (= page-name :page.user/index)
      [[:dispatch [:entity.user.event/get-all]]]

      (= page-name :page.user/single)
      (let [user-id (-> match :path-params :user-id)
            role (-> match :query-params :role)]
        [[:dispatch [:entity.user.event/get-info user-id role]]
         [:dispatch [:entity.student.event/get-registrations user-id]]])

      (= page-name :page.my/forms)
      (let [user (e.current-user/current-user db)]
        [[:dispatch [:entity.user.event/get-info (:user/id user) (e.user/user->default-role user)]]
         [:dispatch [:entity.student.event/get-registrations (:user/id user)]]])

      (= page-name :page.school/index)
      [[:dispatch [:entity.school.event/get-by-org-id org-id]]]

      (= page-name :page.student/index)
      [[:dispatch [:feat.students-by-school.event/get-by-school-id school-id]]]

      ;; ELSE
      :else
      [])))

(defn- page-title [match]
  (let [title (or (-> match :data :title)
                  "")]
    (str title " | KLEI")))

(rf/reg-event-fx
  :router/set-match
  (fn [{:keys [db] :as cofx} [_ match :as event]]
    (let [allow-anonymous? (-> match :data :allow-anonymous?)
          user (:entity.current-user.db/user-id db)
          expiration (:entity.current-user.db/token-expiration db)]
      (if (and (not allow-anonymous?)
               (or (nil? user)
                   (utils/date-expired? expiration)))
        {:dispatch [:entity.current-user.event/access-token-logout]}
        {:db (assoc db :router.db/match match)
         :fx (into
               [[:window/title {:title (page-title match)}]]
               (match-fx cofx match))}))))

(rf/reg-sub
  :router.sub/match
  (fn [db _]
    (:router.db/match db)))

(rf/reg-event-fx
  :router.event/navigate
  (fn [{:keys [db]} [_ route-kw opts]]
    {:fx (into [[:re-reitit/redirect [route-kw opts]]]
           (analytics/navigate-fx db route-kw opts))}))

(rf/reg-fx
  :re-reitit/redirect
  (fn [[name {:keys [path-params query-params op]
              :or {op :push}}]]
    (cond
      (= :push op)
      (rfe/push-state name path-params query-params)

      (= :replace op)
      (rfe/replace-state name path-params query-params)

      :else
      (log/error (ex-info
                   (str "Unknown op `" op "` provided. Expected :push or :replace")
                   {:name name
                    :path-params path-params
                    :query-params query-params
                    :op op})))))

(def router
  (reitit.frontend/router routes/routes))

(defn on-navigate [new-match _history]
  (let [old-match @(rf/subscribe [:router.sub/match])]
    (when (not= old-match new-match)
      (let [controllers (rfc/apply-controllers (:controllers old-match) new-match)
            new-match (assoc new-match :controllers controllers)]
        (rf/dispatch [:router/set-match new-match])))))

(defn start! []
  (rfe/start!
    router
    on-navigate
    {:use-fragment true}))
