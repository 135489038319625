(ns io.klei.lms.frontend.page.school-admin.subject
  (:require
    [goog.object :as gobject]
    [goog.string :as gstring]
    [pyramid.core :as p]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.page.layout :as layout]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]))

(defn subject-table []
  (let [data-source @(rf/subscribe [:feat.subject-list.sub/table-data])]
    [ui/table
     {:className "kl-table kl-table--drawer"
      :dataSource data-source
      :columns [{:title "Name"
                 :key "name"
                 :dataIndex ["subject" "name"]}
                {:title "General Subject"
                 :key "general-subject"
                 :dataIndex ["subject" "general-subject" "name"]}]
      :onRow (fn [^js record]
               #js {:onClick #(rf/dispatch [:feat.subject-edit.event/open (gobject/getValueByKeys record "subject" "id")])})}]))

(defn subject-add-form []
  (let [^js form @(rf/subscribe [:feat.subject-add.sub/form])
        submitting? @(rf/subscribe [:feat.subject-add.sub/submitting?])
        gen-subject-options @(rf/subscribe [:entity.general-subject.sub/select-options])]
    [ui/form {:ref #(rf/dispatch [:feat.subject-add.event/set-form %])
              :labelCol {:span 5}
              :wrapperCol {:span 19}
              :onFinish (fn [values]
                          (rf/dispatch [:feat.subject-add.event/add-subject form values]))}
     [ui/form-item {:label "Name"
                    :name "name"
                    :rules [{:required true}]}
      [ui/input]]
     [ui/form-item {:label "General Subject"
                    :name "general-subject-id"
                    :rules [{:required true}]}
      [ui/select-with-options {:options gen-subject-options
                               :filterOption utils/default-select-filter-option-fn
                               :onChange (fn [val]
                                           (.setFieldsValue form #js {:general-subject-id val}))}]]
     [ui/form-item {:wrapperCol {:span 24}}
      [ui/button {:type "primary"
                  :htmlType "submit"
                  :loading submitting?
                  :style {:margin-left "auto"
                          :display "block"}}
       "Save"]]]))

(defn subject-edit-form [{:keys [subject]}]
  (let [^js form @(rf/subscribe [:feat.subject-edit.sub/form])
        submitting? @(rf/subscribe [:feat.subject-edit.sub/submitting?])
        gen-subject-options @(rf/subscribe [:entity.general-subject.sub/select-options])
        gen-subject-id (-> subject :subject/general-subject :general-subject/id)]
    [ui/form {:ref #(rf/dispatch [:feat.subject-edit.event/set-form %])
              :labelCol {:span 5}
              :wrapperCol {:span 19}
              :initialValues (clj->js {:name (:subject/name subject)
                                       :general-subject-id gen-subject-id})
              :onFinish (fn [values]
                          (rf/dispatch [:feat.subject-edit.event/edit-subject (:subject/id subject) values]))}
     [ui/form-item {:label "Name"
                    :name "name"
                    :rules [{:required true}]}
      [ui/input]]
     [ui/form-item {:label "General Subject"
                    :name "general-subject-id"
                    :rules [{:required true}]}
      [ui/select-with-options {:options gen-subject-options
                               :defaultValue gen-subject-id
                               :filterOption utils/default-select-filter-option-fn
                               :onChange (fn [val]
                                           (.setFieldsValue form #js {:general-subject-id val}))}]]
     [ui/form-item {:wrapperCol {:span 24}}
      [ui/button {:type "primary"
                  :htmlType "submit"
                  :loading submitting?
                  :style {:margin-left "auto"
                          :display "block"}}
       "Save"]]]))

(defn subjects-content []
  [:<>
   [ui/row {:style {:margin-bottom "16px"}}
    [ui/col {:span 24}
     [ui/button
      {:icon (r/as-element [icon/plus])
       :style {:float "right"}
       :on-click #(rf/dispatch [:feat.subject-add.event/open])}
      "Add New Subject"]
     (when-let [_add-open? @(rf/subscribe [:feat.subject-add.sub/open?])]
       [ui/drawer
        {:title "Add New Subject"
         :open true
         :onClose #(rf/dispatch [:feat.subject-add.event/close])}
        [subject-add-form]])]]
   [ui/row
    [ui/col {:span 24}
     [subject-table]
     (let [subject-id @(rf/subscribe [:feat.subject-edit.sub/selected-subject-id])
           subject @(rf/subscribe [:entity.subject.sub/one-by-id subject-id])]
       (when subject
         [ui/drawer
          {:title "Edit Subject"
           :open true
           :onClose #(rf/dispatch [:feat.subject-edit.event/close])}
          [subject-edit-form {:subject subject}]]))]]])

(rf/reg-sub
  :feat.subject-list.sub/table-data
  :<- [:entity.subject.sub/list]
  (fn [list* _]
    (->> list*
         (mapv (fn [subject]
                 {:key (-> subject :subject/id)
                  :subject {:id (-> subject :subject/id)
                            :name (-> subject :subject/name)
                            :general-subject {:id (-> subject :subject/general-subject :general-subject/id)
                                              :name (-> subject :subject/general-subject :general-subject/name)}}})))))

;;------------------------------------------------------------
;; ADD SUBJECT
(rf/reg-event-db
  :feat.subject-add.event/open
  (fn [db _]
    (assoc db :feat.subject-add.db/open? true)))

(rf/reg-event-db
  :feat.subject-add.event/close
  (fn [db _]
    (assoc db :feat.subject-add.db/open? false)))

(rf/reg-sub
  :feat.subject-add.sub/open?
  (fn [db _]
    (get db :feat.subject-add.db/open? false)))

(rf/reg-event-db
  :feat.subject-add.event/set-form
  (fn [db [_ form]]
    (assoc db :feat.subject-add.db/form form)))

(rf/reg-sub
  :feat.subject-add.sub/form
  (fn [db _]
    (get db :feat.subject-add.db/form)))

(rf/reg-sub
  :feat.subject-add.sub/submitting?
  (fn [db _]
    (get db :feat.subject-add.db/submitting? false)))

(rf/reg-event-fx
  :feat.subject-add.event/add-subject
  (fn [{db :db} [_ form values]]
    {:db (assoc db :feat.subject-add.db/submitting? true)
     :http-xhrio (utils/http-map db {:method :post
                                     :uri "/subject"
                                     :params values
                                     :on-success [:feat.subject-add.event/add-subject-success form]})}))

(rf/reg-event-fx
  :feat.subject-add.event/add-subject-success
  (fn [{db :db} [_ form response]]
    (let [subject (-> response
                      (utils/normalize-entity-attr-id :general-subject/id {:subject/general-subject-id :subject/general-subject}))]
      {:db (-> db
               (assoc :feat.subject-add.db/submitting? false)
               (utils/add subject))
       :antd/form-reset form
       :dispatch [:toast-notification {:type :success
                                       :message "Subject has been added."}]})))

;;------------------------------------------------------------
;; EDIT SUBJECT
(rf/reg-event-db
  :feat.subject-edit.event/open
  (fn [db [_ subject-id]]
    (assoc db :feat.subject-edit.db/selected-subject-id subject-id)))

(rf/reg-sub
  :feat.subject-edit.sub/selected-subject-id
  (fn [db _]
    (get db :feat.subject-edit.db/selected-subject-id)))


(rf/reg-event-db
  :feat.subject-edit.event/close
  (fn [db _]
    (assoc db :feat.subject-edit.db/selected-subject-id false)))

(rf/reg-event-db
  :feat.subject-edit.event/set-form
  (fn [db [_ form]]
    (assoc db :feat.subject-edit.db/form form)))

(rf/reg-sub
  :feat.subject-edit.sub/form
  (fn [db _]
    (get db :feat.subject-edit.db/form)))

(rf/reg-sub
  :feat.subject-edit.sub/submitting?
  (fn [db _]
    (get db :feat.subject-edit.db/submitting? false)))

(rf/reg-event-fx
  :feat.subject-edit.event/edit-subject
  (fn [{db :db} [_ subject-id values]]
    {:db (assoc db :feat.subject-edit.db/submitting? true)
     :http-xhrio (utils/http-map db {:method :patch
                                     :uri (gstring/format "/subject/%s" subject-id)
                                     :params values
                                     :on-success [:feat.subject-edit.event/edit-subject-success]})}))

(rf/reg-event-fx
  :feat.subject-edit.event/edit-subject-success
  (fn [{db :db} [_ response]]
    (let [subject (utils/normalize-entity-attr-id response :general-subject/id {:subject/general-subject-id :subject/general-subject})]
      {:db (-> db
               (assoc :feat.subject-edit.db/submitting? false)
               (utils/add subject))
       :dispatch [:toast-notification {:type :success
                                       :message "Subject has been updated."}]})))
