(ns io.klei.lms.frontend.page.user.single
  (:require
    [pyramid.core :as p]
    [reagent.core :as r]
    [re-frame.core :as rf]
    ["react-responsive" :as responsive]

    [io.klei.lms.frontend.feature.student.profile-sections :as f.student.profile-sections]
    [io.klei.lms.frontend.page.user.index :as p.user.index]
    [io.klei.lms.frontend.entity.user :as e.user]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.shared.ui :as ui]))

(defn single-content [{:keys [match]}]
  (let [user (rf/subscribe [:entity.user.sub/one-by-id (-> match :path-params :user-id)])]
    (fn []
      (when @user
       (let [role (e.user/default-role (:user/roles @user))]
         (cond
           (= role :student)
           [:f> f.student.profile-sections/student-profile-sections
            {:user-id (-> match :path-params :user-id)
             :tab-position "left"}]

           :else
           [p.user.index/user-edit-form {:user @user}]))))))
