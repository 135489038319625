(ns io.klei.lms.frontend.entity.material
  (:require
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn sort-material-sharing [material]
  (update-in material [:material/sharing] (fn [share-list]
                                            (sort-by
                                              #(get-in % [:sharing/to :course/name])
                                              share-list))))

(rf/reg-event-fx
  :entity.material.event/get-material-url
  (fn [{db :db} [_ material-id]]
    {:http-xhrio
     (utils/http-map db {:method :get
                         :uri (str "/material/" material-id "/url")
                         :on-success [:entity.material.event/get-material-url-success]
                         :on-failure []})}))

(rf/reg-event-fx
  :entity.material.event/get-material-url-success
  (fn [{db :db} [_ response]]
    {:db (assoc db :entity.material.db/material-url (:url response))}))

(rf/reg-event-fx
  :entity.material.event/get-all
  (fn [{db :db} [_]]
    {:http-xhrio (utils/http-map db {:method :get
                                     :uri (str "/material")
                                     :on-success [:entity.material.events.get-all-success]})}))

(rf/reg-event-fx
  :entity.material.events.get-all-success
  (fn [{db :db} [_ response]]
    {:db (assoc db :entity.material.db/all-by-id (into {}
                                                       (for [m response]
                                                         [(:material/id m) (sort-material-sharing m)])))}))

(rf/reg-event-fx
  :entity.material.event/remove-sharing
  (fn [{db :db} [_ material-id sharing-id]]
    (let [path [:entity.material.db/all-by-id material-id]
          material (update-in (get-in db path) [:material/sharing]
                              (fn [sharing-list]
                                (remove #(= sharing-id (:sharing/id %))
                                        sharing-list)))]
      {:db (assoc-in db path material)})))

(rf/reg-event-fx
  :entity.material.event/add-sharing
  (fn [{db :db} [_ material-id sharing]]
    (let [path [:entity.material.db/all-by-id material-id]
          material (update-in (get-in db path) [:material/sharing] (fn [sharing-list]
                                                                     (->> (conj sharing-list sharing)
                                                                       (sort-by #(get-in % [:sharing/to :course/name])))))]
      {:db (assoc-in db path material)})))


;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :entity.material.sub/file-url
  (fn [db _]
    (get db :entity.material.db/material-url)))

(rf/reg-sub
  :entity.material.sub/all-by-id
  (fn [db _]
    (get db :entity.material.db/all-by-id)))

(rf/reg-sub
  :entity.material.sub/all
  :<- [:entity.material.sub/all-by-id]
  (fn [all _]
    (->> all
         (map (fn [[_k m]]
                m))
         (sort-by :material/title))))
