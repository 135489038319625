(ns io.klei.lms.frontend.shared.utils
  (:require
    [clojure.string :as string]
    [clojure.set :as set]
    [pyramid.core :as p]
    [cljs-bean.core :as bean]
    ["moment" :as moment]
    ["moment-timezone" :as moment-tz]
    [goog.crypt.base64 :as base64]
    [goog.object :as g.object]
    [goog.string :as g.string]
    [goog.i18n.DateTimeFormat :as g.dtf]
    [goog.Uri :as g.uri]
    [goog.Uri.QueryData :as g.uri.QueryData]
    [ajax.core :as ajax]
    [re-frame.core :as rf]
    [tick.core :as t]
    [io.klei.lms.frontend.shared.config :as config]
    [taoensso.timbre :as log]))

(def kw-slash-replacement "_SLASH_")

(defn nskw->str [kw]
  (subs (str kw) 1))

(defn nskw->json-key [kw]
  (if (qualified-keyword? kw)
    (str (namespace kw) kw-slash-replacement (name kw))
    (name kw)))

(defn json-key->nskw [s]
  (keyword (string/replace s kw-slash-replacement "/")))

(defn stringify-keys
  "Recursively transform all map keys from keywords to strings.
  Optionally pass `kw->str` function that accepts single keyword and return a string. Defaults to `name`"
  ([m] (stringify-keys m name))
  ([m kw->str]
   (let [f (fn [[k v]]
             (if (keyword? k)
               [(kw->str k) v]
               [k v]))]
     (clojure.walk/postwalk
       (fn [x]
         (if (map? x)
           (into {} (map f x))
           x))
       m))))

(defn js->nskw [data]
  (bean/->clj data
              :prop->key json-key->nskw
              :key->prop nskw->json-key))

(defn nskw->js [data]
  (bean/->js data
             :key->prop nskw->json-key
             :prop->key json-key->nskw))

(defn nskw->js-object [data]
  (-> (bean/->js data
                 :key->prop nskw->json-key
                 :prop->key json-key->nskw)
      (bean/object)))

(defn js-object [bean']
  (bean/object bean'))

;; copy+paste https://dnaeon.github.io/recursively-merging-maps-in-clojure/
(defn deep-merge
  "Recursively merges maps."
  [& maps]
  (letfn [(m [& xs]
            (if (some #(and (map? %) (not (record? %))) xs)
              (apply merge-with m xs)
              (last xs)))]
    (reduce m maps)))

(defn base64-decode-string [s]
  ;; convert - (minus) to + (plus)
  ;; convert _ (underscore) to / (forward slash)
  ;; do the opposite for encode
  (-> s
      (string/replace #"-" "+")
      (string/replace #"_" "/")
      base64/decodeString))

(defn- token-payload [token]
  (-> token
      (string/split #"\.")
      second))

(defn- keywordize-roles [user]
  (update-in user [:user/roles] #(set (map keyword %))))

(defn jwt-decode [token]
  (-> (token-payload token)
      base64-decode-string
      js/JSON.parse
      (js->clj :keywordize-keys true)
      keywordize-roles))

(defn token-expiration [user]
  (-> (:exp user)
      (* 1000)
      (js/Date.)))

(comment
  (-> "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidWlkIjoiMzNDd1lDN0lUd1lMRUMiLCJlbWFpbCI6InVzZXJAa2xlaS5pbyIsImV4cCI6MTY1MTQwMTY4NX0.u9tTcZp_n_lv2GgtvPMm7GfYpoyWS-OL9IK2aF7hkUM"
      jwt-decode))

(defn bearer-token [token]
  (str "Bearer " token))

(defn- method-get? [m]
  (= :get (:method m)))

;;(not (method-get? m)) (assoc :format (ajax/json-request-format))

(defn api-endpoint [path]
  (if (or (string/starts-with? path "http")
          (string/starts-with? path "https"))
    path
    (str config/api-endpoint path)))

(defn http-map [db m]
  (let [m (if (not (method-get? m))
            (assoc m :format (or (:format m)
                                 (ajax/json-request-format)))
            m)]
    (-> m
        (update-in [:uri] #(api-endpoint %))
        (assoc :response-format (or (:response-format m)
                                    (ajax/json-response-format {:keywords? true})))
        (assoc :timeout 15000)
        (assoc-in [:headers :authorization] (bearer-token (:entity.current-user.db/access-token db))))))

(defn- strip-leading-trailing-slash [path]
  (or (some-> path
              (string/replace #"^/+" "")
              (string/replace #"/+$" ""))
      ""))

(defn thumbnail-url [path]
  (-> (string/join "/" [(strip-leading-trailing-slash config/thumbnail-host)
                        (strip-leading-trailing-slash path)])
      (js/URL.)
      (.toString)))

(defn file-url [file-id]
  (api-endpoint (g.string/format "/file/%s/url" file-id)))

(defn logo-url [path]
  (str config/logo-host path))

(defn avatar-fallback-url []
  (str config/avatar-host "/user.svg"))

;; Create a macro that supports :http-xhrio but
;; extending :on-success to accept last item in vector as callback
;; :on-success [:entity.section.event/get-by-id-success :some-event-to-dispatch-accepting-response]
;;(defmacro reg-http-xhrio
;;  ([id handler]
;;   `(rf/reg-event-fx id nil handler))
;;  ([id interceptors handler]
;;   `(let [event-v ~(second (second handler))
;;          callback-id (last event-v)]
;;      (rf/reg-event-fx id interceptors handler))))


;;------------------------------------------------------------
;; DATES
(def ^:private days-of-the-week-map
  {"mon" {:order 1
          :name "Monday"}
   "tue" {:order 2
          :name "Tuesday"}
   "wed" {:order 3
          :name "Wednesday"}
   "thu" {:order 4
          :name "Thursday"}
   "fri" {:order 5
          :name "Friday"}
   "sat" {:order 6
          :name "Saturday"}
   "sun" {:order 7
          :name "Sunday"}})

(def days-of-the-week (keys days-of-the-week-map))
(def week-days (select-keys days-of-the-week-map ["mon" "tue" "wed" "thu" "fri"]))

(def days-of-the-week-names
  (->> days-of-the-week-map
       (sort-by :name)))

(defn day-name [day]
  (-> (days-of-the-week-map day)
      :name))

(defn day-order [day]
  (-> (days-of-the-week-map day)
      :order))

(defn- day-sort-fn [a b]
  (< (day-order (key a))
     (day-order (key b))))

(defn fill-missing-keys [keys grouped]
  (reduce
    (fn [g k]
      (if (g k)
        g
        (assoc g k [])))
    grouped
    keys))

(defn schedules-group-by-day [section-schedules]
  (->> section-schedules
       (group-by :section-schedule/day)))

(defn plot-schedules-by-day [schedules]
  (->> schedules
       (sort-by :section-schedule/start-time)
       (schedules-group-by-day)
       (fill-missing-keys (keys week-days))
       (sort day-sort-fn)))

(defn date-of-birth->display [date]
  (t/format (t/formatter "MMM dd, yyyy") (t/date date)))

;; camelCase because antd convention
(def default-pagination
  #js {:current 1
       :pageSize 20})

(defn default-year-range []
  [(.startOf ^js (moment) "year")
   (moment (js/Date. (+ (.getFullYear (js/Date.)) 1) 0 1))])

(defn format-datetime [date]
  (.format (goog.i18n.DateTimeFormat. "YYYY-MM-dd hh:ss a") date))

(defn format-date [date]
  (.format date "YYYY-MM-DD"))

(defn format-time [time-moment]
  (.format time-moment "HH:mm"))

(defn set-date->str
  "Sets the values key to formatted date.
  Ex. Jan 31, 2023 to 2023-01-31"
  [^js values k]
  (when-let [date (g.object/get values (nskw->str k))]
    (when (instance? js/Date date)
      (g.object/set values (nskw->str k) (.format (goog.i18n.DateTimeFormat. "YYYY-MM-dd") date)))))

(defn progress-event-percent [^js progress-event]
  (js/Math.round (* 100 (/ (g.object/get progress-event "loaded")
                           (g.object/get progress-event "total")))))

;;------------------------------------------------------------
;; ENTITY utils
(defn normalize [k-fn coll]
  (into {}
        (for [m coll]
          [(k-fn m) m])))

(defn normalize-sorted [k-fn coll sort-key]
  (let [m (normalize k-fn coll)]
       (into (sorted-map-by
               (fn [k1 k2]
                 (compare (get-in m [k1 sort-key]) (get-in m [k2 sort-key]))))
             m)))

(defn bytes->MB [bytes]
  (/ bytes 1000 1000))

(defn pull
  ([db ident]
   (-> db
       (p/pull [ident])
       (get ident)))
  ([db ident pattern]
   (-> db
       (p/pull [{ident pattern}])
       (get ident))))

(defn pull-many [db k ids pattern]
  (for [id ids]
    (pull db [k id] pattern)))

(defn add [db entity-coll]
  (cond
    ;; For single entity
    (map? entity-coll)
    (p/add db entity-coll)

    ;; Ensure it is not empty to
    ;; prevents `Uncaught Error: No item 0 in vector of length 0`
    (seq entity-coll)
    (p/add db entity-coll)

    :else
    db))

(defn delete [db ident]
  ;; The reitit.core.Match is being converted to '() list
  ;; after calling delete. So we have to back up the value
  (let [match (:router.db/match db)]
    (-> db
        (p/delete ident)
        (assoc :router.db/match match))))

(defn normalize-entity-attr-ids [m entity-key rename-map]
  (-> m
      (dissoc :xt/id)
      (update (ffirst rename-map) (fn [ids]
                                    (map #(vector entity-key %) ids)))
      (set/rename-keys rename-map)))

(defn normalize-entity-attr-id [m entity-key rename-map]
  (-> m
      (dissoc :xt/id)
      (update (ffirst rename-map) #(vector entity-key %))
      (set/rename-keys rename-map)))


;;------------------------------------------------------------
;; ANTD helpers
(defn default-select-filter-option-fn [input ^js option]
  (string/includes? (string/lower-case (or (g.object/get option "children") ""))
                    (string/lower-case (or input ""))))

(defn name-select-filter-option-fn [input ^js option]
  (string/includes? (string/replace (string/lower-case (or (g.object/get option "children") "")) #"," "")
                    (string/replace (string/lower-case (or input "")) #"," "")))

(defn search-by [k-fn search-text coll]
  (->> coll
       (filter (fn [m]
                 (string/includes? (string/lower-case (or (k-fn m) ""))
                                   (string/lower-case (or search-text "")))))))

(defn entities->select-options [[value-key text-key] entities]
  (->> entities
       (mapv (fn [e]
              {:value (get e value-key)
               :label (get e text-key)
               :text (get e text-key)}))))

(defn date-expired? [expiration]
  (> (js/Date.) expiration))

(defn convert-dubai-to-local [time-str]
  (when time-str
    (let [[hours minutes] (map js/parseInt (string/split time-str #":"))
         ^js now (js/Date.)
         ^js dubai-time (.tz ^js moment-tz #js [(.getFullYear now) (.getMonth now) (.getDate now) hours minutes] "Asia/Dubai")
         ^js local-time (.tz ^js (.clone dubai-time) (.guess ^js (.-tz ^js moment-tz) true))]
     (.format local-time "hh:mm A"))))

(defn form-url [form-id params]
  (let [url (g.uri/parse (str config/form-host "/" form-id "/pdf"))
        query (g.uri.QueryData/createFromMap (clj->js params))]
    (.setQueryData url query)
    (.toString url)))

(comment
  (form-url "deped-annex-1" {:student-id "123"}))

(def ^:const CONTENT_DISPOSITION->FILENAME_PATTERN
  #"^attachment\s*;\s*filename\s*=\s*(.*)")

(defn get-file-blob [{:keys [uri
                             token
                             file-name]}]
  (-> (js/fetch uri
                (clj->js {:headers {:authorization (str "Bearer " token)}}))
      (.then (fn [response]
               (if (.-ok response)
                 response
                 (throw (ex-info
                          "Failed to download file"
                          {:status (.-status response)
                           :status-text (.-statusText response)})))))
      (.then (fn [response]
               (let [content-disposition (str (.get (.-headers response) "Content-Disposition"))
                     header-file-name (or file-name
                                           (some->
                                             (re-find
                                               CONTENT_DISPOSITION->FILENAME_PATTERN
                                               content-disposition)
                                             second))]
                 (js/Promise.all (into-array [(.blob response) header-file-name])))))))

(defn download-file [{:keys [on-success on-error] :as  opts}]
  (let [anchor (js/document.createElement "a")]
    (-> (get-file-blob opts)
        (.then (fn [[blobby response-file-name]]
                 (log/info :download-file/blob [blobby response-file-name])
                 (let [object-url (js/window.URL.createObjectURL blobby)]
                   (set! (.-href anchor) object-url)
                   (set! (.-download anchor) response-file-name)
                   (.click anchor)
                   (js/window.URL.revokeObjectURL object-url)
                   (when (fn? on-success)
                     (on-success)))))
        (.catch (fn [error]
                  (when (fn? on-error)
                    (on-error (ex-data error))))))))
