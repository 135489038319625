(ns io.klei.lms.frontend.page.school-admin.register-student
  (:require
    [re-frame.core :as rf]
    [reagent.core :as r]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.page.layout :as layout]
    [io.klei.lms.frontend.feature.registration.new-student :as f.registration.new-student]
    [io.klei.lms.frontend.feature.registration.existing-student :as f.registration.existing-student]))

(defn new-student-content []
  [ui/row
   [ui/col {:span 24}
    [f.registration.new-student/register-new-student-form]]])

(defn existing-student-content []
  (let [match (rf/subscribe [:router.sub/match])]
    [ui/row
     [ui/col {:span 24}
      [f.registration.existing-student/register-existing-student-form (-> @match :path-params :student-id)]]]))
