(ns io.klei.lms.frontend.page.school-admin.course
  (:require
    [goog.object :as gobject]
    [goog.string :as gstring]
    [pyramid.core :as p]
    [clojure.set :as set]
    [re-frame.core :as rf]
    [reagent.core :as r]
    [io.klei.lms.frontend.page.layout :as layout]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]))

(defn course-table []
  (let [data-source @(rf/subscribe [:feat.course-list.sub/table-data])]
    [ui/table
     {:className "kl-table kl-table--drawer"
      :dataSource data-source
      :columns [{:title "Name"
                 :key "name"
                 :dataIndex ["course" "name"]}
                {:title "Subjects"
                 :key "subjects"
                 :dataIndex ["course" "subjects"]
                 :render (fn [^js record]
                           (r/as-element
                             (into [ui/space]
                                   (for [subject record]
                                     [ui/tag (gobject/get subject "name")]))))}]
      :onRow (fn [^js record _index]
               #js {:onClick #(rf/dispatch [:feat.course-edit.event/open (gobject/getValueByKeys record "course" "id")])})}]))

(defn course-edit-form [{:keys [course]}]
  (let [^js form @(rf/subscribe [:feat.course-edit.sub/form])
        submitting? @(rf/subscribe [:feat.course-edit.sub/submitting?])
        subject-options @(rf/subscribe [:entity.subject.sub/select-options])
        subject-ids (->> (:course/subjects course)
                         (mapv :subject/id))]
    [ui/form {:ref #(rf/dispatch [:feat.course-edit.event/set-form %])
              :labelCol {:span 4}
              :wrapperCol {:span 20}
              :initialValues (clj->js {:name (:course/name course)
                                       :subject-ids subject-ids})
              :onFinish (fn [values]
                          (rf/dispatch [:feat.course-edit.event/edit-course (:course/id course) values]))}
     [ui/form-item {:label "Name"
                    :name "name"
                    :rules [{:required true}]}
      [ui/input]]
     [ui/form-item {:label "Subjects"
                    :name "subject-ids"}
      [ui/select-with-options {:mode "multiple"
                               :defaultValue subject-ids
                               :options subject-options
                               :filterOption utils/default-select-filter-option-fn
                               :onChange (fn [values]
                                           (.setFieldsValue form #js {:subject-ids values}))}]]
     [ui/form-item {:wrapperCol {:span 24}}
      [ui/button {:type "primary"
                  :htmlType "submit"
                  :loading submitting?
                  :style {:margin-left "auto"
                          :display "block"}}
       "Save"]]]))

(defn course-add-form []
  (let [^js form @(rf/subscribe [:feat.course-add.sub/form])
        submitting? @(rf/subscribe [:feat.course-add.sub/submitting?])
        subject-options @(rf/subscribe [:entity.subject.sub/select-options])]
    [ui/form {:ref #(rf/dispatch [:feat.course-add.event/set-form %])
              :labelCol {:span 4}
              :wrapperCol {:span 20}
              :onFinish (fn [values]
                          (rf/dispatch [:feat.course-add.event/add-course form values]))}
     [ui/form-item {:label "Name"
                    :name "name"
                    :rules [{:required true}]}
      [ui/input]]
     [ui/form-item {:label "Subjects"
                    :name "subject-ids"}
      [ui/select-with-options {:mode "multiple"
                               :options subject-options
                               :filterOption utils/default-select-filter-option-fn
                               :onChange (fn [values]
                                           (.setFieldsValue form #js {:subject-ids values}))}]]
     [ui/form-item {:wrapperCol {:span 24}}
      [ui/button {:type "primary"
                  :htmlType "submit"
                  :loading submitting?
                  :style {:margin-left "auto"
                          :display "block"}}
       "Save"]]]))

(defn courses-content []
  (let [open? @(rf/subscribe [:feat.course-add.sub/open?])]
    [:<>
     [ui/row {:style {:margin-bottom "16px"}}
      [ui/col {:span 24}
       [ui/button
        {:icon (r/as-element [icon/plus])
         :style {:float "right"}
         :on-click #(rf/dispatch [:feat.course-add.event/open])}
        "Add New Course"]
       (when open?
         [ui/drawer
          {:title "Add New Course"
           :open true
           :onClose #(rf/dispatch [:feat.course-add.event/close])}
          [course-add-form]])]]
     [ui/row
      [ui/col {:span 24}
       [course-table]
       (let [course-id @(rf/subscribe [:feat.course-edit.sub/selected-course-id])
             course @(rf/subscribe [:entity.course.sub/one-by-id course-id])]
         (when course
           [ui/drawer
            {:title "Edit Course"
             :open true
             :onClose #(rf/dispatch [:feat.course-edit.event/close])}
            [course-edit-form {:course course}]]))]]]))

;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-db
  :feat.course-edit.event/open
  (fn [db [_ course-id]]
    (assoc db :feat.course-edit.db/selected-course-id course-id)))

(rf/reg-event-db
  :feat.course-edit.event/close
  (fn [db _]
    (assoc db :feat.course-edit.db/selected-course-id nil)))

(rf/reg-event-db
  :feat.course-edit.event/set-form
  (fn [db [_ form]]
    (assoc db :feat.course-edit.db/form form)))

(rf/reg-event-fx
  :feat.course-edit.event/edit-course
  (fn [{db :db} [_ course-id values]]
    {:db (assoc db :feat.course-edit.db/submitting? true)
     :http-xhrio (utils/http-map db {:method :patch
                                     :uri (gstring/format "/course/%s" course-id)
                                     :params values
                                     :on-success [:feat.course-edit.event/edit-course-success]})}))

(rf/reg-event-fx
  :feat.course-edit.event/edit-course-success
  (fn [{db :db} [_ response]]
    (let [course (-> response
                     (update :course/subject-ids (fn [ids]
                                                   (mapv #(utils/pull db [:subject/id %]) ids)))
                     (set/rename-keys {:course/subject-ids :course/subjects}))]
      {:db (-> db
               (assoc :feat.course-edit.db/submitting? false)
               (utils/add course))
       :dispatch [:toast-notification {:type :success
                                       :message "Course has been updated."}]})))
;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :feat.course-list.sub/table-data
  :<- [:entity.course.sub/list]
  (fn [list*]
    (->> list*
         (mapv (fn [course]
                 {:key (-> course :course/id)
                  :course {:id (-> course :course/id)
                           :name (-> course :course/name)
                           :subjects (->> (:course/subjects course)
                                          (sort-by :subject/name)
                                          (mapv (fn [subject]
                                                  {:id (-> subject :subject/id)
                                                   :name (-> subject :subject/name)})))}})))))

(rf/reg-sub
  :feat.course-edit.sub/selected-course-id
  (fn [db _]
    (get db :feat.course-edit.db/selected-course-id)))

(rf/reg-sub
  :feat.course-edit.sub/form
  (fn [db _]
    (get db :feat.course-edit.db/form)))

(rf/reg-sub
  :feat.course-edit.sub/submitting?
  (fn [db _]
    (get db :feat.course-edit.db/submitting? false)))

;;------------------------------------------------------------
;; ADD COURSE
(rf/reg-event-db
  :feat.course-add.event/open
  (fn [db _]
    (assoc db :feat.course-add.db/open? true)))

(rf/reg-event-db
  :feat.course-add.event/close
  (fn [db _]
    (assoc db :feat.course-add.db/open? false)))

(rf/reg-sub
  :feat.course-add.sub/open?
  (fn [db _]
    (get db :feat.course-add.db/open? false)))

(rf/reg-event-db
  :feat.course-add.event/set-form
  (fn [db [_ form]]
    (assoc db :feat.course-add.db/form form)))

(rf/reg-sub
  :feat.course-add.sub/form
  (fn [db _]
    (get db :feat.course-add.db/form)))

(rf/reg-sub
  :feat.course-add.sub/submitting?
  (fn [db _]
    (get db :feat.course-add.db/submitting? false)))

(rf/reg-event-fx
  :feat.course-add.event/add-course
  (fn [{db :db} [_ form values]]
    {:db (assoc db :feat.course-add.db/submitting? true)
     :http-xhrio (utils/http-map db {:method :post
                                     :uri "/course"
                                     :params values
                                     :on-success [:feat.course-add.event/add-course-success form]})}))

(rf/reg-event-fx
  :feat.course-add.event/add-course-success
  (fn [{db :db} [_ form response]]
    (let [course (-> response
                     (utils/normalize-entity-attr-ids :subject/id {:course/subject-ids :course/subjects}))]
      {:db (-> db
               (assoc :feat.course-add.db/submitting? false)
               (utils/add course))
       :antd/form-reset form
       :dispatch [:toast-notification {:type :success
                                       :message "Course has been added."}]})))
