(ns io.klei.lms.frontend.feature.teacher-new
  (:require
    [pyramid.core :as p]
    [goog.string :as gstring]
    [goog.string.format]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.object :as gobject]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn teacher-form [{:keys [initial-values on-submit]}]
  (let [^js form @(rf/subscribe [:feat.teacher-new.sub/form])
        submitting? @(rf/subscribe [:feat.teacher-new.sub/submitting?])
        section-options @(rf/subscribe [:entity.section.sub/select-options])]
    [ui/form
     {:ref #(rf/dispatch [:feat.teacher-new.event/set-form %])
      :labelCol {:span 5}
      :wrapperCol {:span 19}
      :initialValues (clj->js initial-values)
      :onFinish (fn [values]
                  (on-submit values))}
     [ui/form-item
      {:label "First Name"
       :name "first-name"
       :rules [{:required true}]}
      [ui/input]]
     [ui/form-item
      {:label "Middle Name"
       :name "middle-name"
       :rules [{:required false}]}
      [ui/input]]
     [ui/form-item
      {:label "Last Name"
       :name "last-name"
       :rules [{:required true}]}
      [ui/input]]
     [ui/form-item
      {:label "Username"
       :name "username"
       :rules [{:required true :type "username"}]}
      [ui/input]]
     [ui/form-item
      {:label "Password"
       :name "password"
       :rules [{:required true :type "password"}]}
      [ui/input-password]]
     [ui/form-item
      {:label "Email"
       :name "email"
       :rules [{:required true :type "email"}]
       :extra "Must be a working email. Password will be sent here."}
      [ui/input]]
     [ui/form-item
      {:label "Assign Sections"
       :name "section-ids"
       :extra "Teacher will gain access to the selected sections."}
      [ui/select-with-options {:mode "multiple"
                               :options section-options
                               :onChange (fn [values]
                                           (.setFieldsValue form #js {:section-ids values}))}]]
     [ui/form-item {:wrapperCol {:span 24}}
      [ui/button {:type "primary"
                  :htmlType "submit"
                  :disabled submitting?
                  :style {:margin-left "auto"
                          :display "block"}}
       "Save"]]]))

;;------------------------------------------------------------
;; UTILS
(defn get-form [db]
  (get db :feat.teacher-new.db/form))

;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-db
  :feat.teacher-new.event/open
  (fn [db _]
    (assoc db :feat.teacher-new.db/open? true)))

(rf/reg-event-db
  :feat.teacher-new.event/close
  (fn [db _]
    (assoc db :feat.teacher-new.db/open? false)))

(rf/reg-event-fx
  :feat.teacher-new.event/add-teacher
  (fn [{db :db} [_ values]]
    {:db (assoc db :feat.teacher-new.db/submitting? true)
     :http-xhrio (utils/http-map db {:method :post
                                     :uri "/teacher"
                                     :params values
                                     :on-success [:feat.teacher-new.event/add-teacher-success]
                                     :on-failure [:feat.teacher-new.event/add-teacher-failure]})}))

(rf/reg-event-fx
  :feat.teacher-new.event/add-teacher-success
  (fn [{db :db} [_ response]]
    (let [form (get-form db)]
      {:db (-> db
               (assoc :feat.teacher-new.db/submitting? false)
               (p/add (dissoc response :xt/id)))
       :antd/form-reset form
       :fx [[:dispatch [:feat.teacher-new.event/close]]
            [:dispatch [:toast-notification {:type :success
                                             :message "Teacher has been successfully added."}]]]})))

(rf/reg-event-fx
  :feat.teacher-new.event/add-teacher-failure
  (fn [{db :db} [_ failure]]
    {:db (assoc db :feat.teacher-new.db/submitting? false)
     :dispatch [:toast-notification {:type :error
                                     :message (-> failure :response :message)}]}))

(rf/reg-event-db
  :feat.teacher-new.event/set-form
  (fn [db [_ form]]
    (assoc db :feat.teacher-new.db/form form)))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :feat.teacher-new.sub/form
  (fn [db _]
    (get-form db)))

(rf/reg-sub
  :feat.teacher-new.sub/submitting?
  (fn [db _]
    (get db :feat.teacher-new.db/submitting?)))

(rf/reg-sub
  :feat.teacher-new.sub/open?
  (fn [db _]
    (get db :feat.teacher-new.db/open?)))
