(ns io.klei.lms.frontend.feature.pdf-viewer
  (:require
    [goog.object :as gobject]
    [goog.string :as gstring]
    [re-frame.core :as rf]
    [reagent.core :as reagent]
    [react-dom]
    ["@react-pdf-viewer/core" :as react-pdf-viewer]
    ["@react-pdf-viewer/toolbar" :as toolbar]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]))

(rf/reg-event-fx
  :feat.pdf-viewer.event/open-preview
  (fn [{:keys [db]} [_ file-id]]
    {:db (assoc db :feat.pdf-viewer.db/open? true)
     :http-xhrio (utils/http-map db {:method :get
                                     :uri (gstring/format "/file/%s/url" file-id)
                                     :on-success [:feat.pdf-viewer.event/get-file-url-success]})}))

(rf/reg-event-fx
  :feat.pdf-viewer.event/get-file-url-success
  (fn [{db :db} [_ response]]
    {:db (assoc db :feat.pdf-viewer.db/file-url (:url response))}))


(rf/reg-event-fx
  :feat.pdf-viewer.event/close-preview
  (fn [{:keys [db]} _]
    {:db (assoc db :feat.pdf-viewer.db/open? false)}))

(rf/reg-sub
  :feat.pdf-viewer.sub/open?
  (fn [db _]
    (get db :feat.pdf-viewer.db/open? false)))

(rf/reg-sub
  :feat.pdf-viewer.sub/file-url
  (fn [db _]
    (get db :feat.pdf-viewer.db/file-url)))

(defn pdf-viewer [{:keys [url open? close-on-overlay-click? on-close]
                   :or {close-on-overlay-click? true
                        on-close (constantly nil)}}]
  (reagent/with-let [container (atom nil)]
    (let [^js toolbar-instance (.toolbarPlugin ^js toolbar)]
      (when open?
        (react-dom/createPortal
          (reagent/as-element
            [:div.pdf-viewer {:ref #(reset! container %)
                              :on-click (fn [^js event]
                                          (when (and close-on-overlay-click?
                                                     (= (.-target event) (some-> @container)))
                                            (on-close event)))}
             [:div.pdf-viewer__toolbar
              ;; https://react-pdf-viewer.dev/examples/toolbar-slot/
              [:> (.-Toolbar toolbar-instance)
               (fn [^js slots]
                 (reagent/as-element
                   [:<>
                    [:div {:style {:margin-left "auto"}}
                     [:> (.-ShowSearchPopover slots)]]
                    [:div [:> (.-GoToPreviousPage slots)]]
                    [:div [:> (.-GoToNextPage slots)]]
                    [:div {:style {:display "flex"
                                   :align-items "center"}}
                     [:> (.-CurrentPageInput slots)]
                     /
                     [:> (.-NumberOfPages slots)]]

                    [:div {:style {:margin-left "24px"}} [:> (.-ZoomOut slots)]]
                    [:div [:> (.-ZoomIn slots)]]
                    [:div [:> (.-Zoom slots)]]
                    [ui/button {:className "pdf-viewer__close-button"
                                :ghost true
                                :style {:margin-left "auto"}
                                :on-click (fn [event]
                                            (rf/dispatch [:feat.pdf-viewer.event/close-preview])
                                            (on-close event))}
                     "CLOSE"]]))]]
             [:> react-pdf-viewer/Worker {:worker-url "https://unpkg.com/pdfjs-dist@3.4.120/legacy/build/pdf.worker.min.js"}
              [:> react-pdf-viewer/Viewer {:file-url url
                                           :defaultScale react-pdf-viewer/SpecialZoomLevel.PageFit
                                           :plugins [toolbar-instance]}]]])
          (js/document.getElementById "pdf-viewer-root"))))))


