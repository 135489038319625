(ns io.klei.lms.frontend.entity.current-user
  (:require
    [clojure.set :as cset]
    [goog.string :as gstring]
    [re-frame.core :as rf]
    [pyramid.core :as p]
    [io.klei.lms.frontend.shared.utils :as utils]
    [taoensso.timbre :as log]
    [clojure.string :as string]
    [io.klei.lms.frontend.entity.user :as e.user]
    [io.klei.lms.frontend.shared.config :as config]))

(defn make-session-id []
  (random-uuid))

(defn set-session-id [db]
  (assoc db :entity.current-user.db/session-id (make-session-id)))

(defn get-session-id [db]
  (get db :entity.current-user.db/session-id))

(def access-token-localstorage-key "access_token")

(defn user-avatar-localstorage-key [user-id]
  (str "avatar." user-id))

(defn set-access-token-ls
  [db]
  (.setItem js/localStorage access-token-localstorage-key (:entity.current-user.db/access-token db)))  ;; sorted-map written as an EDN map

;; Removes user information from localStorge when a user logs out.
;;
(defn remove-user-ls
  []
  (.removeItem js/localStorage access-token-localstorage-key))

(defn current-user [db]
  (when-let [ident (get db :entity.current-user.db/user-id)]
    (-> (utils/pull db ident [:user/id
                              :user/email
                              :user/avatar
                              :user/roles
                              {:user/school [:school/id
                                             :school/name]}
                              {:user/organization [:organization/id
                                                   :organization/name]}])
        (update :user/roles (fn [roles]
                              (->> roles
                                   (map keyword)
                                   set))))))

(def set-access-token-interceptor [(rf/after set-access-token-ls) ;; write user to localstore (after)
                                   rf/trim-v])            ;; removes first (event id) element from the event vec

;; After logging out, clean up local-storage so that when a user refreshes
;; the browser she/he is not automatically logged-in, and because it's
;; good practice to clean-up after yourself.
;;
(def remove-user-interceptor [(rf/after remove-user-ls)])

(defn- init-msclarity-fx [user session-id]
  [[:dispatch [:analytics/identify {:id (:user/id user)
                                    :session-id session-id
                                    :page-id nil
                                    :friendly-name (:user/username user)}]]
   [:dispatch [:analytics/tags {:user-id (:user/id user)
                                :roles (:user/roles user)
                                :organization-code (some-> user :user/organization :organization/code)
                                :organization-id (some-> user :user/organization :organization/id)
                                :school-id (some-> user :user/school :school/id)
                                :school-code (some-> user :user/school :school/code)}]]])

(defn init-fx-by-user [db user]
  (let [roles (set (:user/roles user))
        session-id (get-session-id db)
        default-fx (concat (init-msclarity-fx user session-id)
                     [[:dispatch [:model.event/get-model]]])]
    (let [fx (cond
               (roles :student)
               [[:dispatch [:entity.viewer-student/get-viewer-student]]]

               (roles :teacher)
               [[:dispatch [:entity.viewer-teacher/get-viewer-teacher]]]

               (roles :school-admin)
               [[:dispatch [:entity.viewer-school-admin/get-viewer-school-admin]]]

               :else
               [])]
      (into default-fx fx))))

(rf/reg-event-fx
  :entity.current-user.event/access-token-login
  set-access-token-interceptor
  (fn [{db :db} [access-token]]
    (let [user (utils/jwt-decode access-token)
          roles (:user/roles user)
          redirect-page (cond
                          (roles :student)
                          [:dispatch [:router.event/navigate :page/dashboard {:op :replace
                                                                              :path-params {:org-id (e.user/org-id user)
                                                                                            :school-id (e.user/school-id user)}}]]

                          (roles :teacher)
                          [:dispatch [:router.event/navigate :page/dashboard {:op :replace
                                                                              :path-params {:org-id (e.user/org-id user)
                                                                                            :school-id (e.user/school-id user)}}]]

                          (roles :school-admin)
                          [:dispatch [:router.event/navigate :page/dashboard {:op :replace
                                                                              :path-params {:org-id (e.user/org-id user)
                                                                                            :school-id (e.user/school-id user)}}]]

                          (cset/intersection roles #{:org-admin})
                          [:dispatch [:router.event/navigate :page/dashboard {:op :replace
                                                                              :path-params {:org-id (e.user/org-id user)}}]]

                          ;; TODO redirect to error page
                          :else
                          (do
                            (log/error :current-user/check-redirect-page "Unable to determine redirect page.")))]
      ;; TODO: some of these effects can be refactor with app.db initialization
      {:db (p/add db {:entity.current-user.db/access-token access-token
                      :entity.current-user.db/token-expiration (utils/token-expiration user)
                      :entity.current-user.db/user-id user})
       :fx (into (init-fx-by-user db user)
                 [redirect-page])})))

(rf/reg-event-fx
  :entity.current-user.event/access-token-logout
  remove-user-interceptor
  (fn [{db :db} _]
    (let [fx (into [[:localstorage/remove (user-avatar-localstorage-key (second (-> db :entity.current-user.db/user-id)))]
                    [:dispatch [:router.event/navigate :pages/login {:op :replace}]]]
                   (map (fn [cookie]
                          [:cookie/remove cookie])
                        config/cloudfront-cookies))]
      {:db (-> db
              (dissoc
                :entity.current-user.db/user-id
                :entity.current-user.db/access-token
                :entity.current-user.db/avatar))
       :fx fx})))

(comment
  (utils/base64-decode-string "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidWlkIjoiMzNDd1lDN0lUd1lMRUMiLCJlbWFpbCI6InVzZXJAa2xlaS5pbyIsImV4cCI6MTY1MTQwMTY4NX0.u9tTcZp_n_lv2GgtvPMm7GfYpoyWS-OL9IK2aF7hkUM"))

(rf/reg-event-db
  :entity.current-user.event/set-avatar
  (fn [db [_ avatar]]
    (assoc db :entity.current-user.db/avatar avatar)))

(rf/reg-sub
  :entity.current-user.sub/user
  (fn [db _]
    (current-user db)))

(rf/reg-sub
  :entity.current-user.sub/roles
  :<- [:entity.current-user.sub/user]
  (fn [user _]
    (set (get user :user/roles))))

(rf/reg-sub
  :entity.current-user.sub/avatar
  (fn [db _]
    (get db :entity.current-user.db/avatar)))

(defn- avatar-url [user file-id]
  (string/join "/"
               [config/avatar-host
                (-> user :user/organization :organization/id)
                file-id]))

(rf/reg-sub
  :entity.current-user.sub/avatar-url
  :<- [:entity.current-user.sub/user]
  :<- [:entity.current-user.sub/avatar]
  (fn [[user avatar]]
    (cond
      avatar
      (avatar-url user avatar)

      (:user/avatar user)
      (avatar-url user (:user/avatar user))

      :else
      (utils/avatar-fallback-url))))

(rf/reg-sub
  :entity.current-user.sub/school-name
  :<- [:entity.current-user.sub/user]
  (fn [user _]
    (some-> user :user/school :school/name)))

(rf/reg-sub
  :entity.current-user.sub/access-token
  (fn [db _]
    (get db :entity.current-user.db/access-token)))

(rf/reg-sub
  :entity.current-user.sub/current-role
  :<- [:entity.current-user.sub/roles]
  (fn [roles _]
    (e.user/default-role roles)))

(rf/reg-sub
  :entity.current-user.sub/session-id
  (fn [db _]
    (get-session-id db)))
