(ns io.klei.lms.frontend.shared.re-frame.events
  (:require
    [antd]
    [goog.object :as g.object]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.utils :as utils]
    [reitit.frontend.easy :as rfe]))



;;--------------------------------------------------------------------------------------------------------------
;; Localstorage

(rf/reg-fx
  :localstorage/set
  (fn [[k v]]
    (js/localStorage.setItem k v)))

(rf/reg-fx
  :cookie/remove
  (fn [{:keys [name path domain]}]
    (set! (.-cookie js/document) (str name "=;Max-Age=0; Path=" path "; Domain=" domain ";"))))

(rf/reg-fx
  :localstorage/remove
  (fn [k]
    (js/localStorage.removeItem k)))

(rf/reg-event-fx
  :toast-notification
  (fn [_ [_ options]]
    {:antd/notification options}))

(rf/reg-fx
  :antd/notification
  (fn [{:keys [message description type on-click duration]
        :or {duration 5}}]
    ((g.object/get antd/notification (name type))
     #js {:message message
          :description description
          :duration duration
          :onClick (fn []
                     (when on-click
                       (rf/dispatch on-click)))})))

(rf/reg-fx
  :antd/form-reset
  (fn [^js form]
    (.resetFields form)))

;;--------------------------------------------------------------------------------------------------------------
;; Window

(rf/reg-event-fx
  :location/redirect
  (fn [_ [_ {:keys [href]}]]
    {:fx [[:redirect {:href href}]]}))

(rf/reg-fx
  :redirect
  (fn [{:keys [href]}]
    (set! (.. js/window -location -href) href)))

(def ^:private read-as->file-reader-fn
  {:data-url "readAsDataURL"
   :binary-string "readAsBinaryString"
   :array-buffer "readAsArrayBuffer"
   :text "readAsText"})

(rf/reg-fx
  :file-reader/read
  (fn [{:keys [blob read-as on-load on-error]
        :or {read-as :data-url}}]
    (assert on-load)
    (let [reader (js/FileReader.)
          read-fn (read-as->file-reader-fn read-as)]
      (js-invoke reader read-fn blob)
      (set! (.. reader -onload) (fn []
                                   (rf/dispatch (conj on-load (.-result reader)))))
      (when on-error
        (set! (.. reader -onerror) (fn [^js error]
                                     (rf/dispatch (conj on-error error))))))))

(rf/reg-fx
  :antd.form/set-fields-value
  (fn [[^js form params]]
    (.setFieldsValue form (clj->js params))))

(rf/reg-fx
  :form/reset
  (fn [^js form]
    (.reset form)))

(rf/reg-fx
  :preview/data-url
  (fn [[elem-id ^js file]]
    (let [el (js/document.getElementById elem-id)]
      (set! (.-src el) (js/URL.createObjectURL file))
      ;; release resource to avoid memory leak
      (set! (.-onload el) #(js/URL.revokeObjectURL (.-src el))))))

(rf/reg-fx
  :download-file
  (fn [opts]
    (utils/download-file opts)))


(rf/reg-event-db
  :db/assoc-in
  (fn [db [_ db-path value]]
    (assoc-in db db-path value)))

(rf/reg-event-db
  :db/dissoc-in
  (fn [app-db [_ app-db-path & ks]]
    (assert (seqable? ks))
    (update-in app-db app-db-path
              (fn [v]
                (apply dissoc v ks)))))

(rf/reg-event-db
  :entity/add
  (fn [db [_ response]]
    (utils/add db response)))
