(ns io.klei.lms.frontend.feature.material-list
  (:require
    [goog.string :as gstring]
    [goog.string.format]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [taoensso.timbre :as log]
    [io.klei.lms.frontend.entity.material.book :as entities.material.book]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.config :as config]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn material-cards [{:keys [materials]}]
  [ui/row {:gutter [{:xs 12
                     :md 16}
                    {:xs 12
                     :md 16}]}
   (doall
     (for [m materials]
       (when m
         ^{:key (:material/id m)}
         [ui/col
          [entities.material.book/book-card {:title (:material/title m)
                                             :src (utils/thumbnail-url (-> m :material/thumbnail :file/path))
                                             :on-click #(rf/dispatch [:feat.pdf-viewer.event/open-preview (-> m :material/file :file/id)])}]])))])

(defn- material-item [item]
  [ui/alist-item
   [:div.material-item
    [ui/image {:src (str config/thumbnail-host "/" (-> item :material/thumbnail :file/path))}]
    [:div.material-item__info
     [ui/title {:level 3} (:material/title item)]
     [:div (:material/description item)]
     [:div.material-item__sharing
      (doall
        (for [share (:material/sharing item)]
          ^{:key (:sharing/id share)}
          [ui/tag (-> share :sharing/to :course/name)]))
      [ui/button
       {:type "dashed"
        :size "small"
        :onClick #(rf/dispatch [:feat.material-sharing.event/on-share-access-button-clicked item])}
       [icon/plus]
       "Share Access"]]]]])

(defn material-list [{:keys [materials]}]
  (into [:div.material-list]
        (for [item materials]
          ^{:key (:material/id item)}
          [material-item item])))

;;------------------------------------------------------------
;; UTILS
(defn- reset-modal-state [db]
  (update db :feat.material-sharing.db/modal merge {:submitting? false
                                                      :selected-material-id nil
                                                      :selected-course-id nil}))

(defn- close-modal [db]
  (-> db
      (reset-modal-state)
      (assoc-in [:feat.material-sharing.db/modal :open?] false)))


;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-fx
  :feat.material-sharing.event/on-share-access-button-clicked
  (fn [{db :db} [_ ^js item]]
    {:db (-> db
             (assoc-in [:feat.material-sharing.db/modal :open?] true)
             (assoc-in [:feat.material-sharing.db/modal :selected-material-id] (:material/id item)))}))

(rf/reg-event-fx
  :feat.material-sharing.event/on-modal-cancel
  (fn [{db :db} [_]]
    {:db (close-modal db)}))

(rf/reg-event-fx
  :feat.material-sharing.event/on-course-select
  (fn [{db :db} [_ course-id]]
    {:db (assoc-in db [:feat.material-sharing.db/modal :selected-course-id] course-id)}))

(rf/reg-event-fx
  :feat.material-sharing.event/on-sharing-remove
  (fn [{db :db} [_ sharing]]
    {:http-xhrio (utils/http-map db {:method :delete
                                     :uri (gstring/format "/material-sharing/%s" (:sharing/id sharing))
                                     :on-success [:feat.material-sharing.event/on-sharing-remove-success sharing]
                                     :on-failure [:feat.material-sharing.event/on-sharing-remove-failure]})}))

(rf/reg-event-fx
  :feat.material-sharing.event/on-sharing-remove-success
  (fn [{db :db} [_ sharing _response]]
    (let [modal (get db :feat.material-sharing.db/modal)]
      {:dispatch [:entity.material.event/remove-sharing (:selected-material-id modal) (:sharing/id sharing)]})))

(rf/reg-event-fx
  :feat.material-sharing.event/on-sharing-remove-failure
  (fn [_ [_ failure]]
    (log/error :material-sharing/delete-failure failure)
    {:dispatch [:toast-notification {:type "error"
                                     :message "Failed to remove share access."}]}))

(rf/reg-event-fx
  :feat.material-sharing.event/on-share-button-clicked
  (fn [{db :db} [_]]
    (let [modal (get db :feat.material-sharing.db/modal)]
      {:dispatch [:feat.material-sharing.event/share-material {:to-id (:selected-course-id modal)
                                                               :to-type :course
                                                               :resource-id (:selected-material-id modal)
                                                               :resource-type :material}]})))

(rf/reg-event-fx
  :feat.material-sharing.event/share-material
  (fn [{db :db} [_ params]]
    {:db (assoc-in db [:feat.material-sharing.db/modal :submitting?] true)
     :http-xhrio (utils/http-map db {:method :post
                                     :uri (gstring/format "/material-sharing")
                                     :params params
                                     :on-success [:feat.material-sharing.event/share-material-success]})}))

(rf/reg-event-fx
  :feat.material-sharing.event/share-material-success
  (fn [{db :db} [_ response-as-sharing]]
    (let [material-id (-> response-as-sharing :sharing/resource :material/id)]
      {:db (-> db
               (assoc-in [:feat.material-sharing.db/modal :submitting?] false)
               (assoc-in [:feat.material-sharing.db/modal :selected-course-id] nil))
       :dispatch [:entity.material.event/add-sharing material-id response-as-sharing]})))


;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :feat.material-sharing.sub/modal
  (fn [db _]
    (let [modal (get db :feat.material-sharing.db/modal)]
      (assoc modal :share-button-enabled? (and (:selected-course-id modal)
                                               (not (:submitting? modal)))))))


(rf/reg-sub
  :feat.material-sharing.sub/selected-material-sharing
  :<- [:entity.material.sub/all-by-id]
  :<- [:feat.material-sharing.sub/modal]
  (fn [[all-materials modal]]
    (-> all-materials
        (get (:selected-material-id modal))
        :material/sharing)))

(rf/reg-sub
  :feat.material-sharing.sub/available-for-sharing-options
  :<- [:entity.course.sub/select-options]
  :<- [:feat.material-sharing.sub/selected-material-sharing]
  (fn [[options sharing-list]]
    (->> options
         (remove (fn [o]
                   (some #(= (:value o) (-> % :sharing/to :course/id)) sharing-list))))))

