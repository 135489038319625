(ns io.klei.lms.frontend.page.school-admin.materials
  (:require
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.feature.material-list :as f.material-list]
    [io.klei.lms.frontend.shared.route-utils :as r.utils]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.icon :as icon]))

(defn materials-content [{:keys [match]}]
  (let [modal @(rf/subscribe [:feat.material-sharing.sub/modal])
        materials @(rf/subscribe [:entity.material.sub/all])
        sharing @(rf/subscribe [:feat.material-sharing.sub/selected-material-sharing])
        course-options @(rf/subscribe [:feat.material-sharing.sub/available-for-sharing-options])]
    [:<>
     [ui/row
      [ui/col {:span 24
               :style {:display "flex"
                       :justify-content "flex-end"}}
       [ui/button {
                   :icon (r/as-element [icon/plus])
                   :onClick #(rf/dispatch [:router.event/navigate :page.school-admin/new-material {:path-params (r.utils/org+school-params match)}])}
                  "Add New Material"]]]
     [ui/divider]
     [ui/row
      [ui/col
       [f.material-list/material-list
        {:materials materials}]
       [ui/modal {:className "material-sharing-modal"
                  ;; TODO(jaime): add the material name in the title eg. Share "material name..."
                  :title "Share Access"
                  :open (:open? modal)
                  :footer nil
                  :onCancel #(rf/dispatch [:feat.material-sharing.event/on-modal-cancel %])}
        [:div.material-sharing-modal__select-control
         [ui/select-with-options
          {:placeholder "Select the course to share"
           :options course-options
           :value (:selected-course-id modal)
           :onChange #(rf/dispatch [:feat.material-sharing.event/on-course-select %])}]
         [ui/button {:type "primary"
                     :disabled (not (:share-button-enabled? modal))
                     :onClick #(rf/dispatch [:feat.material-sharing.event/on-share-button-clicked])}
          "Share"]]
        [ui/divider {:size "small"}]
        [ui/text {:strong true}
         "Courses with access"]
        (into [:div.sharing-access-list]
              (for [share sharing]
                ^{:key (:sharing/id share)}
                [:div.sharing-access-list__item
                 [:div.sharing-access-list__shared-to (-> share
                                                          :sharing/to
                                                          :course/name)]
                 [:div.sharing-access-list__actions
                  [ui/button {:type "text"
                              :size "small"
                              :onClick #(rf/dispatch [:feat.material-sharing.event/on-sharing-remove share])}
                   "remove"]]]))]]]]))

