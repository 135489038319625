(ns io.klei.lms.frontend.feature.avatar-dropdown
  (:require
    [clojure.string :as string]
    [re-frame.core :as rf]
    [reagent.core :as r]
    [goog.object :as gobject]
    [io.klei.lms.frontend.entity.viewer-student :as e.viewer-student]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.route-utils :as r.utils]
    [io.klei.lms.frontend.shared.icon :as icon]))

;; SRK Academic years
;;{"g3OEcIBx9t05AE" "SY-2023-2024"
;; "vSXvzxYAeaq0e4" "AY-2024-2025"}

(def dummy-student-numbers
  "SRK to hide external references and forms for these students"
  #{"A22-AD796"
    "A22-AD727"
    "A22-AD899"
    "A22-AD925"
    "A23-AD1107"
    #_"KLEI-0001"})

(def external-ebooks
  "mapping of AY to external materials per level"
  ;; SY-2023-2024
  {"g3OEcIBx9t05AE" {"Basic Level" "https://drive.google.com/drive/folders/1XRARX6TMIeXMpMcYGjBG-R4UW9znC2X4?usp=sharing"
                     "Advanced Level" "https://drive.google.com/drive/folders/1ygzp3I9VG9MOhTgQYoHj6kuHTNzDfvS7?usp=sharing"
                     "Level 1" "https://drive.google.com/drive/folders/1iZ0K7wCUU9yao7mALbMuvLdyzUJxXcqa?usp=share_link"
                     "Level 2" "https://drive.google.com/drive/folders/1VkZY1IqXRsTLO04j2e8d-uID7F02cOyp?usp=share_link"
                     "Level 3" "https://drive.google.com/drive/folders/1T4C2fH_sRzR6JF6OJTGy9gK2JPyLPhJk?usp=share_link"
                     "Level 4" "https://drive.google.com/drive/folders/18a8_UFWIES71K9ozY3JQqzLdMcUZtZVo?usp=share_link"
                     "Level 5" "https://drive.google.com/drive/folders/1k97UlPIa7Y7wTjIpWqQGQVgtD2aoKyfD?usp=share_link"
                     "Level 6" "https://drive.google.com/drive/folders/10QAr4vnY6cbN_P6okNsgaFfjSK3ZZZ-_?usp=share_link"
                     "Level 7" "https://drive.google.com/drive/folders/1P31VIoDP6wk9RQmrXUfHQ6aHN_N1fw70?usp=share_link"
                     "Level 8" "https://drive.google.com/drive/folders/1v9LpCz2MEtVnPfvsW0PdVo3-XVAK-QsU?usp=share_link"
                     "Level 9" "https://drive.google.com/drive/folders/1TO1zmQ7swmIpJiggxmfPhbu5wz23FFIJ?usp=share_link"
                     "Level 10" "https://drive.google.com/drive/folders/115Sjy-mffDVQrZRmfXFCv7yIDoq_OQIi?usp=share_link"}

   ;; AY-2024-2025
   "vSXvzxYAeaq0e4" {"Basic Level" "https://drive.google.com/drive/folders/1-xt6VpoKq6GyZKN637nBuRNa8ldUqmGM?usp=sharing"
                     "Advanced Level" "https://drive.google.com/drive/folders/14qH1eJkhaPQGOrWP9cDae6rgZOCminBn?usp=sharing"
                     "Level 1" "https://drive.google.com/drive/folders/1zmkeEWcpZpbzhnpXh2JlNX6xRzS_o8fw?usp=sharing"
                     "Level 2" "https://drive.google.com/drive/folders/1ZnuendS271O6cjhnrNkt9ySNEwVqMAAL?usp=sharing"
                     "Level 3" "https://drive.google.com/drive/folders/1P7HsYcfwf_fjWXb4j57qL5umlonUERgo?usp=sharing"
                     "Level 4" "https://drive.google.com/drive/folders/1kPzDSR_y2j0xE4Bc4-9nZQGOzRtPU96u?usp=sharing"
                     "Level 5" "https://drive.google.com/drive/folders/1BxB84chLWbt_hfE5EZIal2TTnNT_EThq?usp=sharing"
                     "Level 6" "https://drive.google.com/drive/folders/1kJq62Ciml2XE272dOJseDM5B8qR7_-p0?usp=sharing"
                     "Level 7" "https://drive.google.com/drive/folders/1bUkLsLVO9qRWtljJFUE50aVY485Drnhc?usp=sharing"
                     "Level 8" "https://drive.google.com/drive/folders/1Uac2v_DA_IzYsVsec08bOOFl7anGZx8V?usp=sharing"
                     "Level 9" "https://drive.google.com/drive/folders/1UlpAmJpTSVrEmT5cg4OD7OWWrOY3kNfx?usp=sharing"
                     "Level 10" "https://drive.google.com/drive/folders/1qquGlS2OS4ntBuWw8ROZCP707gVMdb6Z?usp=sharing"
                     "Level 11" "https://drive.google.com/drive/folders/1pKeemUyyKh5A0H6g9aCXb_-FJTPQPrcj?usp=sharing"
                     "Level 12" "https://drive.google.com/drive/folders/1k1QDa6gQM_Ovk1pyrafR3ien9uTdWYc5?usp=sharing"}})

(def external-asynchronous-lessons
  {"vSXvzxYAeaq0e4" {"Basic Level" "https://drive.google.com/drive/folders/1AZtCcA-xpKVK2ojkWu5qnEz0_sL3be3E?usp=sharing"
                     "Advanced Level" "https://drive.google.com/drive/folders/1X1CX7PvTWZti1KPlfom7mRyzYn_FFOFV?usp=sharing"
                     "Level 1" "https://drive.google.com/drive/folders/1OROqGXt7L-F10m7XG37SGLvoAhqT882j?usp=sharing"
                     "Level 2" "https://drive.google.com/drive/folders/1qPTziZJ9wXTPII37p0hfNyekQdUd316p?usp=sharing"
                     "Level 3" "https://drive.google.com/drive/folders/1WO7lNiynSphBHNO-mTCR_7pMcoMnkw0v?usp=sharing"
                     "Level 4" "https://drive.google.com/drive/folders/1jYNjFUmBU26vmOHf9ipz1mtUwibRwtNU?usp=sharing"
                     "Level 5" "https://drive.google.com/drive/folders/1VHNqMEziydwXXqinA_hkxfhWAZi9Djs9?usp=sharing"
                     "Level 6" "https://drive.google.com/drive/folders/1lqs2kV9QcML3HoY8j1iSADUYefebDarW?usp=sharing"
                     "Level 7" "https://drive.google.com/drive/folders/1PCrXyzMzxamtMJ9udlg4MssV2eE9wPYp?usp=sharing"
                     "Level 8" "https://drive.google.com/drive/folders/1AymaFqnPT6v1EXkGKmfBZuqU9fwL8y4R?usp=sharing"
                     "Level 9" "https://drive.google.com/drive/folders/1DrZZ-FHGoqkUgI0UhJNF0-eCkr0TcgMZ?usp=sharing"
                     "Level 10" "https://drive.google.com/drive/folders/1XJ3CWiXpVxScBRLXh80Q6tYs5yp--G7i?usp=sharing"
                     "Level 11" "https://drive.google.com/drive/folders/1D5p1VG0PXLfd7dNN4I6YvRchOD8QDLgq?usp=sharing"
                     "Level 12" "https://drive.google.com/drive/folders/1e3BzKEs1P2F8U7LOlSAKd3VQ6Peec8GH?usp=sharing"}})

(def external-portfolio
  {"vSXvzxYAeaq0e4" {"Basic Level" "https://drive.google.com/drive/folders/1EboaZrfdRdwnW5hTkW1SjXuZLs51KvMf?usp=sharing"
                     "Advanced Level" "https://drive.google.com/drive/folders/114XQlCui9g6OcwmXL_m2d4MtlgmgXnB9?usp=sharing"
                     "Level 1" "https://drive.google.com/drive/folders/1ZV-okLzz9fN9JGYZrnCKnJxQFBVTpiiQ?usp=sharing"
                     "Level 2" "https://drive.google.com/drive/folders/1zNfZ-WUSCcCRF9fAdziCXxx0ZK2UcIQ_?usp=sharing"
                     "Level 3" "https://drive.google.com/drive/folders/1ecxNBpKwHOr8aUUZrR1j5msaect7HRHF?usp=sharing"
                     "Level 4" "https://drive.google.com/drive/folders/1zuP_K_1q5RSv4-GbGqRaMB_zijWqw3U-?usp=sharing"
                     "Level 5" "https://drive.google.com/drive/folders/1wP6GlshvxxlQRHdhUjS3lQ0IgX4SdPl4?usp=sharing"
                     "Level 6" "https://drive.google.com/drive/folders/1v1mZZ5KWrw-EWcxJ1tgzoWq2FPlUoOhX?usp=sharing"
                     "Level 7" "https://drive.google.com/drive/folders/1yNoc9Al1Q9woEly4gverKvQ_8MFHfWJe?usp=sharing"
                     "Level 8" "https://drive.google.com/drive/folders/13hMl0XSnjdCyJ5lTHJKjPGsnIQ0cJWOh?usp=sharing"
                     "Level 9" "https://drive.google.com/drive/folders/1IQEx94omHxkoVxtX4kgYjDjSS3CtKvLV?usp=sharing"
                     "Level 10" "https://drive.google.com/drive/folders/1NECWe4jqlMKdgRD_5-JOYJHvW6c1ZgWb?usp=sharing"
                     "Level 11" "https://drive.google.com/drive/folders/1500v4IO0cvNmNwqkOqRz3DDcr8Vm4fDj?usp=sharing"
                     "Level 12" "https://drive.google.com/drive/folders/19i6o8ZuLuoc9eZxPovbsyQ-HuN1yzX6t?usp=sharing"}})

(def us-section-suffix "HMS")

(def level-label->short
  {
   ; for testing
   "Grade 1" "G1"
   ; SRK
   "Basic Level" "BL"
   "Advanced Level" "AL"
   "Level 1" "L1"
   "Level 2" "L2"
   "Level 3" "L3"
   "Level 4" "L4"
   "Level 5" "L5"
   "Level 6" "L6"
   "Level 7" "L7"
   "Level 8" "L8"
   "Level 9" "L9"
   "Level 10" "L10"
   "Level 11" "L11"
   "Level 12" "L12"})

(def us-ebooks
  ;; Advanced Level = Kindergarten
  {"g3OEcIBx9t05AE" {"student" {"Advanced Level" "https://drive.google.com/drive/folders/1G2xCNsh_hTD_rMAY6LPnwQ5Akb77rpH0?usp=sharing"
                                "Level 1" "https://drive.google.com/drive/folders/10utpMZP0v-37n6o6FHhEGZ2Jv4fBaaVs?usp=sharing"
                                "Level 8" "https://drive.google.com/drive/folders/1jh_x8HgCEmbBRqqbAnKy-k9TxtxfHAPE?usp=sharing"
                                "Level 10" "https://drive.google.com/drive/folders/1NwDzpOOY_yokA6sPINkoupvRtrA4jl5D?usp=sharing"
                                "Level 11" "https://drive.google.com/drive/folders/1uID7EHSbAJBEFKsFu-ZlcLK0uauFWW_F?usp=sharing"
                                "Level 12" "https://drive.google.com/drive/folders/1xTPyOCzn-HwOchEPqUeWiYZGL324lQHU?usp=sharing"}
                     "teacher" {"Advanced Level" "https://drive.google.com/drive/folders/1QpMQ37yxzoxflJ4HDji2fs8vDoRxuHjy?usp=sharing"
                                "Level 1" "https://drive.google.com/drive/folders/1c3NwPiVE2OA7P699kSM7SYexouHQo4gX?usp=sharing"
                                "Level 8" "https://drive.google.com/drive/folders/1kwAdWfEtTeoQx6NwR6gC-TSoIB-Bt7yN?usp=sharing"
                                "Level 10" "https://drive.google.com/drive/folders/1-Qt0LbZdUFuhUq5_MqEyMDryJYKqvdx2?usp=sharing"}}
   "vSXvzxYAeaq0e4" {"student" {"Advanced Level" "https://drive.google.com/drive/folders/13e2bEQfqEcpFIXz5xVVOYb9LbeTM-JeE?usp=sharing"
                                "Level 1" "https://drive.google.com/drive/folders/1-6vacQBNNU4ff0wwsTOOFjF0QDn-bidZ?usp=sharing"
                                "Level 3" "https://drive.google.com/drive/folders/1tm5zRP9Q5mrlQ41gfAIDIxi1cKUKQLq_?usp=sharing"
                                "Level 7" "https://drive.google.com/drive/folders/1iciZ3lK31gagHkDImBS9aBf_T7WRj1Lv?usp=sharing"
                                "Level 8" "https://drive.google.com/drive/folders/1ckAOHes1OqthZFm7p1sCoTBFXtHxJ3Um?usp=sharing"
                                "Level 10" "https://drive.google.com/drive/folders/1tS9aZlrdCfcIfYD4FD60-b2Kx-7X2JGL?usp=sharing"}
                     "teacher" {"Advanced Level" "https://drive.google.com/drive/folders/1Btkt-NsfJC-KYzAGzpvHjLLK_srTLOQ-?usp=sharing"
                                "Level 1" "https://drive.google.com/drive/folders/1-6vacQBNNU4ff0wwsTOOFjF0QDn-bidZ?usp=sharing"
                                "Level 3" "https://drive.google.com/drive/folders/172tgiz2cAmkfzgAZyYoQrozcC-n6QFpC?usp=sharing"
                                "Level 7" "https://drive.google.com/drive/folders/1cw8ADxhdgqLO6UjNhCpGHDhH7BIunHaN?usp=sharing"
                                "Level 8" "https://drive.google.com/drive/folders/1hTfbLGjDSeX1Urj1oR2NaI4spWtXqZyj?usp=sharing"
                                "Level 10" "https://drive.google.com/drive/folders/1jo_o6mz5mz9Nv3dFnhf0MvmOyyowQDw6?usp=sharing"}}})

(defn- student-external-ebooks [viewer-student]
  (let [ay-id (e.viewer-student/academic-year-id viewer-student)
        level (e.viewer-student/level-name viewer-student)]
    (get-in external-ebooks [ay-id level])))

(defn- student-external-async-lessons [viewer-student]
  (let [ay-id (e.viewer-student/academic-year-id viewer-student)
        level (e.viewer-student/level-name viewer-student)]
    (get-in external-asynchronous-lessons [ay-id level])))

(defn- student-external-portfolio [viewer-student]
  (let [ay-id (e.viewer-student/academic-year-id viewer-student)
        level (e.viewer-student/level-name viewer-student)]
    (get-in external-portfolio [ay-id level])))

(defn- us-curriculum-section? [section]
  (some-> (:section/name section)
          (string/ends-with? us-section-suffix)))

(defn student-us-ebooks [viewer-student]
  (let [us-curriculum? (some-> (:section viewer-student)
                               (us-curriculum-section?))
        ay-id (e.viewer-student/academic-year-id viewer-student)
        level (e.viewer-student/level-name viewer-student)]
    (when us-curriculum?
      (get-in us-ebooks [ay-id "student" level]))))

(defn- teacher-us-books-by-level [viewer-teacher]
  (some->> (:sections viewer-teacher)
           (filter us-curriculum-section?)
           (map #(get-in % [:section/level :level/name]))
           (set)))

(defn external-links [user]
  (let [viewer-student @(rf/subscribe [:entity.viewer-student.sub/viewer-student])
        viewer-teacher @(rf/subscribe [:entity.viewer-teacher.sub/viewer-teacher])
        roles (:user/roles user)]
    (cond
      (contains? dummy-student-numbers (e.viewer-student/student-number viewer-student))
      []

      (contains? roles :student)
      (let [links (cond-> []
                    (student-external-ebooks viewer-student)
                    (conj {:key "external-ebooks"
                           :label (r/as-element
                                    [:a {:href (student-external-ebooks viewer-student) :target "_blank"} "E-books"])})

                    (student-external-portfolio viewer-student)
                    (conj {:key "external-portfolio"
                           :label (r/as-element
                                    [:a {:href (student-external-portfolio viewer-student) :target "_blank"} "Portfolio"])})

                    (student-external-async-lessons viewer-student)
                    (conj {:key "external-async-lessons"
                           :label (r/as-element
                                    [:a {:href (student-external-async-lessons viewer-student) :target "_blank"} "Asynchronous Lessons"])})

                    (student-us-ebooks viewer-student)
                    (conj {:key "us-ebooks"
                           :label (r/as-element
                                    [:a {:href (student-us-ebooks viewer-student) :target "_blank"} "Homeschool E-books"])}))]
        (when (seq links)
          [{:type "divider"}
           {:key "external-references-group"
            :label "External References"
            :type "group"
            :children links}
           {:type "divider"}]))


      (contains? roles :teacher)
      (let [levels (some->> viewer-teacher
                     :sections
                     (map #(get-in % [:section/level :level/name]))
                     (set))
            us-levels (teacher-us-books-by-level viewer-teacher)
            ay-id (some-> viewer-teacher :sections first :section/academic-year-id)
            links (cond-> []
                    true
                    (into (some->> levels
                            (select-keys (get external-ebooks ay-id))
                            (sort-by first)
                            (mapv (fn [[label link]]
                                    {:key (str "e-books-" label)
                                     :label (r/as-element
                                              [:a {:href link :target "_blank"}
                                               (str "E-books - " (level-label->short label))])}))))

                    true
                    (into (some->> levels
                            (select-keys (get external-portfolio ay-id))
                            (sort-by first)
                            (mapv (fn [[label link]]
                                    {:key (str "portfolio-" label)
                                     :label (r/as-element
                                              [:a {:href link :target "_blank"}
                                               (str "Portfolio - " (level-label->short label))])}))))

                    true
                    (into (some->> levels
                            (select-keys (get external-asynchronous-lessons ay-id))
                            (sort-by first)
                            (mapv (fn [[label link]]
                                    {:key (str "async-lessons-" label)
                                     :label (r/as-element
                                              [:a {:href link :target "_blank"}
                                               (str "Asynchronous Lessons - " (level-label->short label))])}))))

                    (seq us-levels)
                    (into (some->> us-levels
                            (select-keys (get-in us-ebooks [ay-id "teacher"]))
                            (sort-by first)
                            (mapv (fn [[label link]]
                                    {:key (str "us-" label)
                                     :label (r/as-element
                                              [:a {:href link :target "_blank"}
                                               (str "Homeschool E-books - " (level-label->short label))])})))))]
        (when (seq links)
          [{:type "divider"}
           {:key "external-references-group"
            :label "External References"
            :type "group"
            :children links}
           {:type "divider"}])))))

;;------------------------------------------------------------
;; UI
(defn avatar-dropdown []
  (let [user @(rf/subscribe [:entity.current-user.sub/user])
        avatar-url @(rf/subscribe [:entity.current-user.sub/avatar-url])
        student? (contains? (:user/roles user) :student)
        viewer-student @(rf/subscribe [:entity.viewer-student.sub/viewer-student])]
    [ui/dropdown
     {:overlayStyle {:min-width "200px"}
      :trigger "click"
      :menu {:items (->> (into [{:key "profile"
                                 :label "Profile"}
                                (when (and student?
                                        (not (contains? dummy-student-numbers (e.viewer-student/student-number viewer-student))))
                                  {:key "forms"
                                   :label "Forms"})]
                           (conj (external-links user)
                             {:key "logout"
                              :label "Logout"}))
                      (filter some?))
             :on-click #(rf/dispatch [:feat.avatar-dropdown.event/on-click %])}}
     [:div.user-avatar-dropdown
      [ui/space
       [:span.user-avatar-dropdown__text (:user/email user)]
       [ui/avatar {:src avatar-url}]
       [icon/down {:style {:font-size "12px"}}]]]]))

;;------------------------------------------------------------
;; EVENTS
(def menu->page
  {"profile" :page.my/profile})

(rf/reg-event-fx
  :feat.avatar-dropdown.event/on-click
  (fn [{db :db} [_ o]]
    (let [match (-> db :router.db/match)
          item-key (gobject/get o "key")
          fx (case item-key
               "profile"
               [[:dispatch [:router.event/navigate :page.my/profile {:path-params {:org-id (r.utils/org-id match)}}]]]

               "forms"
               [[:dispatch [:router.event/navigate :page.my/forms {:path-params {:org-id (r.utils/org-id match)}}]]]

               "logout"
               [[:dispatch [:entity.current-user.event/access-token-logout]]]

               [])]
      {:fx fx})))

;;------------------------------------------------------------
;; SUBS

