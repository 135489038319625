(ns io.klei.lms.frontend.app
  (:require
    [day8.re-frame.http-fx]
    ;; load models, libs here
    [io.klei.lms.frontend.app.db]
    [io.klei.lms.frontend.app.router :as router]
    [io.klei.lms.frontend.entity.core]
    [io.klei.lms.frontend.feature.core]
    [io.klei.lms.frontend.shared.re-frame.events]
    [io.klei.lms.frontend.shared.re-frame.subs]
    [io.klei.lms.frontend.shared.re-frame.pathom]
    [io.klei.lms.frontend.form.uniforms]
    [io.klei.lms.frontend.page.layout :as layout]
    [io.klei.lms.frontend.model]
    [re-frame.core :as rf]))

(defn app []
  (let [match @(rf/subscribe [:router.sub/match])]
    [layout/nest-component match]))
