(ns io.klei.lms.frontend.page.dashboard
  (:require
    [clojure.set :as cset]
    [goog.object :as gobject]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.entity.section :as entities.section]
    [io.klei.lms.frontend.entity.material.book :as entities.material.book]
    [io.klei.lms.frontend.feature.material-list :as features.material-list]
    [io.klei.lms.frontend.feature.pdf-viewer :as features.pdf-viewer]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.route-utils :as r.utils]
    [io.klei.lms.frontend.page.layout :as layout]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn- student-content []
  (let [grouped-schedules @(rf/subscribe [:entity.viewer-student.sub/schedules-table-data])
        materials @(rf/subscribe [:entity.viewer-student.sub/materials])
        file-url @(rf/subscribe [:feat.pdf-viewer.sub/file-url])
        preview-open? @(rf/subscribe [:feat.pdf-viewer.sub/open?])]
    [ui/row {:wrap true
             :gutter [{:xs 8
                       :md 16}
                      {:xs 8
                       :md 24}]}
     [ui/col {:span 24}
      [ui/title {:level 4} "My Schedule"]
      [:div.week-schedule
       (doall
         (for [group-by-day grouped-schedules]
           (do
             ^{:key (hash group-by-day)}
             [:div.week-schedule__row
              [:div.week-schedule__day (utils/day-name (key group-by-day))]
              (if-let [scheds (seq (val group-by-day))]
                (into [:div.week-schedule__schedule-list]
                      (for [s scheds]
                        [:div.week-schedule__schedule
                         [:div.week-schedule__time-slot (str (utils/convert-dubai-to-local (:section-schedule/start-time s))
                                                          " - "
                                                          (utils/convert-dubai-to-local (:section-schedule/end-time s)))]
                         [:div.week-schedule__subject (-> s :section-schedule/subject :subject/name)]]))
                "--")])))]]

     [ui/col {:span 24}
      [ui/section-heading "Books"]
      [features.material-list/material-cards {:materials materials}]
      (when (and preview-open? file-url)
        [:f> features.pdf-viewer/pdf-viewer {:url file-url
                                             :open? preview-open?
                                             :close-on-overlay-click? true
                                             :on-close #(rf/dispatch [:feat.pdf-viewer.event/close-preview])}])]]))

(defn teacher-content []
  (let [match @(rf/subscribe [:router.sub/match])
        sections @(rf/subscribe [:entity.viewer-teacher.sub/sections])]
    [ui/row {:gutter [32 32]
             :wrap true}
     (if (seq sections)
       (doall
         (for [section sections]
           [ui/col {:key (:section/id section)
                    :xs 24
                    :md 5}
            [:a {:href (r.utils/page-href :page.teacher/section-view (merge {:section-id (:section/id section)}
                                                                            (r.utils/org+school-params match)))}
             [entities.section/section-card {:data section}]]]))
       [ui/alert {:message "You are not assigned to any sections yet."
                  :description "Sections that are assigned to you should be listed here."
                  :type "info"
                  :showIcon true}])]))


(defn admin-content []
  [ui/row
   [ui/col
    [ui/title {:level 4} "Dashboard for school admin coming soon..."]]])

(defn dashboard-content []
  (let [roles @(rf/subscribe [:entity.current-user.sub/roles])]
    (cond
      (contains? roles :student)
      [student-content]

      (contains? roles :teacher)
      [teacher-content]

      (contains? roles :school-admin)
      [admin-content]

      (seq (cset/intersection roles #{:org-admin}))
      [admin-content]

      :else nil)))
